import React from 'react';

export const Terms = () => {
    return (
        <div>

            <h1>Welcome to MarsData!</h1>
            <p>We hope you’ll enjoy using our platform. Before you start participating in studies, please make sure you have read and understood these Terms, which describe your (and our) legal rights and obligations in relation to your use of MarsData.</p>
            <p>You might also find it helpful to read some of the posts in our TODO: Participant Help Centre. The Help Centre offers more practical “how to” information, and discusses some of the issues mentioned in these Terms in more detail.</p>
            <h2>Definitions and overview</h2>
            <p><strong>To give some context for the rest of these Terms:</strong></p>
            <ul>
                <li>We are MarsData Academic Ltd (PAL), as well as, where expressly noted in these Terms, MarsData Research USA, Inc. (PRUSA) (‘we’ or ‘us’).</li>
                <li>MarsData is the platform we provide for launching and recruiting for research studies, which we make available through our Website at <a href="https://marsdata.co">www.marsdata.co</a>.</li>
                <li>Researchers are the academic institutions, organisations or individuals who use MarsData to launch online research studies (Studies).</li>
                <li>You are an individual Participant. Participants use MarsData to participate in Studies, and their submissions in participating in Studies are Submissions.</li>
                <li>The rewards offered by Researchers to Participants for their Submissions are Rewards.</li>
                <li>There are a few legal relationships which arise through MarsData:
                    <ul>
                        <li>When you register to use MarsData, you enter into an agreement with PAL under which we agree to make MarsData available to you, and you agree to certain obligations in relation to your use of MarsData. That agreement comprises these Participant Terms (Terms).</li>
                        <li>Each Researcher who uses MarsData enters into an agreement with us as well, under which we agree to make MarsData available to them, and they agree to certain obligations in relation to their use of MarsData. That agreement comprises the Researcher Terms.</li>
                        <li>When you take part in a Study, you will provide Submissions in exchange for Rewards. An agreement is formed between you and the Researcher. That agreement comprises the Researcher’s Study listing (setting out the details of the Study) and any specific terms which the Researcher needs Participants to accept before taking part in that Study (like consent forms) (together these are the Study Terms).</li>
                    </ul>
                </li>
                <li>Importantly, we are not party to the agreement between the Researcher and the Participant which is created by participation in a Study.</li>
                <li>Because Studies are conducted outside of MarsData, we do not see or control Participant responses to Studies, or Researchers’ conduct of Studies.</li>
                <li>Anybody who views our Website, whether as a Researcher, Participant, or just browsing, does so under our Website Terms of Use (these cover some pretty generic subject matter, like linking to and from the Website and not copying its contents).</li>
                <li>Finally, any personal data you provide to us or which we collect about you will be used in accordance with our Participant Privacy Notice.</li>
            </ul>

            <h2>1. Account registration, and your agreement with us</h2>
            <p>To register to use MarsData you must enter your first name, surname, email address, country of residence and date of birth and choose a password.</p>
            <p>You will then be asked to provide further personal data for pre-screening purposes. This profile information is mostly demographic in nature (like nationality, education, ethnicity, or gender) and we use it for the purposes of pre-screening to ensure that you participate only in those Studies for which you are suitable based on the parameters set by the relevant Researcher.</p>
            <p>We may request that you provide further information or documentation to verify your profile information, and you may not be able to participate in certain Studies unless we have verified this. More information about how we use this personal data can be found in our TODO: Participant Privacy Notice.</p>
            <p>When you register to use MarsData as a Participant, you are offering to enter into a binding agreement with us under these Terms. If we accept your registration, then you will be legally bound by these Terms. We will then open an account associated with the details you have provided to us (a Participant Account). If for any reason we decline your registration, we will notify you and you will not be able to use MarsData.</p>
            <p><strong>By registering to use MarsData you confirm:</strong></p>
            <ul>
                <li>that you are over eighteen (18) years old and have legal capacity to enter into your agreement with us;</li>
                <li>that all the information provided to us for the purpose of registering for MarsData is true, and that you will notify us promptly of any changes to your personal data;</li>
                <li>that you authorise the electronic transfer of funds to us and from us in accordance with the Payments section of these Terms; and</li>
                <li>that you will abide by our stand against discrimination or abuse towards our employees or contractors (see below).</li>
            </ul>
            <p>You are responsible for all activities undertaken through your Participant Account, and must not let anyone else use your Participant Account (including allowing any third party to complete Studies, unless you appoint a substitute as described further below). You must keep your login details secure, and notify us immediately at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a> if you believe any other person may have access to your login details or Participant Account. While we may make recommendations, keeping your password and login details secure is your responsibility and you must ensure that you take appropriate precautions (such as minimum password strength, not re-using passwords used on other accounts, and regularly updating your password for your Participant Account without re-using previous passwords).</p>

            <h2>Your responsibilities</h2>
            <p>We value trust and quality. We try to make sure that Researchers adhere to appropriate values of transparency, fair pay and ethical research practice. From the Researcher’s perspective, we try to make sure that they can source trusted Participants through MarsData, with better data quality than might be available on other platforms.</p>
            <p>As a general rule, you should comply with the guidance for Participants contained in our Help Centre – we’ve tried to make this a resource setting out not just practical guidance, but also examples of good practice. While we can’t circumscribe every aspect of good behaviour, we ask that you treat our employees and contractors (e.g., MarsData support staff) with respect and fairness.</p>
            <p>Otherwise, you must:</p>
            <ul>
                <li>not use MarsData for any form of advertising;</li>
                <li>not use any bots or other programs to automate your use of MarsData or participation in Studies, automate registration for your Participant Account or crawl or scrape data from MarsData;</li>
                <li>use MarsData entirely honestly and respectfully. For example, you should not impersonate anyone else, give false information, misrepresent your affiliation to any organisation or institution, or conduct any fraudulent or unlawful activity; and</li>
                <li>not hold yourself out publicly as being a MarsData employee or contractor on the basis of your participation in any of the Studies.</li>
            </ul>
            <p>Please note, while some of the content on our Website, particularly in our Help Centre, may express our understanding of applicable laws like data protection laws, or your responsibility to pay tax on income received through MarsData, none of that content constitutes legal or professional advice on which you may rely.</p>

            <h2>No discrimination or abuse against MarsData employees and contractors</h2>
            <p>We will not tolerate racism, discrimination, violence or abuse of any kind against our employees or contractors (e.g., when they provide you with support in relation to your use of MarsData). If there are any instances of racist, discriminatory or abusive language used in dealing with any of our employees or contractors, then we may terminate your account immediately and without notice.</p>

            <h2>No restriction on marketing yourself for other research studies or on similar platforms</h2>
            <p>When you use MarsData you do so on a non-exclusive basis and are of course free to sign up and use other research study platforms if you choose to do so.</p>
            <p>In addition, there is no restriction on you contacting any Researcher through MarsData (i.e., using our messaging system) or outside of MarsData to offer yourself up for other research studies where the Researcher is comfortable with you doing so.</p>

            <h2>2. Taking part in Studies</h2>
            <p>Our Help Centre contains helpful guidance on how you can take part in Studies – you should read that guidance before reading this section of these Terms.</p>

            <h2>How Studies are listed and conducted</h2>
            <p>MarsData is used to list Studies and to recruit Participants, but most Studies will be completed, and Submissions received, on whatever third-party platform the Researcher has chosen to host the Study*. Because the Study takes place outside of MarsData, this means we don’t actually have any oversight of it. Instead, the Researcher remains responsible for the conduct of the Study itself – and sets the parameters for and the content of that Study. Our role is simply to make that Study available to you by listing it on the platform.</p>
            <p>*There is an exception to this, where Researchers launch a Study through a data collection tool which is integrated within our platform, meaning we may have access to the Study and your Submissions (as part of providing this service to the Researcher). However, the Researcher still remains responsible for the conduct of the Study. These Studies are known as Managed Data Collection Studies, and there are Supplementary Terms (set out below these Terms) which apply when you take part in this type of of Study. It will be made clear when a Study is a Managed Data Collection Study.</p>
            <p>You are free to participate in a Study or not. No action is needed by you if you don’t want to participate.</p>
            <p>If you do agree to participate in a Study, you will be entering into a separate agreement between you and the Researcher on the Study Terms. Remember: the Study Terms means the Researcher’s Study listing and any specific requirements or additional terms and conditions they ask you to agree to before participating in the Study. For example, some Studies may require additional verification checks to be conducted before you can take part. More information on these checks can be found in our Participant Privacy Notice.</p>
            <p>Because you are entering into a separate agreement with the Researcher, this model has a few important consequences:</p>
            <ul>
                <li>we are not responsible for the stability or quality of the third-party platform used to host the Study;</li>
                <li>we will not moderate the content of the Study, or the content of Submissions. That “distance” is an important part of our model and helps preserve the anonymity of Submissions (where that’s required) and ensure the integrity of Studies. You should not send us any Studies, Submissions or their contents without our written agreement;</li>
                <li>we are not responsible for the integrity and quality of Studies, or for how they are conducted. However, under the Researcher Terms, we require Researchers to ensure that Studies are conducted ethically, lawfully and with all necessary approvals;</li>
                <li>we do not provide your personal data to Researchers, except for screener, demographic and performance data (which is pseudonymised, and associated with your MarsData ID rather than with any direct identifiers like your name or email address) without your consent, unless there is a breach of your obligations to a Researcher under the Study Terms as described further below;</li>
                <li>we are not responsible for any other software or services which any Researcher requires you to use in order to participate in any Study;</li>
                <li>if any issues arise in connection with a Study, the Researcher is responsible for resolving them in much the same way they would resolve issues arising from comparable offline studies; and</li>
                <li>when you provide a Submission, you do so as an independent contractor. Any Rewards you earn may constitute income, and you are responsible for paying tax on that income. See “Taxes and reporting” below for more information.</li>
            </ul>
            <p>The use by the Researcher of any data comprising your Submissions and any further personal data you provide in connection with a Study (e.g., where this has been requested by the Researcher) will be governed by the relevant Study Terms, and in particular any privacy notice included by the Researcher in the Study listing.</p>

            <h2>Our role as agent</h2>
            <p>For any Researcher that is contracted with PAL, the Researcher authorises us to act as its commercial agent to negotiate and/or conclude agreements on their behalf on the Study Terms. When you make a Submission, you will be entering into an agreement with that Researcher on the Study Terms.</p>
            <p>We do not otherwise act as the agent of the Researcher, and we do not act as your agent.</p>

            <h2>Approving or rejecting your Submission</h2>
            <p>The Researcher (and not MarsData) will decide whether to approve or reject your Submission. We ask Researchers to do this promptly after completion of each Study and normally expect them to do so within two (2) working days after completion. Unless rejected, all Submissions will be automatically approved twenty-one (21) days after completion of the Study.</p>
            <p>Some Studies may be subject to a maximum completion time set by the Researcher. If you are “timed out” of a Study then you will not usually be eligible to receive any Reward for your Submission.</p>

            <h2>Submission quality</h2>
            <p>We strongly recommend that you read the Study listing carefully and ensure your Submission is of reasonable quality and meets the specific requirements of the Study set by the Researcher to increase the likelihood of it being approved. Your Submission must not contain any offensive or illegal material.</p>
            <p>If the Researcher determines that your Study is not of reasonable quality or does not meet the requirements of the Study, your Submission may be rejected and you may not be paid any Reward or may be paid only a partial Reward. Where the Researcher is contracted with PRUSA, we are responsible for the payment of any Rewards to you. We reserve the right to determine whether or not you are eligible for any Reward or partial Reward, based on the Researcher’s determination that your Study is not of reasonable quality or does not meet the requirements of the Study.</p>

            <h2>Returning your Submission</h2>
            <p>You can “return” your Submission to any Study by withdrawing from participation in the Study while it is underway, or after it is completed but awaiting review. If you return your Submission you will not be paid any Reward for your participation in the relevant Study, regardless of whether the Researcher or MarsData is responsible for the payment of the Reward.</p>

            <h2>Restrictions and confidentiality</h2>

            <h2>Your obligations to MarsData</h2>
            <p>You agree to not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of MarsData or the services we provide nor attempt to do so, except to the extent as may be permitted by law.</p>

            <h2>Your obligations to Researchers</h2>
            <p>In order to preserve research integrity, copyright and, where relevant, confidentiality, Researchers will expect you not to publish or otherwise disclose the contents of any Studies. Some Researchers may require you to agree to specific confidentiality obligations in the Study Terms (e.g., in relation to sensitive Studies). Your duty of confidentiality in relation to any Study is to the Researcher and not to MarsData.</p>
            <p>If you breach your confidentiality obligations to any Researcher, then we may be compelled to disclose your identity to the Researcher in order for them to pursue civil remedies against you, and you may further be liable to us for our associated costs.</p>

            <h2>Your right of substitution</h2>
            <p>At any point after you’ve agreed to take part in a Study but before you have begun completing your Submission, you may choose to have someone else participate in the Study on your behalf. This is known as your right of substitution.</p>
            <p>You can choose anyone to be your substitute, as long as they meet the eligibility criteria for the Study in question. In practice this means that the profile information which you provided for pre-screening purposes must be the same for your substitute. You can check the profile information you have provided in the ‘About you’ section of your Participant Account. It is your responsibility to ensure your substitute meets these criteria and that they comply with these Terms and the relevant Study Terms.</p>
            <p>Your substitute will use your Participant Account to complete the Study in question.</p>
            <p>Rewards for any Submission completed by your substitute will be paid to your Participant Account in the normal way (see ‘Paying Rewards’ below). You are responsible for paying any Rewards to your substitute, and for setting any payment terms between you. Any other arrangements you agree with your substitute are down to you. However, you must ensure your substitute is treated in accordance with the law. You’ll also be fully responsible and accountable for anything your substitute does when they use MarsData and when they take part in a Study.</p>
            <p>If you’d like to exercise your right of substitution for a Study, please see this **Help Centre article** where you will find further details (or contact us at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>). You must tell us about each and every Study for which you want to provide a substitute. Please note, you cannot appoint a substitute for any Study where additional verification checks need to be conducted in order to take part.</p>

            <h3>3. Paying Rewards</h3>
            <p>Researchers decide and set the Rewards for their own Studies. You can choose whether or not to take part in any Study offered to you at your discretion.</p>
            <p>We require Researchers have a sufficient balance to pay Rewards before launching the relevant Study. We will apply Rewards to your Participant Account on behalf of the relevant Researcher. Rewards are paid in the currency identified in the relevant Study listing.</p>
            <p>You will need to wait until your Submission is approved by the Researcher before receiving the relevant Reward (see ‘Approving or rejecting your Submission’ above for how and when this is done). If the Researcher rejects your Submission, no Reward will be paid to you. We are not responsible for any Rewards not being paid to you for this reason.</p>
            <p>If the Researcher approves your Submission, we will apply the Reward to your Participant Account within fourteen (14) working days of that approval. However, if you are liable to us for any reason we may withhold payment of any Rewards to you as an offset against that liability.</p>
            <p>You may not claim any Rewards unless and until you have accumulated at least £6.00 in total Rewards (or the equivalent in any other currency). If no Rewards are credited to your Participant Account for a twelve (12) month period, we reserve the right to close your Participant Account and disburse any unredeemed Rewards to your linked payment account.</p>
            <p>MarsData utilises Hyperwallet payment services to deliver payments to you. Such payment services are subject to the Hyperwallet Terms of Service and the Hyperwallet Privacy Policy. We also use PayPal as a payment provider. Rewards will be paid to your PayPal account.</p>
            <p>PayPal and Hyperwallet (which is part of the PayPal group of companies) may receive your credit or debit card details and other personal data about you when providing these payment services. More information is available in our Participant Privacy Notice.</p>
            <p>For the most part, payment of Rewards is determined by the Researcher as described above. However, in limited circumstances, we may delay or suspend payment of Rewards:</p>
            <ul>
                <li>if we are unable to confirm your identity or reasonably believe that the transfer requested is unauthorised; or</li>
                <li>if we reasonably believe that you are in breach of these Terms.</li>
            </ul>
            <h4>Taxes and reporting</h4>
            <p>Rewards are inclusive of any applicable VAT or any equivalent or similar tax imposed outside the United Kingdom.</p>
            <p>The Rewards you earn may constitute taxable income, for which you are personally liable. This means it is your responsibility to report and pay tax to the tax authority in the jurisdiction in which you are tax resident in respect of that income.</p>
            <p>In addition, if you are a resident in the United Kingdom and the total value of the Rewards you earn in a calendar year exceeds a threshold of €2,000EUR (approximately £1,700GBP) in any currency, we are required by law to collect and report certain information about you (including your name, address, date of birth and personal tax identification number, e.g. national insurance number or unique tax reference number) to the UK tax authority, HM Revenue and Customs (HMRC). This threshold may be subject to change by HMRC. If you are resident in a jurisdiction outside the United Kingdom, we may be required to collect and report similar information about you to the relevant tax authority.</p>
            <p>If you fail to provide us with the information required by any tax authority, you may be prevented from onboarding with MarsData, or we may suspend access to your account until such information is provided.</p>

            <h3>4. Data Protection</h3>
            <p>Our Participant Privacy Notice sets out full information about how we receive, use and transfer personal data relating to you. In summary, though:</p>
            <ul>
                <li>We are a data controller of the personal data, we collect and hold about you in relation to your use of MarsData, and we will comply with applicable data protection law in our use of that personal data. However, please note, we are not the data controller in relation to any personal data you provide in connection with a Study, including your responses in any Submission – instead this is the Researcher who is conducting that Study.</li>
                <li>Each Researcher who receives personal data relating to you is likewise a data controller, and is responsible for complying with applicable law in collecting and using that personal data. We are not responsible for any use, or misuse, of your personal data by Researchers.</li>
                <li>We will not disclose any direct personal identifiers to Researchers, like your name, phone number or email address, unless you breach your obligations you owe to a particular Researcher. Generally, this means that unless you choose to share this information directly with the Researcher yourself (or any other information which is capable of revealing your identity) as described further below, your identity will remain unknown to them.</li>
                <li>Some Researchers may ask if you are willing to share with them direct identifiers (like your name, contact details and details of your social media accounts), as well as other personal data they may require as part of a Study. Where this is the case, we ask that the Researcher makes this clear in the Study listing (including any privacy notice they include in the Study listing) so you can decide if you are willing to share this information and so make an informed decision as to whether you wish to participate in the Study. In making that decision you should ensure you are comfortable with how any information you provide will be used by the Researcher. Remember, the collection of certain information about you (such as direct personal identifiers) may enable the Researcher to identify you. Again, we are not responsible for any use, or misuse, of your personal data by Researchers.</li>
                <li>Other Studies may require you to provide audio, video or images as part of your Submissions. Again, where this is the case, we ask that the Researcher makes this clear in the Study listing or their privacy notice so that you can decide if you are comfortable providing these when you participate in the Study. In making that decision you should be aware that the provision of audio, video or images may make it easier for the Researcher to identify you and this could affect your anonymity.</li>
                <li>Researchers may have access to demographic profile information relating to Participants used for screening purposes (so if you seek to enrol in a Study the Researcher may use that information to determine whether or not you are eligible). If you participate in a Study, then the Researcher will also have access to performance information relating to your Submission (such as start time, finish time and so on). Finally, your Study responses may contain information about you, even if that information does not directly identify you. This information will be associated with your MarsData ID and, although it will be pseudonymised, and will not contain direct identifiers like your name or contact details, it is personal data. Accordingly we require Researchers to comply with applicable law and their own ethics policies in relation to that personal data. And in particular, to agree that they will provide a privacy notice to each Participant sufficient to comply with applicable law. Further information is available in our Participant Privacy Notice.</li>
            </ul>

            <h3>5. Limitation of Liability</h3>
            <p>We provide a platform for research studies, connecting Researchers with Participants. Because our role is limited, and we don’t control the content, conduct or commercial value of Studies, we limit and exclude our liability to an appropriate degree. This section explains the ways in which our liability to you is limited and excluded.</p>
            <ul>
                <li>Where we don’t limit or exclude our liability. We do not limit or exclude our liability where not permitted by applicable law. For example, we do not limit our liability for death or personal injury caused by our negligence, or for fraud. This statement takes priority over the rest of the Terms.</li>
                <li>No implied terms. We provide access to the Website and MarsData on these Terms, and the documents referred to in them, alone. Any terms which might otherwise be implied by statute, under common law, or otherwise, are excluded.</li>
                <li>We don’t control the Internet. Communications networks are not always stable, and we may need to take down or maintain MarsData from time to time. Accordingly, we will not be liable if it is unavailable for any reason. MarsData is provided “as is” and without any warranty. For example, while obviously we do our best to correct defects, we do not warrant that MarsData will be uninterrupted, free from errors, or free from viruses or malicious code.</li>
                <li>Third parties. We are not responsible or liable for the actions of third parties. Third parties may include, for example, other users of MarsData, third party service providers who are linked from our Website, social media service providers or the providers of any device or software which you use to access MarsData.</li>
                <li>No indirect or consequential loss. We will not be liable for any indirect or consequential loss, damage, costs or expenses incurred or suffered by you in connection with your use of MarsData. We are not liable for losses, damages, costs and expenses that are not foreseeable to both you and us at the time you open your account with us, or which are not caused by our breach of these Terms.</li>
                <li>No liability for certain kinds of loss. We will not be liable for any loss of sales or business, loss of revenue, loss of agreements or contract, loss of anticipated savings, loss of use of corruption of software, data or information, loss of reputation or damage to goodwill, loss of profit, or indirect or consequential loss.</li>
                <li>Data loss. Since Studies are launched and conducted outside MarsData, we will not handle very much data on your behalf. We are not providing a backup service, and you should ensure that you have your own backups for whatever data of yours is stored in, or generated through, MarsData.</li>
                <li>Cap on liability. Our total aggregate liability to you in connection with your use of MarsData, however arising, will not exceed £20.00.</li>
                <li>Survival. This clause shall continue in force on termination or expiry of our agreement with you.</li>
            </ul>

            <h4>Researchers’ Liability</h4>
            <p>The Researcher’s liability to you will be limited and excluded to the same extent as our own in this Limitation of Liability section (unless otherwise stated in the Study Terms)except that nothing in this section limits the Researcher’s liability for any breach of applicable law.</p>

            <h3>6. Indemnity</h3>
            <p>If we are, or any Researcher is, subject to any third-party claim as a result of your breach of these Terms, or as a result of your negligence, misrepresentation, failure to comply with applicable law, or other wrongful act, then you will indemnify us (or the Researcher) against that claim. Indemnifying means you will compensate us or the Researcher against all costs, expenses, losses, damages or liabilities suffered or incurred in connection with that third-party claim, including any legal and professional fees. You will also provide all reasonable assistance and information requested from you in order to enable us or the Researcher to defend and settle the claim and mitigate any liability. We will not, and the Researcher will not, settle any claim against which you are required to indemnify without your prior written consent, provided that your consent is not unreasonably withheld, conditioned or delayed.</p>
            <p>This clause shall continue in force on termination or expiry of our agreement with you.</p>

            <h3>7. Termination</h3>
            <h4>Termination by you</h4>
            <p>You may stop using MarsData whenever you like, and can contact us at marsdataco@gmail.com to close your Participant Account. If you close your Participant Account, it is your responsibility to ensure that any Rewards in your Participant Account are claimed before closure.</p>

            <h4>Termination by us</h4>
            <p>We may limit, disable and/or suspend access to your Participant Account, and/or terminate our agreement with you and close your Participant Account with immediate effect without notice to you for any reason. Whilst we would not normally expect to do this without good reason, we are not required to provide any reason for suspension or termination, and any suspension or termination is entirely at our discretion.</p>
            <p>Without affecting any other right or remedy available to it, either party may terminate this agreement with immediate effect by giving written notice to the other party if:</p>
            <ul>
                <li>the other party fails to pay an amount due under this agreement on the due date for payment and remains in default not less than 30 days after being notified in writing to make such payment;</li>
                <li>the other party commits a material breach of any term of this agreement and (if such breach is remediable) fails to remedy that breach within a period of 30 days after being notified in writing to do so;</li>
                <li>the other party takes or has taken against it (other than in relation to a solvent restructuring) any step or action towards its entering bankruptcy, administration, provisional liquidation or any composition or arrangement with its creditors, applying to court for or obtaining a moratorium under Part A1 of the Insolvency Act 1986, being wound up (whether voluntarily or by order of the court), being struck off the register of companies, having a receiver appointed to any of its assets, or its entering a procedure in any jurisdiction with a similar effect to a procedure listed in this section; or</li>
                <li>the other party suspends or ceases, or threatens to suspend or cease, carrying on business.</li>
            </ul>
            <p>If you are in breach of these Terms, we may also at our option take any of the following actions:</p>
            <ul>
                <li>issue you with a written warning;</li>
                <li>take legal action against you; or</li>
                <li>if appropriate, disclose your details to law enforcement, regulatory authorities and/or (where necessary) any relevant Researcher(s) (e.g., if they need to enforce their rights against you).</li>
            </ul>

            <h4>Effects of termination</h4>
            <ul>
                <li>If we terminate when we have no reason to believe you are in breach of these Terms, then we will allow you to claim the balance of Rewards in your Participant Account.</li>
                <li>If we terminate when you are, or we reasonably suspect you are, in breach of these Terms, then:</li>
                <li>any Submission you have completed which is under review will be deemed returned, and no Reward will be payable to you for that Submission; and</li>
                <li>the balance of the Rewards in your Participant Account may be set off against any liability you may have to us or any Researcher, without limiting our or their other rights.</li>
            </ul>
            <p>Termination will not affect any obligations, liabilities, rights or remedies you or we may have under these Terms prior to termination.</p>

            <h4>Right of Appeal</h4>
            <p>Where your Participant Account has been suspended or terminated you are able to appeal the decision by getting directly in contact with our support team at marsdataco@gmail.com</p>
            <p>Whilst we are not obliged to give a reason for the suspension or termination of an account, we will, where possible, give you an opportunity to state the grounds for your appeal.</p>
            <p>However, if, after consideration of those grounds we do not change our decision there will be no further right of appeal and MarsData’s decision is final.</p>

            <h3>8. General</h3>
            <ul>
                <li>Transfers. You may not transfer your rights under our agreement with you without our prior written consent.</li>
                <li>Matters outside our control. We will not be liable for any delay or failure to perform our obligations under these Terms due to matters outside our control (like power failures, epidemic, network or communications failures, etc.).</li>
                <li>Entire agreement. These Terms, together with the other documents referred to in them, and any specific amendment we may agree with you in writing, are the entire agreement between you and us relating to your use of MarsData. You acknowledge that you have not entered into that agreement based on any other representation or warranty.</li>
                <li>No waiver. If you are in breach of these Terms, we can only waive that breach by written notice. If we do not immediately take action in relation to your breach we may still do so later.</li>
                <li>Severance. If any court or competent authority determines that any provision or part-provision of the Terms is unenforceable for any reason then that provision or part-provision will no longer apply and the rest of the Terms will continue in force.</li>
                <li>Third Party Rights. A person who is not a party to your agreement with us under these Terms will not have any rights under them, except that in relation to a given Study, the relevant Researcher will be able to enforce any provisions which expressly or impliedly confer a benefit on them pursuant to the Contract (Rights of Third Parties) Act 1999. In addition, please remember that in relation to any Study in which you participate, you will also be under an agreement with the Researcher on the relevant Study Terms. In short, this means if you do not comply with these Terms or the Study Terms you may be directly liable to any Researcher who suffers harm as a result.</li>
                <li>Governing law. Your agreement with us under these Terms is governed by the laws of England.</li>
                <li>Disputes with us. If any dispute arises between you and us in connection with your use of MarsData, then we’ll attempt to resolve that dispute through discussion. If we are unable to resolve the dispute by discussion, then the courts of England will have exclusive jurisdiction over any claim. If you are not resident in England, then we may bring proceedings against you in any court in your country of residence.</li>
                <li>Disputes with Researchers. If any dispute arises between you and any Researcher then we may try to help resolve it but are not obliged to do so.</li>
                <li>Notices. All notices relating to these Terms must be sent by e-mail or post. We will send notices to you at the e-mail or postal address associated with your account. You will send notices to us at marsdataco@gmail.com or by recorded delivery to our postal address of 483 Green Lanes, London, England, N13 4BS. E-mail notices will be deemed received when sent, provided no error or server message indicating non-delivery has been received by the sender. As an exception, if either you or we need to serve notice of legal proceedings then notice should be served in accordance with applicable civil procedure rules.</li>
                <li>Revisions, and last updated. We may revise these Terms from time to time. We’ll give you thirty (30) days’ notice of any material changes (normally through a service message). Any changes will not affect Studies already listed prior to those changes taking effect. By continuing to use MarsData you accept the revised Terms.</li>
            </ul>
            <p>Last updated July 2024.</p>
            <h3>Contact Us</h3>
            <p>MarsData Academic Ltd is a company formed under the laws of England and Wales with company registration number XYZ and its registered office at 483 Green Lanes, London, England, N13 4BS. You can contact us for support, or with queries at marsdataco@gmail.com.</p>
            <p>For data protection purposes, details of our registration with the UK Information Commissioner's Office and details of our EU representative can be found in our Participant Privacy Notice.</p>


        </div>
    );
};