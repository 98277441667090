import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from '../../i18n';
import { baseUrl } from '../../const';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast, getErrorMessage } from '../utils';

const initialState = {
    loading: 'idle',
    error: null,
    success: null,
    screeningQuestions: null
}

export const getScreeningQuestions = createAsyncThunk(
    'study/getScreeningQuestions',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/prescreens/get_all_screening_questions/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const saveUserResponse = createAsyncThunk(
    'study/saveUserResponse',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/prescreens/save_user_response/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)


export const requestPrescreen = createAsyncThunk(
    'study/requestPrescreen',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/prescreens/create_prescreen_request/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)


export const prescreenSlice = createSlice({
    name: 'prescreen',
    initialState,
    reducers: {
        // ******* Define synchronous actions ******* //
        clearMessages: (state) => {
            state.error = null;
            state.success = null;
            state.loading = 'idle';
        }
    },
    // ******* Define asynchronous actions ******* //
    extraReducers: builder => {
        builder
            .addCase(getScreeningQuestions.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getScreeningQuestions.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = null
                state.screeningQuestions = action.payload
            })
            .addCase(getScreeningQuestions.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(saveUserResponse.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(saveUserResponse.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t(action.payload)
                showSuccessToast(i18n.t(action.payload.detail));
            })
            .addCase(saveUserResponse.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(requestPrescreen.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(requestPrescreen.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('prescreenRequestCreated')
                showSuccessToast(i18n.t('prescreenRequestCreated'));
            })
            .addCase(requestPrescreen.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
    }
})

export const { clearMessages } = prescreenSlice.actions
export default prescreenSlice.reducer;