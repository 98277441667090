import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Loader from '../../components/common/Loader';
import FormContainer from '../../components/common/FormContainer';
import { resetPassword } from '../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import PasswordField from '../../components/common/PasswordField';
import { Grid, Typography } from '@mui/material';

function ResetPasswordScreen() {
    let { uid, token } = useParams(); // Assuming uid and token are passed as URL params
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState('');
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.user);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(resetPassword({ uid: uid, token: token, new_password: newPassword }));
    };

    return (
        <div className='screen-notloggedin'>
            <FormContainer mdval={6}>
                {loading === 'pending' && <Loader />}

                <Typography variant="h4" gutterBottom>
                    {t('resetPassword')}
                </Typography>

                <form onSubmit={submitHandler}>

                    <PasswordField setPassword={setNewPassword} placeholder='enterNewPassword' />
                    <Grid container justifyContent="flex-end" sx={{ marginTop: '6px' }}>
                        <Grid item>
                            <Button type='submit' variant='outlined'>
                                {t('changePassword')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormContainer>
        </div>
    );
}

export default ResetPasswordScreen;