import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { logout } from '../../features/user/userSlice.js';
import { useHistory } from 'react-router-dom';

function stringToColor(string) {
    if (!string) {
        return '000000';
    }

    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name) {
    if (!name) {
        return {
            sx: {
                bgcolor: '000000',
            },
            children: '',
        };
    }

    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function AvatarCircle() {
    const { userInfo } = useSelector(state => state.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openProfileMenu = Boolean(anchorEl);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const username = () => {
        if (userInfo?.user) {
            return userInfo.user.first_name + " " + userInfo.user.last_name
        }
    }

    const logoutHandler = () => {
        dispatch(logout());
        history.push('/login')
    }

    return (
        <>
            <div
                id="basic-button"
                aria-controls={openProfileMenu ? 'basic-menu' : undefined}
                aria-expanded={openProfileMenu ? 'true' : undefined}
                onClick={handleClick}
                className='mobile-no-margin-padding'
                style={{ cursor: 'pointer', display: 'inline-block', marginRight: '36px' }}
            >
                <Avatar {...stringAvatar(username())} className='mobile-no-margin-padding' />
            </div>
            
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openProfileMenu}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => history.push('/profile')}>
                        {t('profile')}
                </MenuItem>
                <MenuItem onClick={() => { handleClose(); logoutHandler(); history.push('/') }}>
                    {t('logout')}
                </MenuItem>
            </Menu>
        </>
    );
}