import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/common/Loader'
import FormContainer from '../../components/common/FormContainer'
import PasswordField from '../../components/common/PasswordField'
import { login, clearMessages } from '../../features/user/userSlice'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { resendActivationEmail } from '../../features/user/userSlice'
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Grid';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { InputAdornment } from "@mui/material";

function LoginScreen() {
    const history = useHistory();
    const { t } = useTranslation();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showReactivate, setShowReactivate] = useState(false)
    const [errors, setErrors] = useState({});
    const [touchedFields, setTouchedFields] = useState({});
    const dispatch = useDispatch()
    const { userInfo, loading, error, triedEmail } = useSelector(state => state.user)

    useEffect(() => {
        if (userInfo?.user?.user_type === 'researcher') {
            history.push('/researcher')
        } else if (userInfo?.user?.user_type === 'participant') {
            history.push('/participant')
        } else {
            history.push('/login')
        }
    }, [history, userInfo])

    useEffect(() => {
        if (error === 'Account not active. Please activate your account.' ||
            error === 'Hesap aktif değil. Lütfen hesabınızı aktifleştirmek için emailinizi kontrol edin. Eğer email almadıysanız, aşağıdaki butona tıklayarak emailinizi tekrar gönderebilirsiniz.') { // TODO: use a constant later on
            setShowReactivate(true)
        }

        if (error === 'participantAccountNotVerifiedError') {
            history.push('/waiting-for-approval');
            dispatch(clearMessages());
        }
    }, [error, history, dispatch])

    const handleBlur = (field) => {
        setTouchedFields({ ...touchedFields, [field]: true });
    };

    const validateFields = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = t('emailRequired');
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = t('invalidEmail');
        }
        if (!password) {
            newErrors.password = t('passwordRequired');
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const submitHandler = (e) => {
        e.preventDefault()
        const isValid = validateFields();
        if (isValid) {
            dispatch(login({ email: email, password: password }))
        }
    }

    const handleResendActivation = () => {
        dispatch(resendActivationEmail({ email: triedEmail }))
    }

    return (
        <div className='screen-notloggedin'>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ width: '100%' }}
            >
                <Grid item style={{ width: '40%', minWidth: '370px' }}>
                    <Card style={{ padding: 24, width: '100%' }}>
                        <FormContainer display="flex" style={{ width: '100%' }}>
                            <Typography variant='h3' style={{ marginBottom: 18 }}>{t('login')}</Typography>
                            {showReactivate ? <Button className='btn-secondary' style={{ color: '#0C4CAA' }} onClick={() => handleResendActivation()}>{t('resendEmail')}</Button> : <></>}
                            {loading === "pending" ? <Loader /> : <></>}

                            <form onSubmit={submitHandler}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sx={{ mb: '12px' }}>
                                        <TextField
                                            fullWidth
                                            autoComplete="on"
                                            name='email'
                                            type="email"
                                            placeholder={t('enterEmail')}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onBlur={() => handleBlur('email')}
                                            error={!!errors.email}
                                            helperText={errors.email}
                                            variant="outlined"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} 
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: '18px' }}>
                                        <PasswordField
                                            setPassword={setPassword}
                                            onBlur={() => handleBlur('password')}
                                            error={!!errors.password}
                                            helperText={errors.password}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Link underline="hover" component={RouterLink} to={'/password_reset'}>
                                            <Typography variant='body1'>{t('forgotPassword')}</Typography>
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12} sx={{ mt: '18px' }}>
                                        <Button type='submit' variant='contained' style={{ width: '100%', marginTop: 18 }}>
                                            <Typography variant='body1'>{t('login')}</Typography>  <ArrowForwardIcon sx={{ ml: 1 }} />
                                        </Button>

                                        <div style={{ marginTop: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography variant='body1'>{t('newCustomer')}</Typography> <Link underline="hover" component={RouterLink} to={'/register'}>
                                                &nbsp;<b>{t('register')}</b></Link>
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormContainer>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default LoginScreen