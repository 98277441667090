import React from 'react';

export const Terms = () => {
    return (
        <div>

            <p>This is a short summary only, for ease of reading, which is not legally binding. Our full version below has much more
                detail and constitutes a binding contract: you should make sure you’ve read and understood the full version.</p>

            <h2>Overview, and how MarsData works</h2>
            <p>We’re MarsData Academic Ltd, a TR company. When you
                use our platform, MarsData, you have to accept our Participant Terms.</p>
            <p>MarsData can be used by researchers to list studies, and those listings are visible to eligible participants like you
                who also use MarsData.</p>
            <p>When a researcher lists a study they agree to pay rewards to participants who take part in that study, and when a
                participant like you elects to take part in that study you agree to provide a submission to the study in exchange
                for payment. That creates a simple contract between you and the researcher, known as the Study Terms.</p>
            <p>MarsData is used to connect researchers with participants, but the studies themselves are typically conducted outside
                MarsData on whatever platform the researcher has chosen to use. There is an exception to this: where the researcher
                uses a data collection tool on MarsData to launch and conduct their study, and the Managed Data Collection Studies –
                Supplementary Terms will apply. It will be made clear when that is the case.</p>
            <p>When you complete a study, and the researcher approves your submission, we’ll pay the agreed reward to you on behalf
                of the researcher.</p>

            <h2>Your participant account</h2>
            <p>When you apply to register your MarsData account, we may ask you to go through some onboarding processes like ID
                checks to help identify you and maintain trust in our platform.</p>
            <p>Your account is personal to you. You have to keep it secure, and not let anyone else use it.</p>

            <h2>Taking part in studies</h2>
            <p>When a researcher lists a study, they can enter pre-screening criteria so that only participants with certain
                demographic profile information can participate in it. You can only participate in studies for which you are
                eligible.</p>
            <p>You should make sure you have read and understood any terms which the researcher has included in their study listing,
                and that you comply with any submission requirements (in particular in relation to quality or timing).</p>
            <p>Because studies are usually conducted outside the MarsData platform:</p>
            <ul>
                <li>we will not ‘see’ or moderate in any way the content of the study or the content of your submissions. That
                    “distance” is an important part of our model and helps preserve the anonymity of submissions and ensure the
                    integrity of studies;</li>
                <li>we ask researchers to ensure that studies are conducted ethically, lawfully and with all necessary approvals,
                    but we’re not responsible for their conduct; and</li>
                <li>if any issues arise in connection with a study, the researcher is responsible for resolving them in much the
                    same way they would resolve issues arising from comparable offline studies.</li>
            </ul>

            <h2>Receiving rewards</h2>
            <p>We require researchers to deposit the balance necessary to pay rewards before launching the relevant study, so you’re
                assured that if your submission is approved you’ll be paid. Your reward will be paid after your submission is
                approved, which we ask researchers to do promptly, though it should take no longer than twenty-one (21) days. If
                your submission is not approved, you will not receive a reward.</p>
            <p>You should claim rewards and pay them out to your account regularly: if no rewards are credited to your account for a
                twelve (12) month period, we reserve the right to close your account and disburse any unredeemed rewards to your
                linked payment account.</p>
            <p>Any rewards you earn may be taxable income. It is your responsibility to pay tax and social security to the tax
                authority in the jurisdiction in which you are tax resident.</p>

            <h2>Data protection</h2>
            <p>We’ll use your personal data in accordance with our Participant Privacy Notice.</p>
            <p>We don’t provide direct identifiers of participants to researchers (so we’ll never provide your name, email address,
                or telephone number), unless - for example, you’re in breach of your Study Terms with a researcher. However, some
                researchers may choose to request this information as part of a study, and you will have the choice as to whether or
                not to share it. Where this is the case, researchers must make clear upfront what personal data may be requested as
                part of a study and how it will be used in the study listing so you can decide if you’re comfortable taking part.
            </p>
            <p>When a researcher conducts studies, they may also receive from us some very limited information in relation to
                participants: basic demographic information, performance information and responses to pre-screening questions. This
                information is provided by reference to a participant’s MarsData ID (a string of random numbers and letters) and not
                their name, email address, etc. Even though your MarsData ID does not directly identify you, by law, it is still
                treated as “personal data”. For that reason, we require researchers to comply with applicable law when they receive
                even that information from us, as well as when they collect any other information in the course of conducting a
                study. We are not responsible for any use, or misuse, of your personal data by researchers - they are responsible
                for its collection and use.</p>
            <p>Data protection laws are developing rapidly and it’s likely that we will update features of MarsData, and our
                Participant Terms, from time to time to reflect changes in law or in our practices.</p>

            <h2>Liability</h2>
            <p>Because our role in connecting researchers with participants is limited, and we don’t control the content, conduct or
                value of studies, we limit and exclude our liability to an appropriate degree.</p>
            <p>In particular, we don’t accept liability for losses which are not foreseeable, and our liability to any participant
                is generally capped at a maximum of £20.00.</p>

            <h2>Termination</h2>
            <p>You can stop using MarsData and close your account whenever you like.</p>
            <p>We can suspend or terminate access to your account at any time, although we would not normally expect to do this
                without good reason.</p>
            <p>After termination, all funds in your account will be returned to you, except that if you are liable to us for any
                reason we may retain some of all of those funds as an offset against that liability.</p>

            <h2>General legal provisions</h2>
            <p>Your account cannot be transferred without our consent.</p>
            <p>Your agreement with us is governed by the laws of England and subject to the exclusive jurisdiction of the courts of
                England.</p>
            <p>If any dispute arises between you and any researcher then we may try to help resolve it but are not obliged to do so.
            </p>

            <h2>Our details</h2>
            <p>MarsData Academic Ltd is a company formed under the laws of England and Wales with company registration number
                XYZ and its registered office at XYZ. You can contact us
                for support, or with queries or notices at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>.</p>

            <h2>Revisions, and last updated</h2>
            <p>We may revise our Participant Terms from time to time, and will notify users of MarsData of material changes
                (normally through a service message) at least thirty (30) days before our Participant Terms are updated. Any changes
                will not affect studies already listed prior to those changes taking effect. By continuing to use MarsData you
                accept the revised Participant Terms.</p>
            <p>Last updated July 2024.</p>

        </div>
    );
};