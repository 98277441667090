import * as React from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MessageIcon from '@mui/icons-material/Message';
import GoBackButton from './GoBackButton.js';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import QuizIcon from '@mui/icons-material/Quiz';
import { clearMessages } from '../../features/study/studySlice.js';
import { clearMessages as clearProjectMessages } from '../../features/project/projectSlice.js';
import { useLocation } from 'react-router-dom';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import AvatarCircle from './AvatarCircle.js';
import WalletIcon from '@mui/icons-material/Wallet';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoginIcon from '@mui/icons-material/Login';

import LogoResearcherEn from '../../static/images/md_r_en.png';
import LogoResearcherTr from '../../static/images/md_r_tr.png';
import LogoParticipantEn from '../../static/images/md_p_en.png';
import LogoParticipantTr from '../../static/images/md_p_tr.png';
import LogoBlack from '../../static/images/md_b.png';
import { Stack } from '@mui/material';
import LanguageSwitcher from './LanguageSwitcher.js';
import FolderIcon from '@mui/icons-material/Folder';
import ResearcherRatingTooltip from './ResearcherRatingTooltip.js';

import { useMediaQuery, useTheme } from '@mui/material';

const AppAppBar = () => {
    const { userInfo } = useSelector(state => state.user);
    const { users } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const location = useLocation();
    const isProjectPath = location.pathname.includes('project');
    const isHomePage = location.pathname === '/researcher' || location.pathname === '/participant';
    const userType = userInfo?.user?.user_type ?? '';
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const openNewStudy = () => {
        dispatch(clearMessages())
        dispatch(clearProjectMessages())
        history.push("/newstudy")
    }

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll,
                behavior: 'smooth',
            });
        }
    };

    const logoStyle = {
        width: '100px',
        height: 'auto',
        cursor: 'pointer',
        marginRight: '12px',
    };

    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: '#ffffff',
                    backgroundImage: 'none',
                    width: '100%',
                    border: 0
                }}
            >
                <Toolbar
                    variant="regular"
                    sx={(theme) => ({
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'right',
                        flexShrink: 0,
                        height: 40
                    })}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, marginLeft: '36px' }}>
                        <img
                            src={
                                userInfo?.user?.user_type === 'researcher'
                                    ? t('i18n').language === 'en'
                                        ? LogoResearcherEn
                                        : LogoResearcherTr
                                    : userInfo?.user?.user_type === 'participant'
                                        ? t('i18n').language === 'en'
                                            ? LogoParticipantEn
                                            : LogoParticipantTr
                                        : LogoBlack
                            }
                            style={logoStyle}
                            alt="logo of marsdata"
                            onClick={() => history.push('/' + userType)}
                        />

                        {userInfo && !isHomePage ? <GoBackButton /> : <></>}
                    </Box>

                    {isProjectPath ? <>
                        <Button onClick={openNewStudy} color="primary" variant="text" size="small" style={{ marginLeft: '6px' }}>
                            <Stack direction="row" spacing={1}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <AddCircleIcon fontSize='medium' className='icon-color-generic' />
                                    <Typography style={{ marginLeft: '6px', marginRight: '6px' }} variant="body2" color="text.primary" className='mobile-hidden'>{t('newStudy')}</Typography>
                                </div>
                            </Stack>
                        </Button>
                    </> : <></>}

                    {/* Items for Home Page (not logged in yet) */}
                    {!userInfo ?
                        <>
                            <MenuItem
                                onClick={() => scrollToSection('faq')}
                                sx={{ py: '6px', px: '12px' }}
                            >
                                <Typography variant="body2" color="text.primary">
                                    {t('faq')}
                                </Typography>
                            </MenuItem>
                        </> : <></>}

                    {/* Items for Researchers */}
                    {userInfo?.user?.user_type === 'researcher' ? (
                        <>
                            <Button onClick={() => history.push('/' + userType)} color="primary" variant="text" size="small"
                                style={{ marginLeft: '6px' }} className='mobile-no-margin-padding'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <FolderIcon fontSize='medium' className='icon-color-generic' />
                                    <Typography variant="body2" color="text.primary" className="mobile-hidden" style={{ marginLeft: '6px', marginRight: '6px' }}>
                                        {t('projects')}
                                    </Typography>
                                </div>
                            </Button>

                            <Button onClick={() => history.push('/payment')} color="primary" variant="text" size="small" className='mobile-no-margin-padding'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <WalletIcon fontSize='medium' className='icon-color-generic' />
                                    <Typography variant="body2" color="text.primary" className="mobile-hidden"
                                        style={{ marginRight: '6px', marginLeft: '6px' }}
                                    >{t('budget')}: ₺{userInfo.user.budget}</Typography>
                                </div>
                            </Button>

                            <Button onClick={() => history.push('/active-studies')}
                                color="primary"
                                style={{ marginLeft: '6px' }}
                                className='mobile-no-margin-padding'
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <PlayCircleFilledIcon className='icon-color-generic' fontSize='medium' style={{ color: '#264573' }} />
                                    <Typography variant="body2" color="text.primary" className='mobile-hidden' style={{ marginLeft: '6px', marginRight: '6px' }}>{t('activeStudies')}</Typography>
                                </div>
                            </Button>

                            <Button onClick={() => history.push('/recent-studies')}
                                color="primary"
                                style={{ marginLeft: '6px' }}
                                className='mobile-no-margin-padding'
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <WorkHistoryIcon className='icon-color-generic' fontSize='medium' style={{ color: '#264573' }} />
                                    <Typography variant="body2" color="text.primary" className='mobile-hidden' style={{ marginLeft: '6px', marginRight: '6px' }}>{t('recentStudies')}</Typography>
                                </div>
                            </Button>
                        </>
                    ) : <></>}

                    {/* Items for workers: 'Studies', 'Dashboard' */}
                    {userInfo?.user?.user_type === 'participant' ? (
                        <>

                            <Button onClick={() => history.push('/participant')}
                                color="primary"
                                style={{ marginLeft: '6px' }}
                                className='mobile-no-margin-padding'
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <WorkOutlineIcon className='icon-color-generic' fontSize='medium' style={{ color: '#264573' }} />
                                    <Typography variant="body2" color="text.primary" className='mobile-hidden' style={{ marginLeft: '6px', marginRight: '6px' }}>{t('studies')}</Typography>
                                </div>
                            </Button>

                            <Button onClick={() => history.push('/dashboard')}
                                style={{ marginLeft: '6px' }}
                                className='mobile-no-margin-padding'>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DashboardIcon className='icon-color-generic' fontSize='medium' style={{ color: '#264573' }} />
                                    <Typography variant="body2" color="text.primary" className='mobile-hidden' style={{ marginLeft: '6px', marginRight: '6px' }}>{t('dashboard')}</Typography>
                                </div>
                            </Button>

                            <Button onClick={() => history.push('/screening-questions')}
                                color="primary"
                                style={{ marginLeft: '6px' }}
                                className='mobile-no-margin-padding'
                            >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <QuizIcon className='icon-color-generic' fontSize='medium' style={{ color: '#264573' }} />
                                    <Typography variant="body2" color="text.primary" className='mobile-hidden' style={{ marginLeft: '6px', marginRight: '6px' }}>{t('screeningQuestions')}</Typography>
                                </div>
                            </Button>
                        </>
                    ) : (<></>)}

                    {userInfo ?
                        <>
                            {users && users.some(user => user.last_message_read === false) ?
                                <Button onClick={() => history.push('/messages')}
                                    color="primary"
                                    style={{ marginLeft: '6px' }}
                                    className='mobile-no-margin-padding'
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <MessageIcon className='icon-color-generic' fontSize='medium' />
                                        <Typography style={{ marginLeft: '6px', marginRight: '6px' }} variant="body2" color="text.primary" className='mobile-hidden'>{t('messages')}</Typography> <FiberNewIcon />
                                    </div>
                                </Button>
                                :
                                <Button onClick={() => history.push('/messages')}
                                    color="primary"
                                    style={{ marginLeft: '6px' }}
                                    className='mobile-no-margin-padding'
                                >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <MessageIcon className='icon-color-generic' fontSize='medium' style={{ color: '#264573' }} />
                                        <Typography style={{ marginLeft: '6px', marginRight: '6px' }} variant="body2" color="text.primary" className='mobile-hidden'>{t('messages')}</Typography>
                                    </div>
                                </Button>}
                        </> : <></>}

                    {/* Items for Home Page (not logged in yet) */}
                    {!userInfo ? (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 0.5,
                                alignItems: 'center',
                                flexDirection: { xs: 'column', md: 'row' }
                            }}
                        >
                            {isMobile ? (
                                // Show only one login button on mobile devices
                                <Button
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    sx={{
                                        padding: '8px 16px', // Original padding
                                        fontSize: '0.875rem', // Original font size
                                        backgroundColor: '#243cc7', // Original background color
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: '#243cc7',
                                        },
                                    }}
                                    onClick={() => history.push('/login')}
                                >
                                    <LoginIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                                    {t('login')} {/* Use a generic login label */}
                                </Button>
                            ) : (
                                // Show all buttons on larger screens
                                <>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        sx={{
                                            padding: '8px 16px',
                                            fontSize: '0.875rem',
                                            backgroundColor: '#243cc7',
                                            color: '#ffffff',
                                            '&:hover': {
                                                backgroundColor: '#243cc7',
                                            },
                                        }}
                                        onClick={() => history.push('/login')}
                                    >
                                        <LoginIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                                        {t('researcherLogin')}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        sx={{
                                            padding: '8px 16px',
                                            fontSize: '0.875rem',
                                            backgroundColor: '#de5a02',
                                            color: '#ffffff',
                                            '&:hover': {
                                                backgroundColor: '#de5a02',
                                            },
                                        }}
                                        onClick={() => history.push('/login')}
                                    >
                                        <LoginIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                                        {t('participantLogin')}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{
                                            padding: '8px 16px',
                                            fontSize: '0.875rem',
                                            backgroundColor: '#545454',
                                            color: '#ffffff',
                                            '&:hover': {
                                                backgroundColor: '#545454',
                                            },
                                        }}
                                        onClick={() => history.push('/register')}
                                    >
                                        <AppRegistrationIcon sx={{ verticalAlign: 'middle', marginRight: 1 }} />
                                        {t('register')}
                                    </Button>
                                </>
                            )}
                        </Box>
                    ) : null}


                    {/* Items for Logged in Users */}
                    {(userInfo?.user?.user_type === 'researcher') & (userInfo?.user?.n_ratings > 0) ?
                        <ResearcherRatingTooltip
                            rating_ux={userInfo?.user?.rating_ux}
                            rating_fairness={userInfo?.user?.rating_fairness}
                            rating_time={userInfo?.user?.rating_time}
                            rating_compensation={userInfo?.user?.rating_compensation}
                            n_ratings={userInfo?.user?.n_ratings}
                        >
                            <Box
                                sx={{
                                    display: { xs: 'flex', md: 'flex' },
                                    gap: 0.5,
                                    alignItems: 'center',
                                }}
                            ><AvatarCircle /></Box>
                        </ResearcherRatingTooltip> :
                        userInfo?.user ? <AvatarCircle /> : <></>
                    }

                    {/* Items for right-side of the bar */}
                    {userInfo ? <></> : <LanguageSwitcher />}

                </Toolbar>
            </AppBar>
        </div >
    );
}

export default AppAppBar;