import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';

const GoBackButton = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <Button onClick={handleGoBack} size="small" sx={{
            borderRadius: 2,
            variant: 'outlined',
        }} className='mobile-hidden'>
            <ArrowLeftIcon />
            <Typography variant="body2" color="text.primary">{t('goBack')}</Typography>
        </Button>
    );
};

export default GoBackButton;