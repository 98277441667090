import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CleanAndSplitIDs } from '../../utils/utils';
import { CheckIDValidity } from '../../utils/utils';
import { getBulkMessages, sendMessage } from '../../features/message/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import Tooltip from '@mui/material/Tooltip';
import { getMessages } from '../../features/message/messageSlice';

const ComposeMessageModal = ({ open, setOpen, alreadySelectedUsers, setSelectedTab }) => {
    const onCloseModal = () => setOpen(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.user);
    const [formData, setFormData] = useState({
        recipients: alreadySelectedUsers || '',
        content: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        const isValid = CheckIDValidity(formData.recipients, t);
        if (!isValid) { return; }

        const recipientIDs = CleanAndSplitIDs(formData.recipients);
        if (!formData.content.trim()) return;

        // If there is just one recipient
        if (recipientIDs.length === 1) {
            dispatch(sendMessage({ receiver: recipientIDs, content: formData.content, user_id: userInfo.user.id })).then(() => {
                setSelectedTab(1);
                dispatch(getMessages({ access: userInfo.user.access, userId: recipientIDs[0] }))
            })
        } else {
            dispatch(sendMessage({ receiver: recipientIDs, content: formData.content, user_id: userInfo.user.id })).then(() => {
                setSelectedTab(2);
                dispatch(getBulkMessages({ access: userInfo.user.access }));
            });
        }

        onCloseModal();
    };

    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            aria-labelledby="compose-message-modal"
            aria-describedby="compose-a-new-message"
            closeAfterTransition
        >
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    m: 2,
                    borderRadius: 1
                }}
                className='mobile-width'
            >
                <Typography variant="h6" id="compose-message-modal" sx={{ mb: 2 }}>
                    {t('composeMessage')}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Tooltip title={t('composeMessageTooltip')}>
                        <TextField
                            fullWidth
                            label={t('recipients')}
                            value={alreadySelectedUsers ? alreadySelectedUsers : formData.recipients}
                            variant="outlined"
                            margin="normal"
                            onChange={(e) => setFormData({ ...formData, recipients: e.target.value })}
                            required
                        />
                    </Tooltip>
                    <TextField
                        fullWidth
                        label={t('content')}
                        variant="outlined"
                        margin="normal"
                        multiline
                        rows={4}
                        onChange={(e) => setFormData({ ...formData, content: e.target.value })}
                        required
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary">
                            {t('send')}
                            <SendIcon style={{ marginLeft: '6px' }} />
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};

export default ComposeMessageModal;