import React, { useState } from "react";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import { useTranslation } from 'react-i18next';
import LockIcon from '@mui/icons-material/Lock';
import WarningIcon from '@mui/icons-material/Warning';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordField = ({ setPassword, error, helperText, onBlur, label='password', placeholder='enterPassword' }) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);

    const togglePasswordVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleKeyUp = (e) => {
        if (e.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    };

    return (
        <TextField
            fullWidth
            variant="outlined"
            placeholder={t(placeholder)}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleKeyUp}
            type={isVisible ? "text" : "password"}
            required
            error={error}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 },}} 
            helperText={helperText}
            onBlur={onBlur}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LockIcon />
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                        {isCapsLockOn && (
                            <Tooltip title={t('capsLockOnWarning')} arrow>
                                <WarningIcon color="warning" fontSize="small" />
                            </Tooltip>
                        )}
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                            {isVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordField;