import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, Grid, IconButton, Drawer, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Message from '../../components/chat/Message';
import UserMenu from '../../components/chat/UserMenu';
import { sendMessage, getMessages } from '../../features/message/messageSlice';
import { useDispatch, useSelector } from 'react-redux';
import SubmissionEvent from '../../components/chat/SubmissionEvent';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation } from 'react-router-dom';
import ComposeMessageModal from '../../components/chat/ComposeMessageModal';
import ProjectsDrawer from '../../components/researcher/project/ProjectsDrawer';
import Loader from '../../components/common/Loader';
import ReceiversModal from '../../components/chat/ReceiversModal';
import ParticipantDrawer from '../../components/participant/ParticipantDrawer';

const ChatScreen = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [currentMessage, setCurrentMessage] = useState('');
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);  // State to control UserMenu visibility
    const { currentReceiver, messages, loading } = useSelector(state => state.message);
    const { userInfo } = useSelector(state => state.user);
    const [open, setOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [openReceiversModal, setOpenReceiversModal] = useState(false);
    const [bulkReceivers, setBulkReceivers] = useState([]);
    const isXs = window.innerWidth < 600;

    const participantIDs = location?.state?.participantIDs;

    useEffect(() => {
        if (!userInfo) {
            window.location.href = '/login';
        }
    }, [userInfo]);

    const messagesEndRef = useRef(null);
    const dispatch = useDispatch();

    const handleSendMessage = () => {
        if (!currentMessage.trim()) return;
        dispatch(sendMessage({
            receiver: [currentReceiver], content: currentMessage,
            user_id: userInfo.user.id
        }));
        setCurrentMessage('');
    };

    useEffect(() => {
        if (selectedTab === 0) {
            const interval = setInterval(() => {
                if (currentReceiver) {
                    dispatch(getMessages({ access: userInfo.user.access, userId: currentReceiver }));
                }
            }, 60 * 1000);

            return () => clearInterval(interval);
        }
    }, [dispatch, currentReceiver, userInfo.user.access, selectedTab]);

    useEffect(() => {
        if (currentReceiver) {
            dispatch(getMessages({ access: userInfo.user.access, userId: currentReceiver }));
        }
    }, [dispatch, currentReceiver, userInfo.user.access]);

    const handleSelectUser = (user) => {
        dispatch(getMessages({ access: userInfo.user.access, userId: user.id }));
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        if (!userInfo) {
            window.location.href = '/login';
        }
    }, [userInfo]);

    // If users are already selected
    useEffect(() => {
        if (participantIDs) {
            setOpen(true);
        }
    }, [participantIDs]);

    const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };

    useEffect(() => { if (open) { let root = document.getElementById("root"); root?.setAttribute("aria-hidden", "false"); } }, [open]);

    return (
        <>
            {loading === 'pending' && <Loader />}
            {userInfo?.user?.user_type === 'researcher' ? <ProjectsDrawer /> : <ParticipantDrawer />}

            <ComposeMessageModal open={open} setOpen={setOpen} alreadySelectedUsers={participantIDs} setSelectedTab={setSelectedTab} />
            <ReceiversModal open={openReceiversModal} setOpen={setOpenReceiversModal} receivers={bulkReceivers} />

            <div className="screen">
                <Grid container spacing={1}>

                    <Grid item xs={12} md={4}>
                        {!isXs && (
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <UserMenu onSelectUser={handleSelectUser} setOpen={setOpen} selectedTab={selectedTab} setSelectedTab={setSelectedTab} toggleUserMenu={toggleUserMenu} setBulkReceivers={setBulkReceivers} setOpenReceiversModal={setOpenReceiversModal} />
                            </Box>
                        )}

                        {isXs && (
                            <Box sx={{ position: 'fixed', bottom: '3%', left: '12px', zIndex: 2000 }}>
                                <IconButton edge="start" color="black" onClick={toggleUserMenu}
                                    sx={{ borderRadius: '2px', backgroundColor: 'white', border: 1, padding: 0.5, left: '6px' }}
                                >
                                    <MenuIcon /> <Typography>{t('users')}</Typography>
                                </IconButton>
                            </Box>
                        )}

                        <Drawer
                            anchor="left"
                            open={isUserMenuOpen}
                            onClose={toggleUserMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '75%' },
                            }}
                        >
                            <Box p={2} role="presentation" onKeyDown={toggleUserMenu}>
                                <UserMenu onSelectUser={handleSelectUser} setOpen={setOpen} selectedTab={selectedTab} setSelectedTab={setSelectedTab} toggleUserMenu={toggleUserMenu} setBulkReceivers={setBulkReceivers} setOpenReceiversModal={setOpenReceiversModal} />
                            </Box>
                        </Drawer>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Box
                            sx={{
                                maxHeight: '85vh',
                                overflowY: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                marginBottom: '12px',
                                paddingBottom: { xs: '90px', md: '100px' }, // Adjust padding for mobile  
                            }}
                        >
                            {messages && messages.map((msg, index) =>
                                msg && (
                                    msg.status ? (
                                        <SubmissionEvent key={`${msg.date}-${index}`} status={msg.status} date={msg.date} title={msg.study} />
                                    ) : (
                                        <Message key={`${msg.date}-${index}`} message={msg.content} isOwnMessage={msg.sender === userInfo.user.id} date={msg.date} />
                                    )
                                )
                            )}
                            <div ref={messagesEndRef} />
                        </Box>
                        {selectedTab !== 2 && currentReceiver && (
                            <Box
                                sx={{
                                    position: 'fixed',
                                    bottom: 72,
                                    width: { xs: '100%', md: '50%' },
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '10px',
                                    backgroundColor: '#fff',
                                    zIndex: 1000,
                                }}
                            >
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    value={currentMessage}
                                    onChange={(e) => setCurrentMessage(e.target.value)}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { handleSendMessage(); } }}
                                    placeholder={t('typeMessageText')}
                                />
                                <Button variant="contained" onClick={handleSendMessage} sx={{ marginLeft: '6px' }}>
                                    {t('send')}
                                    <SendIcon style={{ marginLeft: '6px' }} />
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default ChatScreen;