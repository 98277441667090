import React from "react";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";


const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const handleLanguageChange = (e) => {
        var newLang = e.target.value;
        if (newLang === 'en') {
            newLang = 'en-EN';
        } else if (newLang === 'tr') {
            newLang = 'tr-TR';
        }
        i18n.changeLanguage(newLang);
    };

    return (
        <FormControl size="small" sx={{ mr: '36px' }} className='mobile-hidden'>
            <Select
                name='language'
                value={i18n.language === 'tr' || i18n.language === 'tr-TR' ? 'tr-TR' : 'en-EN'}
                onChange={handleLanguageChange}
                sx={{ padding: 0 }}
            >
                <MenuItem value="en-EN" label="US" id="en">
                    <Typography>
                        US
                    </Typography>
                </MenuItem>
                <MenuItem value="tr-TR" label="TR" id="tr">
                    <Typography variant="body1">
                        TR
                    </Typography>
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;