import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from './features/user/userSlice'
import studySlice from './features/study/studySlice'
import projectSlice from './features/project/projectSlice'
import participantSlice from './features/participant/participantSlice'
import messageSlice from './features/message/messageSlice'
import paymentSlice from './features/payment/paymentSlice'
import prescreenSlice from './features/prescreen/prescreenSlice'
import axios from 'axios'
import { refreshToken } from './features/user/userSlice'
import {
    persistStore, persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const reducer = combineReducers({
    user: userSlice,
    studies: studySlice,
    projects: projectSlice,
    participant: participantSlice,
    message: messageSlice,
    payment: paymentSlice,
    prescreen: prescreenSlice
})

const rootReducer = (state, action) => {
    if (action.type === 'user/logout') {
        // This will reset the state to the initial state
        state = undefined;
    }
    return reducer(state, action);
};

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),

})

export const persistor = persistStore(store)


axios.interceptors.response.use(
    response => response,
    async error => {

        const originalRequest = error.config;
        let errorMessage = error.response.data.detail;
        if (error.response.status === 401 && errorMessage === "Given token not valid for any token type" && !(originalRequest._retryCount && originalRequest._retryCount >= 3)) {
            // Increment the retry count
            originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;

            // Dispatch the refreshToken action
            await store.dispatch(refreshToken());

            // Get the new token from the state
            const state = store.getState();
            const newToken = state.user.userInfo.user.access;

            // Update the Authorization header of the original request
            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

            // Retry the original request
            return axios(originalRequest);
        }
        
        return Promise.reject(error);
    }
);
