import React, { lazy, Suspense } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Switch } from 'react-router-dom';
import AppAppBar from './components/common/NewHeader';
import Footer from './components/common/Footer';
import { ConfirmProvider } from "material-ui-confirm";
import { ToastContainer } from 'react-toastify';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import ReportBugButton from './components/common/ReportBugButton';
import ReportBugModal from './components/common/ReportBugModal';
import LoadingSpinner from './components/common/LoadingSpinner';
import Breadcrumbs from './components/common/Breadcrumbs'; // Import Breadcrumbs

// Common Screens (loaded initially)
import LoginScreen from './screens/common/LoginScreen';
import ProfileScreen from './screens/common/ProfileScreen';
import RegisterScreen from './screens/common/RegisterScreen';
import ChatScreen from './screens/common/ChatScreen';
import ResetPasswordScreen from './screens/common/ResetPassword';
import ForgotPasswordScreen from './screens/common/ForgotPassword';
import ActivateAccountScreen from './screens/common/ActivateAccount';
import { TermsAndConditions } from './screens/common/TermsAndConditions';
import { PrivacyPolicy } from './screens/common/PrivacyPolicy';
import ScrollToTop from './components/common/ScrollToTop';

// Lazy-loaded Participant Screens
const HomeScreenParticipant = lazy(() => import('./screens/participant/HomeScreenParticipant'));
const DashboardParticipant = lazy(() => import('./screens/participant/DashboardParticipant'));
const WorkScreen = lazy(() => import('./screens/participant/WorkScreen'));
const CompleteScreen = lazy(() => import('./screens/participant/CompleteScreen'));
const WaitingForApproval = lazy(() => import('./screens/participant/WaitingForApproval'));
const ScreeningQuestions = lazy(() => import('./screens/participant/ScreeningQuestions'));

// Lazy-loaded Researcher Screens
const HomeScreenResearcher = lazy(() => import('./screens/researcher/HomeScreenResearcher'));
const NewStudyScreen = lazy(() => import('./screens/researcher/NewStudyScreen'));
const ProjectScreen = lazy(() => import('./screens/researcher/ProjectScreen'));
const PaymentScreen = lazy(() => import('./screens/researcher/PaymentScreen'));
const ReceiptScreen = lazy(() => import('./screens/researcher/ReceiptScreen'));
const SubmissionScreenResearcher = lazy(() => import('./screens/researcher/SubmissionScreenResearcher'));
const TransferMoneyScreen = lazy(() => import('./screens/researcher/TransferMoneyScreen'));
const ActiveStudiesScreen = lazy(() => import('./screens/researcher/ActiveStudiesScreen'));
const RecentStudiesScreen = lazy(() => import('./screens/researcher/RecentStudiesScreen'));

// Lazy-loaded common screen
const HomeScreen = lazy(() => import('./screens/common/HomeScreen'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#334EAC', // Your desired primary color
    },
    text: {
      primary: '#081f5c'
    },
    background: {
      default: 'white', // Default background color for the entire app
      paper: 'white', // Background color for paper components
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    },
    /*fontFamily: [
      'Karla'
    ].join(','), */
  },
});

const App = () => {
  const [reportBugModalOpen, setReportBugModalOpen] = React.useState(false);

  return (
    <>
      <CssBaseline />
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={{ body: { backgroundColor: theme.palette.background.default } }} />
        <ConfirmProvider>
          <AppAppBar />
          <Breadcrumbs /> {/* Include Breadcrumbs */}
          <ReportBugModal open={reportBugModalOpen} setOpen={setReportBugModalOpen} />
          <main>
            <ReportBugButton setOpen={setReportBugModalOpen} />
            <ToastContainer
              limit={3}
              draggable
              closeOnClick
            />

            <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                {/* Common Screens */}
                <Route path='/' component={HomeScreen} exact />
                <Route path='/login' component={LoginScreen} />
                <Route path='/register/' component={RegisterScreen} />
                <Route path='/profile' component={ProfileScreen} />
                <Route path='/messages' component={ChatScreen} />
                <Route path='/password_reset/' component={ForgotPasswordScreen} />
                <Route path='/password_reset_confirm/:uid/:token' component={ResetPasswordScreen} />
                <Route path='/activate/:uid/:token' component={ActivateAccountScreen} />
                <Route path='/terms_conditions' component={TermsAndConditions} />
                <Route path='/privacy_policy' component={PrivacyPolicy} />

                {/* Participant Screens */}
                <Route path='/participant' component={HomeScreenParticipant} exact />
                <Route path='/dashboard' component={DashboardParticipant} />
                <Route path='/participant/work/:id' component={WorkScreen} />
                <Route path='/complete/:id' component={CompleteScreen} />
                <Route path='/waiting-for-approval' component={WaitingForApproval} />
                <Route path='/screening-questions' component={ScreeningQuestions} />

                {/* Researcher Screens */}
                <Route path='/researcher' component={HomeScreenResearcher} exact />
                <Route path='/newstudy/:id?' component={NewStudyScreen} />
                <Route path='/project/:id' component={ProjectScreen} />
                <Route path='/payment' component={PaymentScreen} />
                <Route path='/receipts' component={ReceiptScreen} />
                <Route path='/submissions/:id' component={SubmissionScreenResearcher} />
                <Route path='/transfer_money' component={TransferMoneyScreen} />
                <Route path='/active-studies' component={ActiveStudiesScreen} />
                <Route path='/recent-studies' component={RecentStudiesScreen} />
              </Switch>
            </Suspense>
          </main>
          <Footer />
        </ConfirmProvider>
      </ThemeProvider >
    </>
  );
}

export default App;