import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button,
    InputAdornment,
    Grid,
    Typography,
    Checkbox,
    TextField,
    Link,
    InputLabel
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import FormContainer from '../../components/common/FormContainer';
import { register } from '../../features/user/userSlice';
import PasswordField from '../../components/common/PasswordField';
import { MuiTelInput } from 'mui-tel-input';

function RegisterForm() {
    const history = useHistory();
    const { t } = useTranslation();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    //const [idPhoto, setIdPhoto] = useState(null);
    const [idNumber, setIdNumber] = useState('');
    const [over18, setOver18] = useState(false);
    const [dob, setDob] = useState('');
    const [errors, setErrors] = useState({});

    const language = localStorage.getItem('i18nextLng');
    const url = window.location.href;
    const urlSplit = url.split('/register/');
    const userType = urlSplit[1];
    const dispatch = useDispatch();
    const { userInfo, loading } = useSelector(state => state.user);

    useEffect(() => {
        if (userInfo) {
            history.push('/');
        }
    }, [history, userInfo]);

    const validateFields = () => {
        const newErrors = {};
        if (!firstname) newErrors.firstname = t('requiredField');
        if (!lastname) newErrors.lastname = t('requiredField');
        if (!email) newErrors.email = t('requiredField');
        if (!password) newErrors.password = t('requiredField');
        if (!confirmPassword) newErrors.confirmPassword = t('requiredField');
        if (!phone) newErrors.phone = t('requiredField');
        if (userType === 'participant') {
            if (!idNumber) newErrors.idNumber = t('requiredField');
            //if (!idPhoto) newErrors.idPhoto = t('requiredField');
            if (!dob) newErrors.dob = t('requiredField');
        }
        if (!over18) newErrors.over18 = t('requiredField');
        if (password !== confirmPassword) newErrors.confirmPassword = t('passwordsDoNotMatch');
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (field, value) => {
        switch (field) {
            case 'firstname':
                setFirstname(value);
                if (value) setErrors(prevState => ({ ...prevState, firstname: '' }));
                break;
            case 'lastname':
                setLastname(value);
                if (value) setErrors(prevState => ({ ...prevState, lastname: '' }));
                break;
            case 'email':
                setEmail(value);
                if (value) setErrors(prevState => ({ ...prevState, email: '' }));
                break;
            case 'password':
                setPassword(value);
                if (value) setErrors(prevState => ({ ...prevState, password: '' }));
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                if (value && value === password) setErrors(prevState => ({ ...prevState, confirmPassword: '' }));
                break;
            case 'phone':
                setPhone(value);
                if (value) setErrors(prevState => ({ ...prevState, phone: '' }));
                break;
            case 'idNumber':
                setIdNumber(value);
                if (value) setErrors(prevState => ({ ...prevState, idNumber: '' }));
                break;
            /*case 'idPhoto':
                setIdPhoto(value);
                if (value) setErrors(prevState => ({ ...prevState, idPhoto: '' }));
                break;*/
            case 'dob':
                setDob(value);
                if (value) setErrors(prevState => ({ ...prevState, dob: '' }));
                break;
            case 'over18':
                setOver18(value);
                if (value) setErrors(prevState => ({ ...prevState, over18: '' }));
                break;
            default:
                break;
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        const isValid = validateFields();

        if (isValid) {
            const user = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                password: password,
                user_type: userType,
                language: language,
                phone_number: phone,
                dob: dob,
            };

            if (userType === 'participant') {
                //user.id_photo = idPhoto;
                user.id_number = idNumber;
            }

            dispatch(register(user)).then((result) => {
                if (result.type.endsWith('fulfilled')) {
                    history.push('/login');
                }
            });
        }
    };

    return (
        <div className='screen-notloggedin'>
            <FormContainer mdval={6}>
                <Typography variant="h3" gutterBottom>
                    {t(`registerAs${userType}`)}
                </Typography>
                {loading === 'pending' && <Loader />}
                <form onSubmit={submitHandler}>
                    <Grid container spacing={'12px'}>
                        <Grid item xs={12} sm={12}>
                            <InputLabel
                                shrink={false}
                                htmlFor="firstname"
                                sx={{ mb: '1px', mt: 0, padding: 0 }}
                            >
                                <Typography variant='body2'>{t('firstName')}</Typography>
                            </InputLabel>
                            <TextField
                                id="firstname"
                                name="firstname"
                                value={firstname}
                                onChange={(e) => handleChange('firstname', e.target.value)}
                                error={!!errors.firstname}
                                helperText={errors.firstname}
                                fullWidth
                                margin="dense"
                                sx={{ mb: 0, mt: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputLabel
                                shrink={false}
                                htmlFor="lastname"
                                sx={{ mb: '1px', mt: 0, padding: 0 }}
                            >
                                <Typography variant='body2'>{t('lastName')}</Typography>
                            </InputLabel>
                            <TextField
                                id="lastname"
                                name="lastname"
                                value={lastname}
                                onChange={(e) => handleChange('lastname', e.target.value)}
                                error={!!errors.lastname}
                                helperText={errors.lastname}
                                fullWidth
                                margin="dense"
                                sx={{ mb: 0, mt: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel
                                shrink={false}
                                htmlFor="email"
                                sx={{ mb: '1px', mt: 0, padding: 0 }}
                            >
                                <Typography variant='body2'>{t('email')}</Typography>
                            </InputLabel>
                            <TextField
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e) => handleChange('email', e.target.value)}
                                error={!!errors.email}
                                helperText={errors.email}
                                fullWidth
                                margin="dense"
                                sx={{ mb: 0, mt: 0 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>

                        {userType === 'participant' && (
                            <>
                                <Grid item xs={12}>
                                    <InputLabel
                                        shrink={false}
                                        htmlFor="idNumber"
                                        sx={{ mb: '1px', mt: 0, padding: 0 }}
                                    >
                                        <Typography variant='body2'>{t('idNumber')}</Typography>
                                    </InputLabel>
                                    <TextField
                                        id="idNumber"
                                        name="idNumber"
                                        value={idNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value) && value.length <= 11) {
                                                handleChange('idNumber', value);
                                            }
                                        }}
                                        error={!!errors.idNumber}
                                        helperText={errors.idNumber}
                                        fullWidth
                                        margin="dense"
                                        sx={{ mb: 0, mt: 0 }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <BadgeIcon />
                                                </InputAdornment>
                                            ),
                                            inputProps: {
                                                inputMode: 'numeric',
                                                pattern: '\\d{11}',
                                            },
                                        }}
                                    />
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <Tooltip title={t('whyWeNeedIDImage')} placement="right">
                                        <InputLabel
                                            shrink={false}
                                            htmlFor="idPhoto"
                                            sx={{ mb: '1px', mt: 0 }}
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography variant='body2'>{t('idImageField')}</Typography>
                                                <InfoIcon sx={{ fontSize: '1rem', ml: '4px' }} />
                                            </div>
                                        </InputLabel>
                                    </Tooltip>
                                    <MuiFileInput
                                        id="idPhoto"
                                        value={idPhoto}
                                        onChange={(file) => handleChange('idPhoto', file)}
                                        fullWidth
                                        accept='image/*'
                                        error={!!errors.idPhoto}
                                        helperText={errors.idPhoto}
                                        clearIconButtonProps={{
                                            title: t('remove'),
                                            children: <CloseIcon fontSize="small" />
                                        }}
                                        sx={{ mb: 0, mt: 0 }}
                                        inputProps={{ accept: '.png, .jpeg, .jpg' }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AddAPhotoIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>*/}
                                <Grid item xs={12}>
                                    <InputLabel
                                        shrink={false}
                                        htmlFor="dob"
                                        sx={{ mb: '1px', mt: 0 }}
                                    >
                                        <Typography variant='body2'>{t('dob')}</Typography>
                                    </InputLabel>
                                    <TextField
                                        required
                                        fullWidth
                                        type="date"
                                        name="dob"
                                        placeholder={t('dateOfBirthPlaceholder')}
                                        onChange={(e) => handleChange('dob', e.target.value)}
                                        value={dob}
                                        inputProps={{
                                            max: (() => {
                                                const today = new Date();
                                                const maxDob = new Date(today.setFullYear(today.getFullYear() - 18)).toISOString().split('T')[0];
                                                return maxDob;
                                            })()
                                        }}
                                        error={!!errors.dob}
                                        helperText={errors.dob}
                                    />
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <InputLabel
                                shrink={false}
                                htmlFor="phone"
                                sx={{ mb: '1px', mt: 0 }}
                            >
                                <Typography variant='body2'>{t('phoneNumber')}</Typography>
                            </InputLabel>
                            <MuiTelInput
                                id="phone"
                                value={phone}
                                onChange={(value) => handleChange('phone', value)}
                                placeholder={t('phoneNumber')}
                                defaultCountry="TR"
                                fullWidth
                                margin="dense"
                                InputProps={{
                                    style: errors.phone ? { borderColor: 'red' } : {}
                                }}
                                sx={{ mb: 0, mt: 0 }}
                            />
                            {errors.phone && <Typography color="error">{errors.phone}</Typography>}
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel
                                shrink={false}
                                htmlFor="password"
                                sx={{ mb: '1px', mt: 0 }}
                            >
                                <Typography variant='body2'>{t('password')}</Typography>
                            </InputLabel>
                            <PasswordField
                                setPassword={(value) => handleChange('password', value)}
                                placeholder='passwordPlaceholder'
                                error={!!errors.password}
                                helperText={errors.password}
                                fullWidth
                                margin="dense"
                                sx={{ mb: 0, mt: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel
                                shrink={false}
                                htmlFor="confirmPassword"
                                sx={{ mb: '1px', mt: 0 }}
                            >
                                <Typography variant='body2'>{t('confirmPassword')}</Typography>
                            </InputLabel>
                            <PasswordField
                                setPassword={(value) => handleChange('confirmPassword', value)}
                                label='confirmPassword'
                                error={!!errors.confirmPassword}
                                helperText={errors.confirmPassword}
                                fullWidth
                                margin="dense"
                                sx={{ mb: 0, mt: 0 }}
                            />
                        </Grid>

                        <Grid item xs={12} display="flex" alignItems="center">
                            <Checkbox
                                checked={over18}
                                onChange={(e) => handleChange('over18', e.target.checked)}
                            />
                            <Typography variant="body1">{t('over18')}</Typography>

                            {errors.over18 && <Typography color="error">{errors.over18}</Typography>}
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant='contained'
                                fullWidth
                                disabled={!over18}
                            >
                                {t('register')}
                                <ArrowForwardIcon sx={{ ml: 1 }} />
                            </Button>
                        </Grid>

                        <Grid item xs={12} textAlign="center">
                            {t('byContinuingToAgree')}&nbsp;
                            <Link underline="hover" target="_blank" component={RouterLink} to={`/terms_conditions/${userType}`}>
                                {t('termsAndConditions')}
                            </Link>&nbsp;
                            {t('and')}&nbsp;
                            <Link underline="hover" target="_blank" component={RouterLink} to={`/privacy_policy/${userType}`}>
                                {t('privacyPolicy')}
                            </Link>&nbsp;
                            {t('agree')}
                        </Grid>

                        <Grid item xs={12} textAlign="center">
                            {t('haveAnAccount')}&nbsp;
                            <Link underline="hover" component={RouterLink} to={'/login'}>
                                <b>{t('login')}</b>
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </FormContainer>
        </div>
    );
}

export default RegisterForm;