import React from 'react';
import { Grid } from '@mui/material';

function FormContainer({ children, mdval }) {
    return (

        <Grid container justifyContent="center">
            <Grid item xs={12} md={mdval}>
                {children}
            </Grid>
        </Grid>

    );
}

export default FormContainer;