import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ResearcherRating from './ResearcherRating';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="left-start" />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

export default function ResearcherRatingTooltip({ children, rating_ux, rating_fairness, rating_time, rating_compensation, n_ratings }) {
    const { t } = useTranslation();

    return (
        <HtmlTooltip
            title={
                <React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                        <Divider />
                        <Typography color="inherit"><b>{t('ratings')} ({n_ratings})</b></Typography>
                        <div>
                            <div>
                                <Typography variant="body2">{t('userExperience')}</Typography>
                                <ResearcherRating rating={rating_ux} />
                                <Divider />
                            </div>
                            <div>
                                <Typography variant="body2">{t('fairness')}</Typography>
                                <ResearcherRating rating={rating_fairness} />
                                <Divider />
                            </div>
                            <div>
                                <Typography variant="body2">{t('time')}</Typography>
                                <ResearcherRating rating={rating_time} />
                                <Divider />
                            </div>
                            <div>
                                <Typography variant="body2">{t('compensation')}</Typography>
                                <ResearcherRating rating={rating_compensation} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        >
            <Button sx={{ padding: 0, marginLeft: '6px', minWidth: 'auto', width: 'auto' }}>{children}</Button>
        </HtmlTooltip>
    );
}