import React from 'react';

export const Terms = () => {
    return (
        <div>

            <p>This is a short summary only, for ease of reading. Our <strong>Full Privacy Notice</strong> below has much more detail (and in particular contains all the information which we are legally required to provide).</p>
            <aside>
                <p>ℹ️ An overview of personal data collected…</p>
                <ul>
                    <li>…when you use MarsData: we act as a <strong>data controller</strong> in relation to the personal data we collect and hold about you so you can use MarsData. That means we determine how we use and look after it; and we have set that out in this Privacy Notice.</li>
                    <li>…when you take part in a Study: for any Study accessed through MarsData, the Researcher conducting the Study (or the third party asking us to conduct the Study on their behalf) acts as the <strong>data controller</strong> in relation to your Study responses. <strong>This means they are responsible for complying with data protection laws when it comes to that data, and their privacy notice will apply.</strong>
                        <p>We do not usually ‘see’ any Study responses ourselves, as Studies take place outside of MarsData. However, if we are conducting the Study on behalf of a Researcher or a Researcher uses a data collection tool on MarsData to run its Study, we <em>will</em> have access to Study responses, in which case we are a <strong>data processor</strong> in relation to that data.</p>
                    </li>
                </ul>
            </aside>
            <ul>
                <li>If you’re a Participant, we will hold a variety of personal data relating to you when you use MarsData, but primarily <strong>account information</strong> (like your name and contact details), your responses to screening questions (<strong>profile information</strong>), records relating to your use of MarsData (like <strong>payment information</strong>, <strong>transaction data</strong> and <strong>activity data</strong>), <strong>verification information</strong> and <strong>usage data</strong>.</li>
                <li>We use your personal data to verify your identity, provide you with the use of MarsData, determine your eligibility for participation in Studies, manage our relationship with you as a MarsData user, contact you, monitor the use of the MarsData platform and meet our legal obligations.</li>
                <li>We do not share any direct identifiers - such as your name or email address - with Researchers (unless you breach any obligations you owe to those Researchers), and instead only provide them with access to certain demographic data, performance data and responses to relevant screening questions by reference to your “<strong>MarsData ID</strong>” (a unique identifier which we assign to you when you use the platform). However, some researchers may request you to share this information - or other personal data - <em>directly</em> with them as part of a Study. The Researcher will make this clear in the Study listing so you can decide if you’re happy to participate and share this information. Again, the Researcher - as <em>data controller</em> of its own Study responses - is responsible for the collection and use of the data contained in those Study responses, and not MarsData.</li>
                <li>Studies are usually launched and completed outside of our platform by Researchers. Again, where this is the case, we will almost never ‘see’ your Study responses, which are provided directly by you to Researchers. The only time we might see any part of a Study response where the Study is completed outside of MarsData is if a limited extract is shown to us in connection with a complaint, mediation or investigation.</li>
                <li>However, we may conduct our own Studies or a Study on behalf of a third party, and we will see the responses to those. It will always be clear when a Study is conducted by MarsData, either on our own behalf or on behalf of a third party.</li>
                <li>Otherwise, we only provide your personal data to third parties for our business purposes (like the various suppliers who host data or provide software tools in relation to the operation of MarsData) or as permitted by law. We don’t share your data with third party advertisers.</li>
                <li>Some Researchers and some of our third-party suppliers may be located outside the UK and EEA. We have terms in place with them to make sure our transfer of personal data to them is lawful.</li>
                <li>We store personal data for specified periods and delete it when it is no longer required.</li>
                <li>You have legal rights in relation to your personal data which you can exercise on request.</li>
                <li>You can contact us to enquire about any of the contents of this Privacy Notice, at <a href="mailto:marsdataco@gmail.com"><strong>marsdataco@gmail.com</strong></a>.</li>
                <li>As we introduce new product features or new business processes, or as applicable law changes, we may update this Privacy Notice from time to time. We’ll notify you of any material changes (for example, through a notification or message on the MarsData platform, or by email).</li>
            </ul>

        </div>
    );
};