import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import WalletIcon from '@mui/icons-material/Wallet';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import MessageIcon from '@mui/icons-material/Message';
import { useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';


function ProjectsDrawer(props) {
    const drawerWidth = '15%';
    const { t } = useTranslation();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [showProjects, setShowProjects] = React.useState(false);
    const [showBudget, setShowBudget] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const { projectInfo } = useSelector(state => state.projects);
    const history = useHistory();

    const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); }
    const handleProjects = () => { setShowProjects(!showProjects); }
    const handleBudget = () => { setShowBudget(!showBudget); }

    const filteredProjects = projectInfo && projectInfo.filter(project =>
        project?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const drawer = (
        <List>
            <ListItem disablePadding>
                <ListItemButton onClick={handleProjects}>
                    <FolderIcon className='icon-color-generic' fontSize='medium' style={{ marginRight: '6px' }} />
                    <ListItemText className='hide-under-1000' primary={t("projectsGeneral")} primaryTypographyProps={{ variant: "body2" }} onClick={() => { history.push('/researcher') }} />
                    {showProjects ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>

            <Collapse in={showProjects} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem>
                        <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            placeholder={t("searchProject")}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            // Change placeholder to body2
                            InputProps={{
                                style: { fontSize: '0.875rem' },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                            autoComplete='off'
                        />
                    </ListItem>
                    {filteredProjects?.map((project) => (
                        <ListItem key={project.id} disablePadding>
                            <ListItemButton component={Link} to={`/project/${project.id}/`}>
                                <ListItemText primary={project.name} primaryTypographyProps={{ variant: "body2" }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Collapse>

            <ListItem disablePadding>
                <ListItemButton onClick={handleBudget}>
                    <WalletIcon fontSize='medium' className='icon-color-generic' style={{ marginRight: '6px' }} />
                    <ListItemText className='hide-under-1000' primary={t("finance")} primaryTypographyProps={{ variant: "body2" }} />
                    {showBudget ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>

            <Collapse in={showBudget} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={`/payment`}>
                            <ListItemText primary={t("addMoney")} primaryTypographyProps={{ variant: "body2" }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={`/transfer_money`}>
                            <ListItemText primary={t("transferMoney")} primaryTypographyProps={{ variant: "body2" }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to={`/receipts`}>
                            <ListItemText primary={t("receipts")} primaryTypographyProps={{ variant: "body2" }} />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Collapse>

            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/active-studies`}>

                    <PlayCircleFilledIcon fontSize='medium' className='icon-color-generic' style={{ marginRight: '6px' }} />

                    <ListItemText className='hide-under-1000' primary={t("activeStudies")} primaryTypographyProps={{ variant: "body2" }} />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/recent-studies`}>

                    <WorkHistoryIcon fontSize='medium' className='icon-color-generic' style={{ marginRight: '6px' }} />

                    <ListItemText className='hide-under-1000' primary={t("recentStudies")} primaryTypographyProps={{ variant: "body2" }} />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/messages`}>

                    <MessageIcon fontSize='medium' className='icon-color-generic' style={{ marginRight: '6px' }} />

                    <ListItemText className='hide-under-1000' primary={t("messages")} primaryTypographyProps={{ variant: "body2" }} />
                </ListItemButton>
            </ListItem>

        </List>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            {/* Full screen drawer */}
            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        top: '65px',
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: '#f5f5f5',
                        marginTop: '0px',
                        paddingTop: '0px',
                        border: 0,
                    },
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                {drawer}
            </Drawer>

            {/* Mobile drawer */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    border: 0,
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth,
                        marginTop: '0px',
                        borderTop: '1px',
                    },
                    borderTop: '1px',
                }}
            >
                {drawer}
            </Drawer>
        </>
    );
}

export default ProjectsDrawer;