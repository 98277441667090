import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const CleanAndSplitIDs = (idString) => {
    if (idString === "" || idString.length === 0) { return []; }

    var ids = idString
        .split(/[\s,\n]+/) // Split by space, comma, or newline
        .filter(id => id); // Remove any empty strings

    var uniqueIds = [...new Set(ids)];
    return uniqueIds;
}

// Check if the IDs are in the correct format, i.e., comma separated UUIDs
export const CheckIDValidity = (ids, t) => {
    // If empty string or array is empty, return false
    if (ids === "" || ids.length === 0) { return true; }
    const regex = /^([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}(,|\s|\n)*)*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})$/;
    if (!regex.test(ids)) {
        toast.warning(t('invalidParticipantIDFormat'), { position: "top-center" });
        return false;
    } else {
        return true;
    }
}

// Create a random string of length n, consisting of uppercase letters and numbers
export const RandomString = (n) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    for (var i = 0; i < n; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}