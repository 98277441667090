import React from 'react';

export const Terms = () => {
    return (
        <div>

            <p>This is a short summary only, for ease of reading, which is not legally binding. Our full version has much more detail and constitutes a binding contract: you should make sure you’ve read and understood the full version.</p>
            <h2>Overview, and how MarsData works</h2>
            <p>We’re MarsData Academic Ltd, a TR company. When you use our platform, MarsData, you have to agree our Researcher Terms.</p>
            <p>MarsData can be used by researchers like you to list studies, and those listings are visible to eligible participants who also use MarsData.</p>
            <p>When you list a study you agree to pay a reward to participants who take part in that study. When participants elect to take part in that study they agree to provide a study submission in exchange for that reward. That creates a simple contract between you and the participant (Study Terms), which you authorise us to negotiate or conclude with the participant for the purchase of the participant’s services only on your behalf as your commercial agent (and not as the agent of the participant).</p>
            <p>MarsData is used to connect researchers with participants, but the studies themselves are conducted outside MarsData. Your study listing should include a survey link to whatever platform you normally use for online studies.</p>
            <p>The costs of a study are the rewards payable to participants and our service fees. You ensure that there are sufficient funds available to cover study costs by transferring the total amount to us, or, once we have implemented the feature for funds to be held in a separate account with our payment service provider, to that individual account (Customer Funds Account). Our service fees are not refundable and are paid to us immediately on receipt of the funds by us or to the Customer Funds Account. When you approve each participant’s submission to your Study, we pay or instruct our payment service provider to pay rewards to participants on your behalf as your commercial agent when you have confirmed approval of their submissions.</p>

            <h2>Your researcher account</h2>
            <p>When you apply to register your MarsData account (Researcher Account), we may ask you to go through some onboarding processes to help identify you and your institution. When you use MarsData, you’re using it on behalf of your institution so you should make sure you have any necessary internal approvals. Your Researcher Account is a data account in MarsData and any funds showing as available in your Researcher Account are held in trust by us on your behalf (by us, or at our payment service provider in the Customer Funds Account) awaiting payment to participants. When you look at your account in MarsData we’ll show the entire amount of study costs you have transferred (including our service fees and the amount available to pay participant rewards).</p>
            <p>When you use MarsData, you have to treat participants with fairness and respect, in accordance with good research practice, applicable law and your institution’s ethics policies. Because we emphasise transparency, deception studies on MarsData are only allowed if you follow specific requirements set out in TODO: our Help Centre.</p>

            <h2>Posting and launching studies</h2>
            <p>When you list a study, you can enter pre-screening criteria so that only participants with certain demographic characteristics can participate in it. We provide guidance on what to include in your study listings, but the main requirements are:</p>
            <ul>
                <li>you have to reward participants at a reasonable rate, and in any event at no less than whatever general minimum reward rate we may set as the operator of the MarsData platform from time to time;</li>
                <li>you have to be clear about what your study is for, in line with good research practice;</li>
                <li>you have to include any necessary consent forms or privacy notices (either in your Study listing or in the body of your Study).</li>
            </ul>
            <p>Studies are listed on MarsData, but completed on whatever third-party platform you have chosen to host the study. We will not see the content of responses to your study.</p>

            <h2>Study costs</h2>
            <p>Before any study can be listed, your researcher account needs to show that you have paid in sufficient funds to us or to the Customer Funds Account to cover the study costs. You can use our calculator to work out the study costs, and top up your account by credit card or bank transfer. You can generate an invoice if you need one to meet your organisations’ internal accounting requirements (see our TODO: Help Centre article for more information).</p>
            <p>The study costs include the rewards payable to participants in the study, and our own service fees. Your researcher account will show the amount available (in our account or in future in the Customer Funds Account) to pay participant rewards, as well as sums already allocated to our service fees, and. Any amounts left over after rewards are paid to your participants will show in your researcher account as available to be allocated to your next study.</p>
            <p>The funds showing as available in your Researcher Account and allocated to participant rewards belong to you, not us, and can be refunded on your request at any time until you list your study (in which case we would pay or instruct our payment service provider to pay the relevant amount to the card or account from which you made the initial payment). However, once you list your study, you will not be able to request a refund of any remaining funds if doing so would mean there isn’t enough left to pay participants the reward promised in your study listing.</p>

            <h2>Submissions and paying rewards</h2>
            <p>You should review study submissions promptly, and either approve or reject them. If a submission is approved, or if you haven’t approved or rejected it within 21 days after the end of the study, then the participant will be automatically approved and we will pay or instruct the payment service provider to pay the reward in your study listing. All payments are made under the provisions of the Researcher Terms: you’re not allowed to pay or arrange payment to participants any other way. Payment of the study costs to us or to the Customer Funds Account satisfies your obligation to pay participants’ rewards, as you authorise us to pay or instruct the payment service provider to pay the rewards to participants on these terms.</p>
            <p>You can reject submissions which don’t meet your requirements and, if you do, the participant will not receive any reward. However, you have to be reasonable, and if we think you’re abusing the system then you agree we may suspend your account and pay or instruct the payment service provider to pay rewards to any affected participants in our capacity as your commercial agent (and merchant of record for card scheme purposes where applicable). For more information on valid and invalid reasons for rejection, you can read our TODO: Help Centre article.</p>
            <p>If you reject submissions then the spaces on the study will automatically re-open to replacement participants. You can withdraw your study whenever you want, but if you do any participants who provided a submission before the study was withdrawn are still entitled to the reward agreed under the relevant Study Terms.</p>

            <h2>Data protection</h2>
            <p>We’ll use your personal information in accordance with our <strong>Privacy Notice</strong>.</p>
            <p>We don’t provide direct identifiers of participants to you (so we’ll never provide their name, email address, or telephone number), and we require that you don’t ask for those either. Participants are only ever referred to by their MarsData ID (a string of random numbers and letters).</p>
            <p>When you conduct studies, you may receive from us some very limited information in relation to participants: basic demographic information, performance information and responses to your pre-screening questions. Even though this information may be incapable of directly identifying the participant, it’s still treated as personal data under the data protection laws applicable to us here in the UK (and under laws applicable to European participants who use MarsData).</p>
            <p>You have to comply with applicable data protection law when you receive personal data from us, and when you receive personal data directly from participants when conducting your studies. In particular, that means you have to include a privacy notice in your study listing or study content telling participants what data you might receive, what you will do with it, your legal basis for doing so, where that data goes, and how long you keep it.</p>
            <p>If you are outside the UK or EEA, and not in a territory which has been the subject of a “finding of adequacy” by the European Commission (like Canada or New Zealand), then additional data transfer terms will apply to your use of MarsData, which are set out in full in our Researcher Terms.</p>
            <p>UK and European data protection law are both developing rapidly (in particular due to Brexit) and it’s likely that we will update features of MarsData and our Terms from time to time to reflect changes in law or in our practices.</p>

            <h2>Confidentiality</h2>
            <p>We don’t expect to receive confidential information from most of our Researcher users, because Studies are generally hosted, and Participant submissions received, outside our platform. However, sometimes Researchers might find it useful to discuss non-public matters with us like their research plans and potential use cases for our platform, and likewise we might share non-public information about our plans or platform. You and we will both keep that kind of information confidential and limit its disclosure.</p>

            <h2>Liability</h2>
            <p>Because of the limited nature of our role as your commercial agent, we don’t control the content, conduct or commercial value of studies, we limit and exclude our liability to an appropriate degree.</p>
            <p>In particular, we don’t accept liability for losses which are not foreseeable, and our liability is generally capped. If our liability arises in connection with a particular study, the cap is 130% of the service fee we received for listing that study. If our liability arises for some broader reason, the cap is the total service fees we received from you in the last six months.</p>
            <p>If we are subject to a third party claim because you are in breach of our Terms, then you will be responsible for covering our costs and losses in connection with that claim.</p>

            <h2>Termination</h2>
            <p>You can stop using MarsData whenever you like, and can contact us to close your researcher account.</p>
            <p>We can suspend or terminate access to your researcher account at any time, although we would not normally expect to do this without good reason.</p>
            <p>After termination, on your request we will transfer or instruct our payment service provider to transfer to your card or account from which you made the initial payment all sums showing in your Researcher Account as available for the payment of rewards, except that:</p>
            <ul>
                <li>if you are liable to us for any other reason we may retain some of all of those funds as an offset against that liability, and</li>
                <li>if we suspect that your account has been used for the purposes of money laundering then we, or our payment services provider, may decline to remit those fund pending the conclusion of any relevant investigation.</li>
            </ul>
            <p>Because our service fees are paid when you deposit funds, you will not be entitled to a refund of the sums showing in your Researcher Account as allocated to our service fees.</p>

            <h2>General legal provisions</h2>
            <p>Your account cannot be transferred without our consent.</p>
            <p>Your agreement with us is governed by the laws of England and Wales and subject to the exclusive jurisdiction of the courts of England and Wales.</p>
            <p>If any dispute arises between you and any Participant then we may try to help resolve it but are not obliged to do so.</p>

            <h2>Our details</h2>
            <p>MarsData Academic Ltd is a company formed under the laws of England and Wales with company registration number XYZ and its registered office at XYZ. You can contact us with queries or notices at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>.</p>

            <h2>Revisions, and last updated</h2>
            <p>We may revise our Terms from time to time, and will notify users of MarsData of material changes (normally through a service message) at least thirty (30) days before these Terms are updated. Any changes will not affect studies already listed prior to those changes taking effect. By continuing to use MarsData you accept the revised Terms.</p>
            <p>This summary version was last updated on July 2024.</p>

        </div>
    );
};
