import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import PenImage from '../../static/images/workerimg.png';
import Research from '../../static/images/research.png';
import RegisterForm from './RegisterForm';
import { useHistory } from 'react-router-dom';

function RegisterScreen() {
    const history = useHistory();
    const { t } = useTranslation();
    const { userInfo } = useSelector(state => state.user);

    // Get the url of the current page and get the string after /register
    const url = window.location.href;
    const urlSplit = url.split('/register/');
    const userType = urlSplit[1];

    useEffect(() => {
        if (userInfo) { history.push('/') }
    }, [history, userInfo]);

    return (
        !userType ?
            <div className='screen-notloggedin'>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                    <Typography variant="h3" align="center">{t('whatToDo')}</Typography>

                    <Card sx={{ maxWidth: 500, boxShadow: 3 }}>
                        <CardActionArea onClick={() => history.push('/register/participant')}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={PenImage}
                                        alt="Paper"
                                        sx={{ padding: 2, objectFit: "contain" }}
                                    />
                                </Box>
                                <Box sx={{ flex: 2 }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5">
                                            {t('takeStudies')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('takeStudiesDescription')}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Box>
                        </CardActionArea>
                    </Card>

                    <Card sx={{ maxWidth: 500, boxShadow: 3 }}>
                        <CardActionArea onClick={() => history.push('/register/researcher')}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                                <Box sx={{ flex: 1 }}>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={Research}
                                        alt="Research"
                                        sx={{ padding: 2, objectFit: "contain" }}
                                    />
                                </Box>
                                <Box sx={{ flex: 2 }}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5">
                                            {t('runResearch')}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {t('runResearchDescription')}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Box>
                        </CardActionArea>
                    </Card>
                </Box>
            </div>
            :
            <RegisterForm></RegisterForm>
    );
}

export default RegisterScreen;