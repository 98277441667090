import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Button, Container, InputLabel, TextField, Box, Alert, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormContainer from '../common/FormContainer';
import { reportBug } from '../../features/user/userSlice';

const ReportBugModal = ({ open, setOpen }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.user);

    const [error, setError] = useState('');

    const handleSubmitBugReport = () => {
        if (!title.trim() || !content.trim()) {
            setError(t('titleAndContentRequired'));
            return;
        }

        dispatch(reportBug({
            title: title,
            content: content,
            access: userInfo.user.access
        })).then(() => {
            setOpen(false);
        });
    };

    const onCloseModal = () => {
        setTitle('');
        setContent('');
        setError('');
        setOpen(false);
    };

    return (
        <Modal open={open} onClose={onCloseModal} center styles={{ modal: { maxWidth: '800px', minWidth: '300px' } }}>
            <Container>
                <Typography variant="h4" gutterBottom>{t('reportToMarsdataTeam')}</Typography>
                <FormContainer>
                    {error && <Alert severity="error">{error}</Alert>}

                    <Box sx={{ marginBottom: '6px' }}>
                        <InputLabel shrink={false} sx={{ mb: '1px' }}>
                            <Typography variant='body2'>{t('title')}</Typography>
                        </InputLabel>
                        <TextField
                            required
                            fullWidth
                            type="text"
                            name="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            sx={{ marginBottom: '6px' }}
                        />
                    </Box>

                    <Box sx={{ marginBottom: '6px' }}>
                        <InputLabel shrink={false} sx={{ mb: '1px' }}>
                            <Typography variant='body2'>{t('content')}</Typography>
                        </InputLabel>
                        <TextField
                            required
                            fullWidth
                            multiline
                            minRows={4}
                            name="content"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            sx={{ marginBottom: '6px' }}
                        />
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', margin: '12px' }}>
                        <Button type="submit" variant="contained" color="error" onClick={handleSubmitBugReport}>
                            {t('submitBug')}
                        </Button>
                    </Box>
                </FormContainer>
            </Container>
        </Modal>
    );
};

export default ReportBugModal;