import React, { useState, useEffect } from 'react';
import {
    TextField,
    Box,
    Typography,
    Grid,
    Select,
    MenuItem,
    Divider,
    Link,
    Button,
    InputLabel
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/common/Loader';
import { getUserDetails, updateUserProfile, deactivateAccount } from '../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useConfirm } from "material-ui-confirm";
import ProjectsDrawer from '../../components/researcher/project/ProjectsDrawer';
import ParticipantDrawer from '../../components/participant/ParticipantDrawer';

function ProfileScreen() {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const { userInfo, loading } = useSelector(state => state.user);
    const [language, setLanguage] = useState(userInfo?.user?.language);
    const confirm = useConfirm();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!userInfo) {
            history.push('/login');
        } else {
            if (!userInfo || !userInfo.user.first_name) {
                dispatch(getUserDetails({ id: userInfo._id }));
            } else {
                setFirstName(userInfo.user.first_name);
                setLastName(userInfo.user.last_name);
            }
        }
    }, [dispatch, history, userInfo]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(updateUserProfile({
            'id': userInfo._id,
            'firstName': firstName,
            'lastName': lastName,
            'language': language
        }));
    }

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        setLanguage(selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
    };

    const handleDeactivate = () => {
        confirm({
            confirmationText: t('deactivateAccount'),
            cancellationText: t('cancel'),
            cancellationButtonProps: { variant: "contained", color: "success" },
            confirmationButtonProps: { variant: "outlined", color: "error" },
            title: t('areYouSureToDeactivate'),
            description: t('deactivateAccountDescription')
        }).then(() => {
            dispatch(deactivateAccount());
        }).catch(() => {
            return;
        });
    }

    return (
        <div className='screen'>
            {userInfo?.user?.user_type === 'researcher' ? <ProjectsDrawer /> : <ParticipantDrawer />}
            {loading === 'pending' ? <Loader /> : null}
            <Box className='mobile-width'>
                <Typography variant="h3" sx={{ mb: '24px' }}>{t('userProfile')}</Typography>

                <Grid container spacing={'6px'}>
                    <Grid item xs={12}>
                        <InputLabel
                            shrink={false}
                            htmlFor={t('yourMarsdataID')}
                            sx={{ mb: '1px', mt: 0 }}
                        >
                            <Typography variant='body2'>{t('yourMarsdataID')}</Typography>
                        </InputLabel>
                        <TextField
                            value={userInfo?.user.id}
                            disabled
                            fullWidth
                            id={t('yourMarsdataID')}
                            margin="dense"
                            sx={{ mb: '0px', mt: 0 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel
                            shrink={false}
                            htmlFor={t('firstName')}
                            sx={{ mb: '1px', mt: 0, padding: 0 }}
                        >
                            <Typography variant='body2'>{t('firstName')}</Typography>
                        </InputLabel>
                        <TextField
                            value={firstName}
                            disabled
                            fullWidth
                            id={t('firstName')}
                            margin="dense"
                            sx={{ mb: '0px', mt: 0 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <InputLabel
                            shrink={false}
                            htmlFor={t('lastName')}
                            sx={{ mb: '1px', mt: 0, padding: 0 }}
                        >
                            <Typography variant='body2'>{t('lastName')}</Typography>
                        </InputLabel>
                        <TextField
                            value={lastName}
                            disabled
                            fullWidth
                            id={t('lastName')}
                            margin="dense"
                            sx={{ mb: '0px', mt: 0 }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <form onSubmit={submitHandler}>
                            <InputLabel
                                shrink={false}
                                htmlFor={t('language')}
                                sx={{ mb: '1px', mt: 0, padding: 0 }}
                            >
                                <Typography variant='body2'>{t('language')}</Typography>
                            </InputLabel>
                            <Select
                                value={language}
                                onChange={handleLanguageChange}
                                id={t('language')}
                                displayEmpty
                                fullWidth
                                margin="dense"
                            >
                                <MenuItem value="en">English</MenuItem>
                                <MenuItem value="tr">Türkçe</MenuItem>
                            </Select>

                            <Grid container spacing={1} sx={{ mt: 1 }}>
                                <Grid item xs={8}>
                                    <Link href={'/password_reset'}>
                                        {t('changePassword')}
                                    </Link>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right', marginBottom: '18px' }}>
                                    <Button type='submit' variant='outlined'>
                                        {t('update')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>

                <Divider />

                <Button variant="outlined" color='error' style={{ marginTop: '18px', width: '100%' }} onClick={handleDeactivate}>
                    {t('deactivateAccount')}
                </Button>
            </Box>
        </div>
    );
}

export default ProfileScreen;