import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography, Box } from '@mui/material';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const Breadcrumbs = () => {
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    const { projectInfo, currentProjectId } = useSelector(state => state.projects);
    const { studyInfo, currentStudyId } = useSelector(state => state.studies);
    const { userInfo } = useSelector(state => state.user);


    // Get the label for each path segment
    const getPathLabel = (pathSegment) => {
        switch (pathSegment) {
            case 'newstudy':
                let studyName = studyInfo?.find(study => study.id === currentStudyId)?.internal_name;
                return studyName ? t('study') + ' "' + studyName + '"' : t('newstudy');
            case 'project':
                return t('project') + ' "' + projectInfo?.find(project => project.id === currentProjectId)?.name + '"';
            case 'submissions':
                let studyNameS = studyInfo?.find(study => study.id === currentStudyId)?.internal_name;
                return studyNameS ? t('study') + ' "' + studyNameS + '" ' + t('for') + ' ' + t('submissions') : t('submissions');
            default:
                return t(pathSegment);
        }
    };

    // Function to return the modified pathnames array
    const getPathnames = () => {
        if (location.pathname.startsWith("/newstudy")) {
            return ['project', 'newstudy'];
        } else if (location.pathname.startsWith("/submissions")) {
            return ['project', 'submissions'];
        }

        return pathnames.reduce((acc, segment) => {
            if (!segment.match(/[a-f0-9-]{36}/) && !segment.match('researcher')) {
                acc.push(segment);
            }
            return acc;
        }, []);
    };

    const computedPathnames = getPathnames();

    const handleClick = (event, to, isProjectLink) => {
        event.preventDefault();
        if ((isProjectLink && location.pathname.startsWith('/newstudy')) ||
            (isProjectLink && location.pathname.startsWith('/submissions'))) {
            history.goBack();
        } else {
            history.push(to);
        }
    };

    return (
        <>
            {userInfo?.user?.user_type === 'researcher' ? <Box
                className='breadcrumbs'
            >
                <MUIBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                    <Link component={RouterLink} to={"/" + userInfo?.user?.user_type} onClick={(e) => handleClick(e, "/" + userInfo?.user?.user_type, false)}>
                        {t('projectsGeneral')}
                    </Link>

                    {computedPathnames.map((value, index) => {
                        const isLast = index === computedPathnames.length - 1;
                        const to = `/${computedPathnames.slice(0, index + 1).join('/')}`;
                        const isProjectLink = value === 'project' && (location.pathname.startsWith('/newstudy') || location.pathname.startsWith('/submissions'));

                        return isLast ? (
                            <Typography key={to}>{getPathLabel(value)}</Typography>
                        ) : (
                            <Link
                                component={RouterLink}
                                to={to}
                                key={to}
                                onClick={(e) => handleClick(e, to, isProjectLink)}
                            >
                                {getPathLabel(value)}
                            </Link>
                        );
                    })}
                </MUIBreadcrumbs>
            </Box> : <></>
            }
        </>
    );
};

export default Breadcrumbs;