import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from '../../i18n';
import { baseUrl } from '../../const';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast, showCelebrationToast, getErrorMessage } from '../utils';

const initialState = {
    userInfo: null,
    loading: 'idle',
    error: null,
    success: null,
    triedEmail: null,
    researcherRating: null
}

// ******* Define async thunk actions ******* //
export const login = createAsyncThunk(
    'user/login',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json'
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/users/login/`,
                { 'username': data.email, 'email': data.email, 'password': data.password },
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const register = createAsyncThunk(
    'user/register',
    async (data, { rejectWithValue }) => {
        try {
            const formData = new FormData();

            //if (data.user_type === 'participant') {
            //    formData.append('id_photo', data.id_photo);
            //}

            // Remove whitespace from the phone number
            data.phone_number = data.phone_number.replace(/\s/g, '');

            for (const key in data) {
                if (key !== 'id_photo') {
                    formData.append(key, data[key]);
                }
            }

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }

            // Step 4: Send the FormData object with the request
            const response = await axios.post(
                `${baseUrl}/api/users/register/`,
                formData, config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getUserDetails = createAsyncThunk(
    'user/getUserDetails',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/users/${data.id}/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const updateUserProfile = createAsyncThunk(
    'user/updateUserProfile',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.put(
                `${baseUrl}/api/users/profile/update/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.put(
                `${baseUrl}/api/users/${data.id}/update/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.delete(
                `${baseUrl}/api/users/delete/${data.id}/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const refreshToken = createAsyncThunk(
    'user/refreshToken',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json'
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/users/token/refresh/`,
                { 'refresh': userInfo.user.refresh },
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const requestPasswordReset = createAsyncThunk(
    'user/requestPasswordReset',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json'
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/auth/users/reset_password/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const activateAccount = createAsyncThunk(
    'user/activateAccount',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json'
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/auth/users/activation/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const resendActivationEmail = createAsyncThunk(
    'user/resendActivationEmail',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json'
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/auth/users/resend_activation/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const resetPassword = createAsyncThunk(
    'user/resetPassword',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json'
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/auth/users/reset_password_confirm/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Report a bug
export const reportBug = createAsyncThunk(
    'user/reportBug',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/bugreports/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getUpdatedBudget = createAsyncThunk(
    'user/getUpdatedBudget',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/users/get_updated_budget/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Rate researcher
export const rateResearcher = createAsyncThunk(
    'user/rateResearcher',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/users/rate_researcher/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get researcher rating
export const getResearcherRating = createAsyncThunk(
    'user/getResearcherRating',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/users/get_researcher_rating/${data.researcher_id}/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const deactivateAccount = createAsyncThunk(
    'user/deactivateAccount',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/users/deactivate/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// ******* Define slice ******* //
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state) => {
            // Remove userInfo, loading, and error from state
            state.userInfo = null;
            state.loading = 'idle';
            state.error = null;
        },
        clearError: (state) => {
            state.error = null;
        },
        clearMessages: (state) => {
            state.success = null;
            state.error = null;
        },
        addDemographics: (state, action) => {
            state.userInfo.user.demographics = action.payload
        },
        clearPaymentPageUrl: (state) => {
            state.paymentPageUrl = null;
        },
        updateBalance: (state, action) => {
            state.userInfo.user.budget = action.payload;
        },
        setFilledDemographics: (state) => {
            state.userInfo.user.completed_demographics = true;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.userInfo = action.payload
                if (action.payload.user && action.payload.user.language) {
                    i18n.changeLanguage(action.payload.user.language);
                }
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.triedEmail = action.meta.arg.email

                // Don't show the error toast if the error is participantAccountNotVerifiedError
                if (action.payload !== 'participantAccountNotVerifiedError') {
                    showErrorToast(i18n.t(action.payload));
                }
            })
            .addCase(register.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(register.fulfilled, (state, action) => {
                state.loading = 'idle'
                showSuccessToast(i18n.t('registerSuccess'));
                //state.userInfo = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getUserDetails.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getUserDetails.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.userInfo = action.payload
            })
            .addCase(getUserDetails.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(updateUserProfile.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('profileUpdated')
                showSuccessToast(i18n.t('profileUpdated'));
                state.userInfo.user = action.payload
            })
            .addCase(updateUserProfile.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.userInfo = action.payload
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(deleteUser.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.userInfo = action.payload
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(refreshToken.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null

                // Update users refresh and access token
                // state.userInfo.user.refresh = action.payload.refresh
                state.userInfo.user.access = action.payload.access
            })
            .addCase(refreshToken.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));

                // Logout
                // TODO: Check
                state.userInfo = null;
            })
            .addCase(resetPassword.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('passwordResetSuccesful')
                showSuccessToast(i18n.t('passwordResetSuccesful'));
                state.error = null
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(requestPasswordReset.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(requestPasswordReset.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('passwordResetLinkSent')
                showSuccessToast(i18n.t('passwordResetLinkSent'));
                state.error = null
            })
            .addCase(requestPasswordReset.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(activateAccount.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(activateAccount.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('accountActivated')
                showCelebrationToast(i18n.t('accountActivated'));

                state.error = null
            })
            .addCase(activateAccount.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(resendActivationEmail.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(resendActivationEmail.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('activationEmailSent')
                showSuccessToast(i18n.t('activationEmailSent'), {
                    position: 'top-center',
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined
                });

                state.error = null
            })
            .addCase(resendActivationEmail.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getUpdatedBudget.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getUpdatedBudget.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.userInfo.user.budget = action.payload.budget
            })
            .addCase(getUpdatedBudget.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(reportBug.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(reportBug.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('bugSuccesfullyReported')
                showSuccessToast(i18n.t('bugSuccesfullyReported'));
            })
            .addCase(reportBug.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(rateResearcher.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(rateResearcher.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('ratingSubmitted')
                showSuccessToast(i18n.t('ratingSubmitted'));
            })
            .addCase(rateResearcher.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(deactivateAccount.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(deactivateAccount.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('accountDeactivated')
                showSuccessToast(i18n.t('accountDeactivated'));
                state.userInfo = null;
            })
            .addCase(deactivateAccount.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getResearcherRating.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getResearcherRating.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.researcherRating = action.payload
            })
            .addCase(getResearcherRating.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
            })
    }
})

export const { logout, clearMessages, addDemographics, setFilledDemographics } = userSlice.actions;
export default userSlice.reducer;