import React from 'react';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Define custom icons
const customIcons = {
  0: {
    icon: HelpOutlineIcon,
    label: 'No Rating',
  },
  1: {
    icon: SentimentVeryDissatisfiedIcon,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: SentimentDissatisfiedIcon,
    label: 'Dissatisfied',
  },
  3: {
    icon: SentimentSatisfiedIcon,
    label: 'Neutral',
  },
  4: {
    icon: SentimentSatisfiedAltIcon,
    label: 'Satisfied',
  },
  5: {
    icon: SentimentVerySatisfiedIcon,
    label: 'Very Satisfied',
  },
};

// Interpolation function to calculate color based on rating
const interpolateColor = (rating) => {
  if (rating === 0) {
    return 'gray'; // Default color for no rating
  }
  const r = Math.floor(255 * (1 - rating / 5));
  const g = Math.floor(255 * (rating / 5));
  const b = 0;
  return `rgb(${r},${g},${b})`;
};

// Define a method that returns an appropriate icon and color for any float value
const getCustomIcon = (value) => {
  if (value === 0) {
    return customIcons[0];
  }
  if (value <= 1.5) {
    return customIcons[1];
  }
  if (value <= 2.5) {
    return customIcons[2];
  }
  if (value <= 3.5) {
    return customIcons[3];
  }
  if (value <= 4.5) {
    return customIcons[4];
  }
  return customIcons[5];
};

function ResearcherRating({ rating }) {
  const { icon: Icon, label } = getCustomIcon(rating);
  const color = interpolateColor(rating);

  return (
    <div aria-label={label} style={{ color }}>
      <Icon />{rating.toFixed(1)}
    </div>
  );
}

export default ResearcherRating;