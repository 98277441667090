import React from 'react';

export const Terms = () => {
    return (
        <div>

            <h1>MarsData Academic Researcher Terms – Full version</h1>
            <p>Welcome to MarsData! We hope you’ll enjoy working with our platform. Before you start launching studies, please make sure you have read and understood these Terms, which describe your (and our) legal rights and obligations in relation to your use of MarsData.</p>
            <p>You might also find it helpful to read some of the posts in our <a href="https://marsdata.co/help-center-researcher">Researcher Help Centre</a>. The Help Centre offers more practical “how to” information, and discusses in more detail some of the issues mentioned in these Terms.</p>


            <section>
                <h2>Definitions and overview</h2>
                <p>To give some context for the rest of these Terms:</p>
                <ul>
                    <li><strong>We</strong> are MarsData Academic Ltd.</li>
                    <li><strong>MarsData</strong> is the platform we make available through our <a href="http://www.marsdata.co">Website</a> at www.marsdata.co for researchers to list research studies (Studies) and recruit participants for those Studies.</li>
                    <li>In these Terms, <strong>you</strong> or the <strong>Researcher</strong> means both the researcher using MarsData to launch Studies, and the institution or organisation on whose behalf that researcher is acting.</li>
                </ul>
                <p>Researchers use MarsData to launch online research studies.</p>
                <ul>
                    <li>Individual participants who use MarsData to participate in Studies are <strong>Participants</strong>, and their submissions in participating in Studies are <strong>Submissions</strong>.</li>
                    <li>The rewards offered by Researchers to Participants for their Submissions are <strong>Rewards</strong>.</li>
                </ul>
                <p>There are a few legal relationships which arise through MarsData:</p>
                <ul>
                    <li>You are entering into an agreement with us under which we agree to make MarsData available to you, and you agree to certain obligations in relation to your use of MarsData. That agreement comprises these Terms.</li>
                    <li>Each Participant who uses MarsData enters into an agreement with us as well, under which we agree to make MarsData available to them, and they agree to certain obligations in relation to their use of MarsData. That agreement comprises the <a href="https://marsdata.co/participant-terms">Participant Terms</a>.</li>
                    <li>When you post a Study, and a Participant agrees to provide Submissions to the Study in exchange for Rewards, an agreement is formed between you and that Participant, which you authorise us to conclude with the Participant on your behalf as your commercial agent. That agreement comprises the Participant Terms, and also comprises the details of the Study listing and any specific terms you require Participants to accept before taking part in that Study (all of which together are the <strong>Study Terms</strong>). We are not a party to the Study Terms.</li>
                </ul>
                <p>Because Studies are conducted outside of MarsData, we do not see or control Participant responses to Studies, or Researchers’ conduct of Studies. As a Researcher, it is your responsibility to ensure that you discharge all your legal and ethical responsibilities in conducting your Studies.</p>
            </section>

            <section>
                <h2>1. Account registration, and your agreement with us</h2>
                <p>To register to use MarsData you must enter your first name, surname, email address, and choose a password. All personal data you provide to us will be used in accordance with our <a href="https://marsdata.co/privacy-notice">Privacy Notice</a>.</p>
                <p>When you register to use MarsData as a Researcher, you are offering to enter into a binding agreement with us under these Terms. If we accept your registration, then you will be legally bound by that agreement. If for any reason we decline your registration, we will notify you and you will not be able to use MarsData.</p>
                <p>If we accept your registration, then we will open an account associated with the details you have provided to us (a <strong>Researcher Account</strong>). We may require you to respond to certain checks (for example, to ensure that you are who you say you are, and that you are indeed employed and authorised by your institution) before we allow you to use MarsData.</p>
                <p>By registering to use MarsData you confirm:</p>
                <ul>
                    <li>that you are authorised to enter into an agreement with us on these Terms, and to conduct any activities which you may conduct through your account. This means in particular that if you are an individual you are over eighteen years old and that if you are conducting research for an academic institution or other organisation, that you are employed by or a student at that institution or organisation, and are authorised to enter into these Terms on its behalf;</li>
                    <li>that you are using MarsData for professional or academic purposes and not as a consumer;</li>
                    <li>that all the information provided to us for the purpose of registering for MarsData is true, and that you will notify us promptly of any changes to that information;</li>
                    <li>that you authorise the electronic transfer of funds, including to and from our nominated payment service provider, in accordance with the Payments section of these Terms; and</li>
                    <li>that you will abide by MarsData’s stand against discrimination or abuse.</li>
                </ul>
                <p>You are responsible for all activities undertaken through your Researcher Account, and must not let anyone else use your Researcher Account. You must keep your login details secure, and notify us immediately at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a> if you believe any other person may have access to your login details or Researcher Account.</p>
                <p>Your responsibilities include:</p>
                <ul>
                    <li>not using MarsData for any form of advertising;</li>
                    <li>not using any bots or other programs to automate your use of MarsData, or automate registration for your Researcher Account or crawl or scrape data from MarsData;</li>
                    <li>not using MarsData to recruit for studies which are not listed on MarsData;</li>
                    <li>not using MarsData in any manner that would cause you or Participants to breach applicable law, or any contractual or other obligations to third parties;</li>
                    <li>using MarsData entirely honestly;</li>
                    <li>not permitting or otherwise enabling unauthorised users from accessing the Submissions;</li>
                    <li>not selling, distributing, publishing, sublicensing, loaning or copying any of the Submissions if not expressly permitted by the Terms.</li>
                </ul>
            </section>

            <section>
                <h2>Anti-discrimination and abuse</h2>
                <p>MarsData will not tolerate racism, discrimination, violence or abuse of any kind. We embrace diversity and are committed to ensuring a workplace, culture and platform that is free from discrimination or abuse in any form. If there are any instances of racist, discriminatory or abusive language used in dealing with any staff of MarsData or any of our partners, or with any Participant, then we may terminate your account immediately and without notice.</p>
            </section>

            <section>
                <h2>2. Posting and launching Studies</h2>
                <p>Our <a href="https://marsdata.co/help-center-researcher">Researcher Help Centre</a> has plenty of useful guidance on how to get started, set up advanced Studies, and manage Participants and their Submissions. You should ensure that your Study listing complies with any requirements set out in that guidance.</p>
                <p>In particular:</p>
                <ul>
                    <li>Rewards must be set at an appropriate level. We may set a minimum reward level from time to time, which we will implement directly in the Study listing features of MarsData, and which will apply to any Studies listed from then on.</li>
                    <li>The Study description must be clear, state the purposes of the Study, and any intended publications arising from the Study;</li>
                    <li>If participation in the Study requires the Participants to download or use any third-party software or services, you should make sure this is clear in the Study description.</li>
                    <li>If participation in the Study will require the Participant to provide any audio, video or images ("Media Material"), you should make this clear in the Study description.</li>
                    <li>You must make sure that any necessary consent forms, or privacy notices, are included in your Study listing or in the body of the Study itself.</li>
                    <li>You must ensure that if the Study listing includes any additional terms or conditions these are clear and prominent.</li>
                    <li>Before you can post a Study listing, you need to ensure you have sufficient funds showing in your Researcher Account as available to meet the Study Costs.</li>
                    <li>You must ensure that your Study asks Participants for their MarsData ID numbers and provides Participants with a study code by which the Study can be identified.</li>
                    <li>In listing your Study you may enter pre-screening criteria so that only Participants with certain demographic characteristics can participate in it.</li>
                </ul>
                <p>Studies are conducted off-platform. MarsData is used to list Studies, and to recruit Participants, but all Studies will be completed, and Submissions received, on whatever third-party platform you have chosen to host the Study.</p>
            </section>

            <section>
                <h2>Study Terms and our role as a limited commercial agent</h2>
                <p>When you list a Study, and a Participant agrees to participate in it, that forms a separate agreement between you and the Participant on the Study Terms, which you authorise us to negotiate or conclude with the Participant for the purchase of the Participant’s services only on your behalf as your commercial agent. We do not otherwise act as your agent, nor as the agent of any Participant.</p>
                <p>When a Participant provides a Submission to you, they do so as an independent contractor. Nothing in these Terms is intended to, or will, create any employment, partnership or joint venture relationship between any of the parties or with Participants.</p>
                <p>Our role as a commercial agent has a few important consequences:</p>
                <ul>
                    <li>We are not a party to the Study Terms and are not responsible for your actions, or for those of any Participant.</li>
                    <li>We charge you a fee for providing our services to you. We do not charge Participants any fees.</li>
                    <li>We are not responsible for the stability or quality of the third-party platform you use to host the Study.</li>
                    <li>We will not see the content of Submissions.</li>
                    <li>We are not responsible for the content of Submissions, and you will be responsible for reviewing and approving or rejecting them.</li>
                    <li>We do not provide personal identifiers to you regarding Participants.</li>
                    <li>You are responsible for the integrity and quality of your Study, and for how it is conducted.</li>
                </ul>
            </section>

            <section>
                <h2>3. Study Costs, and sums in your Researcher Account</h2>
                <p>Before any Study can be listed, your Researcher Account needs to show that you have a sufficient amount to cover the Study Costs.</p>
                <p>The <strong>Study Costs</strong> comprise:</p>
                <ul>
                    <li>our <strong>Service Fee</strong>, which is not refundable; and</li>
                    <li>the total Rewards payable to the Participants in the Study.</li>
                </ul>
                <p><strong>Making Payment</strong></p>
                <p>You can pay the amount of the Study Costs by credit card or bank transfer. When the funds are received, we debit from your Researcher Account for the amount of our Service Fees. The remaining funds will be held on trust by us on your behalf by us or to that account (the Customer Funds Account) awaiting payment to Participants as Rewards.</p>
                <p>Your Researcher Account is a data account which shows the amount of Study Costs which you have paid. It takes account of the sums paid to us for our Service Fee, any credits received from us, and the sum held by us or in the Customer Funds Account available to pay Rewards. Because our Service Fee is not refundable we recommend only topping up the correct amount of funds to pay for your planned Studies.</p>
                <p>Our nominated payment service provider(s) may receive your credit or debit card details and other personal information, subject to their respective terms and conditions. More information is available in our <a href="https://marsdata.co/privacy-notice">Privacy Notice</a>.</p>
                <p>In order to use the services of our payment processing partners, you may be required to open your own account with them and accept their standard terms. You shall comply with any terms you have agreed with them.</p>
                <p><strong>Sums held by us</strong></p>
                <p>The sums held by us for Rewards or in the Customer Funds Account are held by us “on trust”, which means that the funds are held by us or at the payment service provider in our name but you are entitled to the money and we will deal with the funds on your behalf as set out in these Terms.</p>
                <p>You can request an invoice in advance of topping up your Study Costs using the features of MarsData. Otherwise, an invoice will be automatically generated whenever you top up your Study Costs. In both cases invoices will identify our Service Fee separately from the remainder of the Study Costs.</p>
                <p>You will not be entitled to interest on any amount showing in your Researcher Account as available or the corresponding funds held by our payment service provider in the Customer Funds Account.</p>
                <p><strong>Fees and Rewards</strong></p>
                <p>When you list a Study, an amount equal to the Rewards for that active Study will be deducted from the amount showing in your Researcher Account as available to pay Rewards. If you stop an active Study before it reaches the number of maximum submissions, any unspent Rewards will show in your Researcher Account as available for other Studies. You will not be able to request a refund of any funds corresponding to sums which have been allocated to an active Study but you may otherwise request a refund of the amounts showing as available for other Studies.</p>
                <p>Because our Service Fees are paid when the amount that you pay for Study Costs is received by us or our nominated payment service provider, you will not be entitled to a refund of the sums showing in your Researcher Account as allocated to our Service Fees. You will also not be entitled to any refund of sums referable to credits provided by us for use on MarsData, or any other amounts that become due and payable to us under these Terms.</p>
                <p>Our Service Fee, and any other fees to which we may be entitled under these Terms, are stated exclusive of VAT which will be payable in addition where applicable. All other payments under these Terms are inclusive of any applicable VAT unless otherwise provided. We will give you at least thirty days’ notice of any change in our Service Fees, which will not affect Studies already listed.</p>
                <p><strong>Account closure</strong></p>
                <p>If your Researcher Account has remained inactive for a period of twelve (12) months, then we may charge a dormant account fee of £5.00 (or equivalent in other currency) per month (“Dormant Account Fee”) from that point on, which will be debited to your Researcher Account and you authorise us to pay or instruct the payment service provider to pay to us the corresponding amount of funds from the Customer Funds Account. No dormant account fee will be charged by us if there are no funds showing as available in your Researcher Account.</p>
                <p>If your Researcher Account has remained inactive (or any Study Costs in your account remain unspent) for a period of eighteen (18) months, then we may:</p>
                <ul>
                    <li>close your Researcher Account;</li>
                    <li>transfer or instruct the payment service provider to attempt to transfer from the Customer Funds Account the amount of funds corresponding to the amount showing in your Researcher Account as available for Rewards or other Studies (excepting amounts showing as allocated to our Service Fee, a fee of £10.00 (or equivalent) to cover the cost of each attempt to close your Researcher Account and instruct the return of amounts due to you (“Account Closure Fee”) referral bonuses or other amounts due to us under these Terms, which we will retain), to the payment card or other payment method that you used to transfer the Study Costs to us.</li>
                </ul>
                <p>So long as we are or our payment service provider is unable to pay the amounts due to you and we cannot close your Researcher Account we may continue to charge the Dormant Account Fee.</p>
            </section>

            <section>
                <h2>4. Submissions, and paying Rewards</h2>
                <p>You shall review each Participant’s Submission within a reasonable period (ideally two (2) working days) following its submission and either approve or reject it. Approved Submissions will automatically receive a Reward, which we will debit in your Researcher Account and transfer or instruct the payment service provider to transfer from the Customer Funds Account to the Participant’s nominated account. As an exception, if a Participant owes any sums or has incurred any liability to MarsData, we may reduce the Reward payable to that Participant by the amount of those sums or that liability, and retain the applicable sum ourselves.</p>
                <p>You may not pay any Rewards, or other consideration, to Participants other than through MarsData. If a Submission is not reviewed within twenty-one (21) days after submission, you will lose the right to reject it and the Participant will receive the Reward as if the Submission had been approved.</p>
                <p>You may reject Submissions if they do not meet the requirements of your Study (for example, if the Submission was wrong, the Study was not completed correctly or the instructions were not followed). If you reject a Submission the Participant will not receive any Reward.</p>
                <p>You must act reasonably in rejecting Submissions: we’ve provided some guidance on valid and invalid reasons for rejection <a href="https://marsdata.co/help/rejection-reasons">here</a>. In our capacity as merchant of record and your commercial agent, if we reasonably decide you have rejected more than a reasonable proportion of the Submissions made to any particular Study, or if we otherwise reasonably believe that you are improperly exercising your right to reject Submissions, you agree that we may:</p>
                <ul>
                    <li>suspend your use of MarsData pending investigation;</li>
                    <li>terminate your use of MarsData; and/or</li>
                    <li>allocate Rewards to any and all Participants in any related Study (whether or not you have already accepted or rejected their Submissions).</li>
                </ul>
                <p>You may withdraw Studies at any time. However, if you do, any Participant who has submitted a Submission will still be entitled to a Reward and you authorise us to pay or instruct the payment service provider to pay that Reward to them.</p>
                <p>If you reject any Submissions then spaces on the Study will automatically re-open to allow new Participants to replace those whose Submissions were rejected, unless the Study has been withdrawn.</p>
            </section>

            <section>
                <h2>Data Protection</h2>
                <p>When Participants engage with your Study, you will be provided with certain information about them, mainly demographic and performance data which is pseudonymized. This information should be handled according to data protection laws and regulations.</p>
                <p>If your Study involves the collection of Media Material, there's an increased chance of identifying participants from that data, making it important to clearly state this in the Study Listing and reflect it in your privacy notice.</p>
            </section>

            <section>
                <h2>Complaints and Dispute Resolution</h2>
                <p>If you have complaints concerning MarsData, please contact us so we can address the issue. We aim to resolve complaints amicably but should any dispute arise, it may be settled in accordance with dispute resolution procedures in your local jurisdiction.</p>
            </section>

            <section>
                <h2>Changes to these Terms</h2>
                <p>We may update these Terms from time to time to accommodate legal, technical, or business developments. We will post the new Terms on our Website and notify you of the changes.</p>
            </section>

            <section>
                <h2>General</h2>
                <p>These Terms constitute the entire agreement between you and us relating to your use of MarsData. If any provision is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>
                <p>These Terms are governed by English law, and any disputes will be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
            </section>

            <h1>5. Intellectual Property</h1>
            <h2>Rights in our content</h2>
            <p>The intellectual property rights in all of the content on and in our Website belong either to us or to whomever licensed that content to us. You may not copy or make any use of that content without having our permission or permission from the relevant rightsholder. Uses which are expressly permitted by law are however allowed (for instance, if you print off a copy of some of our Help Centre guidance for your personal reference). If you would like to make any other use of any of our content then please contact us at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a> and we can discuss your requirements.</p>
            <h2>Rights in Studies</h2>
            <p>We do not claim any rights in the content of Studies. You confirm that you own all rights in, or are licensed to use, all Study materials and have secured all necessary permissions to conduct your Studies.</p>
            <h2>Rights in Submissions</h2>
            <p>We do not claim any rights in Submissions. You should ensure that you have obtained any necessary permissions for your intended use of those Submissions including any Media Material which they may contain.</p>
            <h2>Licence</h2>
            <p>The Researcher hereby grants MarsData Academic Ltd a fully paid-up, non-exclusive, royalty-free, non-transferable licence to use the Researcher’s name, logos and/or trademarks for the term of this Agreement for the purposes of:</p>
            <ul>
                <li>providing MarsData to both Researchers and Participants;</li>
                <li>reporting to authorised agents of MarsData, advisers and investors; and</li>
                <li>promotional marketing, which may include, but is not limited to use in or on brochures, websites, press releases, advertising in periodicals, pitches, investor decks, billboards, social media platforms, radio and television.</li>
            </ul>
            <p>The Researcher may revoke this licence at any time by providing written notice to MarsData.</p>

            <h1>6. Data Protection</h1>
            <p>Your use of MarsData will involve a few different “flows” of personal data (as defined in the General Data Protection Regulation (GDPR). The GDPR has been transposed into UK law under UK’s Data Protection Act 2018 and European Union (Withdrawal) Act 2018: we’ll refer to “GDPR” as shorthand for both EU and UK law.</p>
            <p>Personal data related to you, like your account registration information, contact details and any correspondence with us, will be held by us and used in accordance with our Privacy Notice. We are a data controller in relation to that personal data.</p>
            <p>In conducting Studies, you may receive personal data relating to Participants in several ways:</p>
            <ul>
                <li>When a Participant enrols for a Study, you will be able to access via MarsData certain basic demographic information relating to the Participant, together with performance data relating to the Participant and responses to screening questions. We do not guarantee that this basic demographic information and performance information will be continually or indefinitely available, as its availability may depend on Participant account and consent settings.</li>
                <li>When you conduct Studies off-platform, you will receive Study responses from the Participant.</li>
                <li>If you correspond directly with any Participant for any reason (for example, in connection with a safeguarding or other ethics issue, using MarsData’s messaging system or in connection with a dispute with that Participant). If you use MarsData’s messaging system then you may only contact Participants regarding matters directly related to a Study (and never for marketing purposes).</li>
            </ul>
            <p>In each case:</p>
            <ul>
                <li>This personal data will be pseudonymised (i.e. associated only with a MarsData ID rather than with a direct identifier such as a name or address). Here’s more information about the difference between anonymised and pseudonymised data. You shall not attempt to re-identify any Participant through the pseudonymised data provided by MarsData or any other data available to you (and note this may be a criminal offence in certain jurisdictions).</li>
                <li>You will be a data controller in your own right in relation to that personal data, and it is your responsibility to make sure that your collection and use of that personal data complies with applicable law.</li>
                <li>Whilst we would encourage you not to do so, where it is strictly necessary for you to collect any Media Materials such as audio, video or images from Participants as an essential part of the Study, you should not use those Media Materials to seek to re-identify any Participant and should not combine that information with any other information which you hold in such as manner as to facilitate the re-identification of any Participant.</li>
                <li>You must ensure that in any Study listing your identity is clear and the Participant is provided with or directed to a privacy notice meeting the requirements of any laws applicable to you, explaining to them the personal data you will receive and the uses to which you will put it. In order to assist you with this, the Study listing tool on MarsData will allow you to upload a privacy notice. While it is your responsibility to ensure your privacy notice is complete and accurate, please consider in particular making sure Participants are aware if you intend to use their Participant ID in any “save” or “allow” list for future Studies.</li>
                <li>You shall not in the context of any Study or otherwise request any direct identifiers from Participants. Whilst it may be possible to identify a Participant from Media Materials collected (if any) you should ensure that any such Media Materials do not include direct identifiers and that you take all reasonable steps to maintain the anonymity of the Participant as far as possible. For example, if you are collecting audio or video samples as part of your Study you should ensure those audio and video samples do not include the individuals name, address or other direct identifiers.</li>
                <li>If you wish to list an exceptional Study which absolutely requires direct identifiers to be requested or collected, you must discuss it with us and ensure you comply with any guidelines we provide. You can read more about this here.</li>
                <li>If you are listing a study which will involve the collection of any Media Materials you must ensure this is clear from your Study Listing and that Participants are made aware this may impact upon their anonymity, so that Participants can make an informed decision as to whether they want to take part in your study.</li>
                <li>Because your access through MarsData to demographic or performance data may constitute a controller-to-controller transfer of personal data, we are required to ensure that if you are located outside the UK and European Economic Area (EEA), and not in a territory in relation to which the UK and European Commission have made respective findings of adequacy, the transfer is subject to Module 1 of the Standard Contractual Clauses approved by the European Commission in their decision of 4 June 2021 (the EU SCCs). The optional “Docking Clause” 7 of the EU SCCs will not apply. Because we are a UK entity, the EU SCCs are supplemented by the Addendum to the EU SCCs approved by the UK’s data protection regulator, the ICO, which are in force from 21 March 2022 (the Addendum). The EU SCCs and Addendum are incorporated in these Terms in full, and you agree to comply with them.</li>
                <li>For the purposes of Annex I of the EU SCCs, and Part 1 of the Addendum:
                    <ul>
                        <li>we are the data exporter and you are the data importer and each of us is a controller. Our contact details are as set out in the “Contact Us” section below. Your contact details are as set out in your MarsData account information;</li>
                        <li>the data subjects are the Participants;</li>
                        <li>the purpose of transfer is the conduct of Studies in accordance with these Terms;</li>
                        <li>the categories of data are Participant demographic and performance data (including any sensitive data comprised in the Participant demographic data); and</li>
                        <li>the permitted recipients are: i) your institution or organisation; and ii) any other recipients disclosed in the Study Terms.</li>
                        <li>the frequency of transfer is based on how often you list Studies;</li>
                        <li>the nature of processing is receiving, storing and transmitting personal data;</li>
                        <li>the purpose of transfer is the conduct of Studies in accordance with these Terms, and analysis of the results of those Studies;</li>
                        <li>the duration of processing will be determined by reference to your organisation or institution’s retention policy, and in any event must be no longer than is necessary for the purposes of that processing; and</li>
                        <li>the competent supervisory authority is the UK’s Information Commissioner’s Office (ICO).</li>
                    </ul>
                </li>
                <li>For the purposes of Annex II of the SCCs or EU SCCs the technical and organisational measures adopted by you to ensure the security of personal data transferred to you are those disclosed by you to us when opening your account or subsequently (and until specific measures have been disclosed, the measures required under Article 32 GDPR).</li>
                <li>If you are subject to the EU SCCs, then you agree to these supplementary obligations in relation to personal data received by you from us:
                    <ul>
                        <li>If you receive any legally binding request for disclosure of the personal data by a law enforcement authority or state security body, you will notify us promptly, providing as much information as possible, unless prohibited by law from doing so (e.g. by a prohibition under criminal law to preserve the confidentiality of a law enforcement investigation). If you are prohibited, you must seek a waiver of that prohibition to communicate whatever information you can to us.</li>
                        <li>If despite your efforts you are not permitted to notify us of any specific disclosure requests, you must in any event provide us with general information on the types of requests you have received relating to the personal data (e.g. number of applications for disclosure, type of data requested, requester if possible, etc.).</li>
                        <li>In any case, transfers of relevant personal data to any public authority cannot be disproportionate or indiscriminate, and transfers may be made only if compelled and not voluntarily.</li>
                    </ul>
                </li>
            </ul>
            <h1>7. Confidentiality</h1>
            <p>We don’t expect to receive confidential information from most of our Researcher users. Study listings are by their nature published on our platform. Studies are generally hosted, and Participant submissions received, outside our platform, and we don’t generally need to receive any confidential information from you in order for you to use MarsData.</p>
            <p>However, we appreciate that in consulting with our support team you might find it useful to discuss non-public matters like your research plans and potential use cases for MarsData, and you may share information with us about your organisation’s plans, research or business.</p>
            <p>Therefore, each party undertakes that it shall not at any time disclose to any person any confidential information concerning the business, operations, intentions, affairs, market opportunities, customers, clients or suppliers of the other party or of any member of the group of companies to which the other party belongs, except as permitted below.</p>
            <ul>
                <li>Each party may disclose the other party's confidential information:
                    <ul>
                        <li>to its employees, officers, representatives, contractors, subcontractors, advisers, auditors, or insurers who need to know such information for the purposes of exercising the party's rights or carrying out its obligations under or in connection with this agreement. Each party shall ensure that its employees, officers, representatives, contractors, subcontractors or advisers to whom it discloses the other party's confidential information comply with this clause; and</li>
                        <li>as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.</li>
                    </ul>
                </li>
                <li>The provisions of this clause shall not apply to any confidential information that is or becomes generally available to the public (other than as a result of its disclosure by the receiving party in breach of this clause.</li>
            </ul>
            <p>This clause shall continue in force until the second anniversary of termination or expiry of our agreement with you.</p>

            <h1>8. Limitation of Liability</h1>
            <p>We provide a platform for research studies, connecting Researchers with Participants. Because our role is limited, and we don’t control the content, conduct or commercial value of Studies, we limit and exclude our liability to an appropriate degree. This section explains the ways in which our liability to you is limited and excluded.</p>
            <h2>Where we don’t limit or exclude our liability</h2>
            <p>We do not limit or exclude our liability where not permitted by applicable law. For example, we do not limit our liability for death or personal injury caused by our negligence, or for fraud. This statement takes priority over the rest of the Terms.</p>
            <h2>No implied terms</h2>
            <p>We provide access to the Website and MarsData on these Terms, and the documents referred to in them, alone. Any terms which might otherwise be implied by statute, under common law, or otherwise, are excluded.</p>
            <h2>We don’t control the Internet</h2>
            <p>Communications networks are not always stable, and we may need to take down or maintain MarsData from time to time. Accordingly, we will not be liable if it is unavailable for any reason. MarsData is provided “as is” and without any warranty. For example, while obviously we do our best to correct defects, we do not warrant that MarsData will be uninterrupted, free from errors, or free from viruses or malicious code.</p>
            <h2>No liability for third parties</h2>
            <p>We are not responsible or liable for the actions of third parties. Third parties may include, for example, other users of MarsData, third party service providers who are linked from our Website, social media service providers or the providers of any device or software which you use to access MarsData.</p>
            <h2>No indirect or consequential loss</h2>
            <p>We will not be liable for any indirect or consequential loss, damage, costs or expenses incurred or suffered by you in connection with your use of MarsData. We are not liable for losses, damages, costs and expenses that are not foreseeable to both you and us at the time you open your account with us, or which are not caused by our breach of these Terms.</p>
            <h2>No liability for certain kinds of loss</h2>
            <p>We will not be liable for any loss, damage, costs or expenses incurred or suffered by you (in each case whether direct or indirect) if that liability comes within one of the following categories: loss of sales or business, loss of revenue, loss of agreements or contract, loss of anticipated savings, loss of use of corruption of software, data or information, loss of reputation or damage to goodwill, loss of profit, or indirect or consequential loss.</p>
            <h2>No liability for Study data loss</h2>
            <p>Since Studies are launched outside MarsData, we will not handle very much data on your behalf. We are not providing a backup service, and you should ensure that you have your own backups for whatever data of yours is stored in, or generated through, MarsData.</p>
            <h2>Cap on liability</h2>
            <p>Our total aggregate liability to you in connection with your use of MarsData, however arising, will not exceed: i) a sum equal to 130% of the Service Fee retained by us in relation to the specific Study in relation to which our liability arises; or ii) if our liability does not arise in relation to a specific Study, a sum equal to the Service Fee retained by us in relation to Studies you have posted during the period of six (6) months immediately preceding the date on which the first event or occurrence giving rise to our liability took place.</p>
            <h2>Survival</h2>
            <p>This clause shall continue in force on termination or expiry of our agreement with you.</p>

            <h1>9. Indemnity</h1>
            <p>If we are subject to any third-party claim (including a claim by any Participant) as a result of your breach of these Terms, or as a result of your negligence, misrepresentation, failure to comply with applicable law, or other wrongful act, then you will indemnify us against that claim. Indemnifying us means you will indemnify us and hold us harmless against all costs, expenses, losses, damages or liabilities suffered or incurred by us as a result of or in connection with that third-party claim, including any legal and professional fees. You also agree to provide us with all reasonable assistance and information we may request from you in order to enable us to defend and settle the claim and mitigate our (and your) liability. We will not settle any claim against which you are required to indemnify us without your prior written consent, provided that your consent is not unreasonably withheld, conditioned or delayed.</p>
            <p>This clause shall continue in force on termination or expiry of our agreement with you.</p>

            <h1>10. Termination</h1>
            <h2>Termination by you</h2>
            <p>You may stop using MarsData whenever you like, and can contact us at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a> to close your Researcher Account.</p>
            <h2>Termination by us</h2>
            <p>We may limit, disable and/or suspend access to your Researcher Account, and/or terminate our agreement with you and close your Researcher Account, with immediate effect without notice to you. Whilst we would not normally expect to do this without good reason (e.g. on instruction from our payment service provider), we are not required to provide any reason for suspension or termination and any suspension or termination is entirely at our discretion.</p>
            <ul>
                <li>Without affecting any other right or remedy available to it, either party may terminate this agreement with immediate effect by giving written notice to the other party if:
                    <ul>
                        <li>the other party fails to pay an amount due under this agreement on the due date for payment and remains in default not less than 30 days after being notified in writing to make such payment;</li>
                        <li>the other party commits a material breach of any term of this agreement and (if such breach is remediable) fails to remedy that breach within a period of 30 days after being notified in writing to do so;</li>
                        <li>the other party takes or has taken against it (other than in relation to a solvent restructuring) any step or action towards its entering bankruptcy, administration, provisional liquidation or any composition or arrangement with its creditors, applying to court for or obtaining a moratorium under Part A1 of the Insolvency Act 1986, being wound up (whether voluntarily or by order of the court), being struck off the register of companies, having a receiver appointed to any of its assets, or its entering a procedure in any jurisdiction with a similar effect to a procedure listed in this Clause 2.1(c); or</li>
                        <li>the other party suspends or ceases, or threatens to suspend or cease, carrying on business.</li>
                    </ul>
                </li>
                <li>If you are in breach of these Terms, we may also at our option take any of the following actions:
                    <ul>
                        <li>issue you with a written warning;</li>
                        <li>take legal action against you; or</li>
                        <li>if appropriate, disclose your details to law enforcement and/or regulatory authorities.</li>
                    </ul>
                </li>
            </ul>
            <h2>Effects of termination</h2>
            <ul>
                <li>If we terminate when we have no reason to believe you are in breach of these Terms, then we will transfer or instruct our payment service provider to transfer to you funds in which you are in credit in your Researcher Account as explained in Section 3.</li>
                <li>If we terminate when you are, or we suspect you are, in breach of these Terms, then we may retain any funds in which you are in credit in your Researcher Account to set them off against any liability you may have to us.</li>
                <li>In the event of any termination, regardless of circumstances, any Submission that has been completed by a Participant but not yet accepted by you will be deemed accepted and the applicable Reward will be remitted to the Participant and deducted from the relevant credit displayed in your Researcher Account.</li>
                <li>Termination will not affect any obligations, liabilities rights or remedies you or we may have under these Terms prior to termination.</li>
            </ul>

            <h1>11. General</h1>
            <h2>Transfers</h2>
            <p>You may not transfer your rights under our agreement with you without our prior written consent.</p>
            <h2>Publicity</h2>
            <p>We will not use your name, logos or other trade marks in any public context (like on our website) without your permission. However, we may identify you as a user of MarsData in non-public contexts (such as when reporting to our investors, or when pitching privately to other potential users).</p>
            <h2>Matters outside our control</h2>
            <p>We will not be liable for any delay or failure to perform our obligations under these Terms due to matters outside our reasonable control (including but not limited to power failures, epidemic, network or communications failures).</p>
            <h2>Entire agreement</h2>
            <p>These Terms, together with the other documents referred to in them, and any specific amendment we may agree with you in writing, are the entire agreement between you and us relating to your use of MarsData. You acknowledge that you have not entered into that agreement based on any other representation or warranty.</p>
            <h2>No waiver</h2>
            <p>If you are in breach of these Terms, we can only waive that breach by written notice. If we do not immediately take action in relation to your breach we may still do so later.</p>
            <h2>Severance</h2>
            <p>If any court or competent authority determines that any provision or part-provision of the Terms is unenforceable for any reason then that provision or part-provision will no longer apply and the rest of the Terms will continue in force.</p>
            <h2>Third Party Rights</h2>
            <p>Nothing in these Terms is intended to confer a benefit on any person who is not a party to your agreement with us under these Terms or your Study Terms, and no such person will have any right under them.</p>
            <h2>Governing law</h2>
            <p>Your agreement with us under these Terms is governed by the laws of England and Wales.</p>
            <h2>Disputes with us</h2>
            <p>If any dispute arises between you and us in connection with your use of MarsData, then we’ll attempt to resolve that dispute through discussion. If we are unable to resolve the dispute by discussion, then the courts of England and Wales will have exclusive jurisdiction over any claim, except that, if you are not resident in England or Wales then we may bring proceedings against you in any court in your country of residence.</p>
            <h2>Disputes with Participants</h2>
            <p>If any dispute arises between you and any Participant then we may try to help resolve it but are not obliged to do so.</p>

            <h2>Contact Us</h2>
            <p>MarsData Academic Ltd is a company formed under the laws of England and Wales with company registration number XYZ and its registered office at XYZ.</p>
            <h2>Notices</h2>
            <p>All notices relating to these Terms will be sent by e-mail or post. We will send notices to you at the e-mail or postal address associated with your account. You will send notices to us at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a> or by recorded delivery to our postal address of XYZ. E-mail notices will be deemed received when sent, provided no error or server message indicating non-delivery has been received by the sender. As an exception, if either you or we need to serve notice of legal proceedings then notice should be served in accordance with applicable civil procedure rules.</p>
            <h2>Revisions, and last updated</h2>
            <p>We may revise these Terms from time to time, and will notify users of MarsData of material changes (normally through a service message) at least thirty (30) days before these Terms are updated. Any changes will not affect Studies already listed prior to those changes taking effect. By continuing to use MarsData you accept the revised Terms.</p>
            <p>Last updated July 2024</p>

        </div>
    );
};