import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function Loader({ inline = false }) {
    const loaderStyle = inline
        ? {
            display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', left: '50%', marginTop: '10%'
         }
        : {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
          };

    return (
        <div style={loaderStyle}>
            <CircularProgress style={{ color: 'black' }} variant="indeterminate" />
        </div>
    );
}

export default Loader;
