import { toast } from 'react-toastify';
import i18n from '../i18n';
import CelebrationIcon from '@mui/icons-material/Celebration';

export const showErrorToast = (errorMessage, options = { position: "top-center" }) => {
    if (errorMessage !== 'Token is invalid or expired') {
        toast.error(errorMessage, options);
    }
};

export const showSuccessToast = (message, options = { position: "top-right" }) => {
    toast.success(message, options);
}

// Show celebration toast
export const showCelebrationToast = (message) => {
    toast.success(message, {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        icon: <CelebrationIcon />,
        color: 'green',
        theme: 'colored'
    });
}

export const getErrorMessage = (error) => {
    if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (typeof errorData === 'object') {
            const firstKey = Object.keys(errorData)[0];
            const firstValue = errorData[firstKey];

            // If the first value is an object, it means it's a nested error message
            if (typeof firstValue === 'object' && !Array.isArray(firstValue)) {
                const nestedKey = Object.keys(firstValue)[0];
                const nestedValue = firstValue[nestedKey];
                if (Array.isArray(nestedValue)) {
                    return `${i18n.t(firstKey)} ${i18n.t(nestedKey)}: ${i18n.t(nestedValue)}`.replace('detail', '');
                }
                return `${i18n.t(firstKey)} ${i18n.t(nestedKey)}: ${i18n.t(nestedValue)}`;
            }

            // If the first value is an array, it means it's a list of errors
            if (Array.isArray(firstValue)) {
                if (firstKey === 'detail' || firstKey === 'non_field_errors') {
                    return `${i18n.t(firstValue.join(' '))}`;
                } else {
                    return `${i18n.t(firstKey)}: ${i18n.t(firstValue.join(' '))}`;
                }
            } else {
                return `${i18n.t(firstValue)}`;
            }
        }
        return '404 Error';
    }
    return '404 Error';
}