import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from '../../i18n';
import { baseUrl } from '../../const';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast, getErrorMessage } from '../utils';

const initialState = {
    studyInfo: null,
    loading: 'idle',
    error: null,
    success: null,
    previewStudyInfo: null, // Study that is being previewed
    timeUp: false,
    completedSubmissions: null,
    screeningQuestions: null
}

export const getActiveStudies = createAsyncThunk(
    'study/getActiveStudies',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            // Get studies that are not archived and not cancelled
            const response = await axios.get(
                `${baseUrl}/api/participants/get_active_studies/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

export const reserveStudy = createAsyncThunk(
    'study/reserveStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/participants/${data.id}/reserve_study/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

// Called when a participant accepts a study
export const startStudy = createAsyncThunk(
    'study/startStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/participants/${data.id}/start_study/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

export const cancelReservation = createAsyncThunk(
    'study/cancelReservation',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/participants/${data.id}/cancel_reservation/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

// Send the random id to the backend to finish the study
export const finishStudy = createAsyncThunk(
    'study/finishStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/participants/${data.id}/finish_study/`,
                { 'random_id': data.randomId, 'redirect': data.redirect },
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

// Get all completed submissions of a participant within 30 days
export const getCompletedSubmissions = createAsyncThunk(
    'study/getCompletedSubmissions',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/participants/get_completed_submissions/?n_days=${data.nDays}`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

// Return a subnmission
export const returnSubmission = createAsyncThunk(
    'study/returnSubmission',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/participants/${data.id}/return_submission/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }

    }
)

// Report a study by creating a studyreport object
export const reportStudy = createAsyncThunk(
    'study/reportStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/studyreports/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const participantSlice = createSlice({
    name: 'participant',
    initialState,
    reducers: {
        // ******* Define synchronous actions ******* //
        clearMessages: (state) => {
            state.error = null;
            state.success = null;
            state.loading = 'idle';
        },
        setPreviewStudyInfo: (state, action) => {
            state.previewStudyInfo = action.payload
        },
        setTimeUp: (state, action) => {
            state.timeUp = action.payload
        }
    },
    // ******* Define asynchronous actions ******* //
    extraReducers: builder => {
        builder
            .addCase(getActiveStudies.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getActiveStudies.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.studyInfo = action.payload

                // Sort studies by the date they were created (create_date) is the following format: 2023-10-10T08:40:44.159722Z. Newest first.
                state.studyInfo.sort((a, b) => new Date(b.create_date) - new Date(a.create_date))
            })
            .addCase(getActiveStudies.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(reserveStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(reserveStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('participantStudyReserved')
                showSuccessToast(i18n.t('participantStudyReserved'));

                // Find the reserved study and add replace it with the payload
                const reservedStudyIndex = state.studyInfo.findIndex(study => study.id === action.payload.id)
                state.studyInfo[reservedStudyIndex] = action.payload
            })
            .addCase(reserveStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(startStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(startStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('participantStudyStarted')
                showSuccessToast(i18n.t('participantStudyStarted'));

                // Get study id from the data that was inputted to the action
                const studyId = action.meta.arg.id

                // Find the reserved study and add replace it with the payload
                const reservedStudyIndex = state.studyInfo.findIndex(study => study.id === studyId)
                state.studyInfo[reservedStudyIndex].user_submission.status = 'started'
                state.studyInfo[reservedStudyIndex].user_submission.start_date = action.payload.start_date
            })
            .addCase(startStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(cancelReservation.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(cancelReservation.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('participantStudyCancelled')
                toast.info(i18n.t('participantStudyCancelled'), { position: "top-center" });

                // Get study id from the data that was inputted to the action
                const studyId = action.meta.arg.id

                // Find the reserved study and add replace it with the payload
                const reservedStudyIndex = state.studyInfo.findIndex(study => study.id === studyId)
                state.studyInfo[reservedStudyIndex].user_submission.status = 'cancelled'
            })
            .addCase(cancelReservation.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(finishStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(finishStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('participantStudyFinished')
                showSuccessToast(i18n.t('participantStudyFinished'));

                // Get study id from the data that was inputted to the action
                const studyId = action.meta.arg.id

                // Find the reserved study and add replace it with the payload
                const reservedStudyIndex = state.studyInfo.findIndex(study => study.id === studyId)
                state.studyInfo[reservedStudyIndex].user_submission.status = 'finished'
            })
            .addCase(finishStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getCompletedSubmissions.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getCompletedSubmissions.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = null
                state.completedSubmissions = action.payload
            })
            .addCase(getCompletedSubmissions.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(returnSubmission.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(returnSubmission.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('participantSubmissionReturned')
                showSuccessToast(i18n.t('participantSubmissionReturned'));
            })
            .addCase(returnSubmission.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(reportStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(reportStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('participantStudyReported')
                showSuccessToast(i18n.t('participantStudyReported'));
            })
            .addCase(reportStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
    }
})

export const { clearMessages, setPreviewStudyInfo, setTimeUp } = participantSlice.actions
export default participantSlice.reducer;