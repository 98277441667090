import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../../const';
import i18n from '../../i18n';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, getErrorMessage } from '../utils';

const initialState = {
    messages: [],
    bulkMessages: [],
    users: null,
    loading: 'idle',
    error: null,
    success: null,
    currentReceiver: null
}

export const getUsers = createAsyncThunk(
    'message/getUsers',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            // Get studies that are not archived and not cancelled
            const response = await axios.get(
                `${baseUrl}/api/message/get_users/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getUsersSent = createAsyncThunk(
    'message/getUsersSent',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            // Get studies that are not archived and not cancelled
            const response = await axios.get(
                `${baseUrl}/api/message/get_users_sent/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const sendMessage = createAsyncThunk(
    'message/sendMessage',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/message/send_message/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get messages
export const getMessages = createAsyncThunk(
    'message/getMessages',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/message/get_messages/${data.userId}/`,
                config
            )

            return { data: response.data, userId: data.userId };
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getBulkMessages = createAsyncThunk(
    'message/getBulkMessages',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/message/get_bulk_messages/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)


export const messageSlice = createSlice({
    name: 'message',
    initialState,
    reducers: {
        // ******* Define synchronous actions ******* //
        clearMessages: (state) => {
            state.error = null;
            state.success = null;
            state.loading = 'idle';
        },
        addUser: (state, action) => {
            // Push if the current user is not in the list
            if (state.users === null) {
                state.users = [action.payload]
            } else if (!state.users.find(user => user.id === action.payload.id)) {
                state.users.push(action.payload)
            }

            state.currentReceiver = action.payload.id
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        }
    },
    // ******* Define asynchronous actions ******* //
    extraReducers: builder => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.users = action.payload
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = i18n.t(action.payload);
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(sendMessage.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.success = i18n.t(action.payload.detail);
                
                // Get original data parameter
                const data = action.meta.arg;

                // Add the new message to the messages array
                state.messages.push({
                    content: data.content,
                    receiver: data.receiver[0],
                    sender: data.user_id,
                    date: new Date().toISOString()
                })
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getMessages.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getMessages.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.currentReceiver = action.payload.userId

                // If the messages are the same as the current messages, 
                // don't update to prevent page re-rendering each time
                if (action.payload.data !== state.messages) {
                    state.messages = action.payload.data
                }

                // Set user.last_message_read to true
                state.users = state.users.map(user => {
                    if (user.id === action.payload.userId) {
                        user.last_message_read = true
                    }
                    return user
                })
            })
            .addCase(getMessages.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getBulkMessages.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getBulkMessages.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.bulkMessages = action.payload
            })
            .addCase(getBulkMessages.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getUsersSent.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getUsersSent.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.users = action.payload
            })
            .addCase(getUsersSent.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = i18n.t(action.payload);
                showErrorToast(i18n.t(action.payload));
            })
    }
})

export const { clearMessages, addMessage, addUser, setMessages } = messageSlice.actions
export default messageSlice.reducer;