import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from '../../i18n';
import { baseUrl } from '../../const';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast, getErrorMessage } from '../utils';

const initialState = {
    projectInfo: [],
    loading: 'idle',
    error: null,
    success: null,
    message: null,
    currentProjectId: null
}

// ******* Define async thunk actions ******* //
export const createProject = createAsyncThunk(
    'project/createProject',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/projects/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const deleteProject = createAsyncThunk(
    'project/deleteProject',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.delete(
                `${baseUrl}/api/projects/${data.id}/`,
                config
            )

            return { 'id': data.id, 'response': response.data };
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const updateProjectName = createAsyncThunk(
    'project/updateProjectName',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.put(
                `${baseUrl}/api/projects/${data.id}/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Getting projects of the user
export const getProjects = createAsyncThunk(
    'project/getProjects',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/projects/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const addCollaborator = createAsyncThunk(
    'project/addCollaborator',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/projects/${data.projectId}/add_collaborator/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const removeCollaborator = createAsyncThunk(
    'project/removeCollaborator',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/projects/${data.projectId}/remove_collaborator/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        // ******* Define synchronous actions ******* //
        clearMessages: (state) => {
            state.error = null;
            state.success = null;
            state.loading = 'idle';
            state.message = null;
        },
        incorrectProjectName: (state) => {
            state.error = true;
            state.message = 'Project name must be alphanumeric';
        },
        setCurrentProjectId: (state, action) => {
            state.currentProjectId = action.payload;
        }
    },
    // ******* Define asynchronous actions ******* //
    extraReducers: builder => {
        builder
            .addCase(createProject.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = true
                state.message = i18n.t('projectCreated')
                showSuccessToast(i18n.t('projectCreated'));
                state.error = null

                state.projectInfo?.push(action.payload)
            })
            .addCase(createProject.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = true
                state.message = action.payload
                showErrorToast(i18n.t(action.payload));
                state.success = false
            })
            .addCase(getProjects.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.projectInfo = action.payload.projects
                state.error = null

                // Sort the projects by name
                state.projectInfo?.sort((a, b) => (a.name > b.name) ? 1 : -1)
            })
            .addCase(getProjects.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = true
                state.message = action.payload
                showErrorToast(i18n.t(action.payload));
                state.success = false
            })
            .addCase(deleteProject.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = true
                state.message = i18n.t('projectDeleted')
                showSuccessToast(i18n.t('projectDeleted'));
                state.error = null

                state.projectInfo = state.projectInfo.filter(item => item.id !== action.payload.id)
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.loading = 'idle'
                state.message = action.payload
                state.error = true
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(updateProjectName.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(updateProjectName.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = true
                state.message = i18n.t('projectNameUpdated')
                state.error = null
                showSuccessToast(i18n.t('projectNameUpdated'));

                // Update the project name in projectInfo
                const index = state.projectInfo.findIndex(project => project.id === action.payload.id);
                if (index !== -1) {
                    // Replace the old project with the edited project in the projectInfo array
                    state.projectInfo[index] = action.payload;
                }
            })
            .addCase(updateProjectName.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = true
                state.message = action.payload
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(addCollaborator.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(addCollaborator.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = true
                state.message = i18n.t('collaboratorAdded')
                state.error = null
                showSuccessToast(i18n.t('collaboratorAdded'));

                // Update the project collaborators in projectInfo
                const index = state.projectInfo.findIndex(project => project.id === action.meta.arg.projectId);
                if (index !== -1) {
                    // Add the new collaborator to the collaborators array
                    state.projectInfo[index]?.shared_with?.push({ 'email': action.meta.arg.email });
                }
            })
            .addCase(addCollaborator.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = true
                state.message = i18n.t(action.payload)
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(removeCollaborator.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(removeCollaborator.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = true
                state.message = i18n.t('collaboratorRemoved')
                state.error = null
                showSuccessToast(i18n.t('collaboratorRemoved'));

                // Update the project collaborators in projectInfo
                const index = state.projectInfo.findIndex(project => project.id === action.meta.arg.projectId);
                if (index !== -1) {
                    // Find collaborator from email (use email that was passed as parameter to removeCollaborator)
                    const collaboratorIndex = state.projectInfo[index].shared_with.findIndex(collaborator => collaborator.email === action.meta.arg.email);
                    if (collaboratorIndex !== -1) {
                        // Remove the collaborator from the collaborators array
                        state.projectInfo[index].shared_with.splice(collaboratorIndex, 1);
                    }
                }
            })
            .addCase(removeCollaborator.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = true
                state.message = action.payload
                state.success = false
                showErrorToast(i18n.t(action.payload));
            })
    }
})

export const { clearMessages, setCurrentProjectId, incorrectProjectName } = projectSlice.actions
export default projectSlice.reducer;