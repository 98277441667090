import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationTR from './locales/tr/translation.json';

import prescreenEN from './locales/en/prescreenQuestions.json'
import prescreenTR from './locales/tr/prescreenQuestions.json'

import termsAndConditionsEN from './locales/en/termsAndConditions.json';
import termsAndConditionsTR from './locales/tr/termsAndConditions.json';
import privacyPolicyEN from './locales/en/privacyPolicy.json';
import privacyPolicyTR from './locales/tr/privacyPolicy.json';



const resources = {
    en: {
        translation: translationEN,
        termsAndConditions: termsAndConditionsEN,
        privacyPolicy: privacyPolicyEN,
        prescreen: prescreenEN
    },
    tr: {
        translation: translationTR,
        termsAndConditions: termsAndConditionsTR,
        privacyPolicy: privacyPolicyTR,
        prescreen: prescreenTR
    }
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18next;