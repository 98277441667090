import React from 'react'

function Footer() {
    return (
        <div className="footer-container"> {/* Added container div */}
            <footer>
                <div className="text-center footer-content">Copyright &copy; MarsData</div>
            </footer>
        </div>
    )
}

export default Footer