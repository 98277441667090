import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from '../../i18n';
import { baseUrl } from '../../const';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast, getErrorMessage } from '../utils';

const initialState = {
    paymentPageUrl: null,
    paymentToken: null,
    payments: null,
    receivedTransactions: null,
    sentTransactions: null,
}

// TODO: Call create_checkout_form
export const initCheckoutForm = createAsyncThunk(
    'user/initCheckoutForm',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/payments/create_checkout_form/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getCheckoutFormStatus = createAsyncThunk(
    'user/getCheckoutFormStatus',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/payments/get_checkout_form_status/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getPayments = createAsyncThunk(
    'user/getPayments',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/payments/get_payments/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Transfer payment from one researcher to another
export const sendMoney = createAsyncThunk(
    'user/transferPayment',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/payments/send_money/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get transferred payments
export const getTransactions = createAsyncThunk(
    'user/getTransferredPayments',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.user.access}`
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/payments/get_transactions/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// ******* Define slice ******* //
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearPaymentPageUrl: (state) => {
            state.paymentPageUrl = null;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(initCheckoutForm.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(initCheckoutForm.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.paymentPageUrl = action.payload.paymentPageUrl
                state.paymentToken = action.payload.token
            })
            .addCase(initCheckoutForm.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getCheckoutFormStatus.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getCheckoutFormStatus.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = action.payload.message
                showSuccessToast(action.payload.message);
            })
            .addCase(getCheckoutFormStatus.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getPayments.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getPayments.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.payments = action.payload
            })
            .addCase(getPayments.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(sendMoney.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(sendMoney.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.success = i18n.t('paymentSent')
                showSuccessToast(i18n.t('paymentSent'));
            })
            .addCase(sendMoney.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getTransactions.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.receivedTransactions = action.payload.received_transactions
                state.sentTransactions = action.payload.sent_transactions
            })
            .addCase(getTransactions.rejected, (state, action) => {
                state.loading = 'idle'
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
    }
})

export const { clearPaymentPageUrl } = userSlice.actions;
export default userSlice.reducer;