import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Terms as ShortParticipant } from '../../static/policies/en/PrivacyShortParticipant';
import { Terms as FullParticipant } from '../../static/policies/en/PrivacyFullParticipant';
import { Terms as ShortResearcher } from '../../static/policies/en/PrivacyShortResearcher';
import { Terms as FullResearcher } from '../../static/policies/en/PrivacyFullResearcher';


export const PrivacyPolicy = () => {
    const { t } = useTranslation(['privacyPolicy']);
    const url = window.location.href;
    var userType = 'researcher';
    const [selectedShort, setSelectedShort] = React.useState(false);
    const [selectedLong, setSelectedLong] = React.useState(false);

    // If 'participant' is in url, show participant privacy policy
    if (url.includes('participant')) {
        userType = 'participant';
    }

    return (
        <>
            <div className='screen-notloggedin'>
                <div className='container'>
                    <Typography variant='h4'>
                        <Trans t={t}>{userType + 'PrivacyPolicyTitle'}</Trans>
                    </Typography>

                    {/*-*-*-*-*-*-*-* SHORT INFO *-*-*-*-*-*-*-*/}
                    <ToggleButton
                        value="check"
                        selected={selectedShort}
                        onChange={() => {
                            setSelectedShort(!selectedShort);
                        }}
                        sx={{ marginTop: '12px', color: 'blue' }}
                    >
                        {selectedShort ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}<Typography variant='body1'>{t(`${userType}SummaryPrivacyNotice`)}</Typography>
                    </ToggleButton>

                    {selectedShort ? (
                        <>
                            {userType === 'researcher' ? <Typography variant='body1'>
                                <ShortResearcher />
                            </Typography> : <Typography variant='body1'>
                                <ShortParticipant />
                            </Typography>}
                        </>
                    ) : <></>}


                    {/*-*-*-*-*-*-*-* LONG INFO *-*-*-*-*-*-*-*/}
                    <br></br>
                    <ToggleButton
                        value="check"
                        selected={selectedLong}
                        onChange={() => {
                            setSelectedLong(!selectedLong);
                        }}
                        sx={{ marginTop: '12px', color: 'blue' }}
                    >
                        {selectedLong ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}<Typography variant='body1'>{t(`${userType}FullPrivacyNotice`)}</Typography>
                    </ToggleButton>

                    {selectedLong ? (
                        <>
                            {userType === 'researcher' ? <Typography variant='body1'>
                                <FullResearcher />
                            </Typography> : <Typography variant='body1'>
                                <FullParticipant />
                            </Typography>}
                        </>
                    ) : <></>}


                </div>
            </div>
        </>
    );
};
