import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import BugReportIcon from '@mui/icons-material/BugReport';

const ReportBugButton = ({ setOpen }) => {
    const { t } = useTranslation();
    const { userInfo } = useSelector(state => state.user);

    const handleReportBug = () => {
        setOpen(true);
    };

    return (
        <>
            {userInfo ? (<div className="bugreport-button">
                <Button onClick={handleReportBug} size="lg" variant="outlined" color="error" sx={{
                    borderRadius: 2,
                }}>
                    <BugReportIcon />
                    <Typography variant="body2" color="red"><b>{t('reportABug')}</b></Typography>
                </Button>
            </div>) : <></>}
        </>
    );
};

export default ReportBugButton;