import React from 'react';

export const Terms = () => {
    return (
        <div>

            <h1>MarsData Academic Ltd - Privacy Notice</h1>
            <p>MarsData Academic Ltd ("MarsData", "we", "us" or "our") provides a platform ("Platform") that helps recruit participants ("Participants") for online research studies ("Studies") conducted by third-party researchers ("Researchers") and by us. This Privacy Notice describes how we process personal data of Researchers in relation to such Studies. For our privacy notice in relation to how we process personal data of Participants, please see here, and for our privacy notice in relation to how we process personal data of anyone else (e.g., suppliers, journalists, general enquirers or people just browsing the Website), please see here.</p>

            <p>If you are located in the United Kingdom 🇬🇧 ("UK”) or the European Economic Area 🇪🇺 ("EEA") and where the "GDPR" applies (i.e., collectively, the General Data Protection Regulation 2016/679 ("EU GDPR") and the EU GDPR as it forms part of UK law ("UK GDPR")), MarsData is the controller in respect of the processing of your personal data covered by this Privacy Notice. See the ‘Our details’ section below for our contact details.</p>

            <p>🇨🇳 If you are located in the People’s Republic of China, please see the China Addendum</p>
            <p>🇮🇳 If you are located in India, please see the India Addendum</p>
            <p>🇯🇵 If you are located in Japan, please see the Japan Addendum</p>

            <h2>Personal data we collect</h2>
            <p>Here’s the personal data we (and selected third parties acting on our behalf) may collect in relation to your use of the Platform as a Researcher:</p>
            <ul>
                <li>We may collect account information, such as your name, country of residence, business email address and any other login information. In addition, we may collect details of your employing organisation or institution or, where you are acting as a sole trader or on your own account, your trading name or name used for research purposes. This includes your or your organisation’s/institution’s general area of research e.g. ‘AI’ or ‘academic’, etc.</li>
                <li>We may collect verification information about you. Shortly after your account on the Platform has been opened, we may verify your email address by sending you a verification email. In certain circumstances, we may ask you to provide proof of your affiliation to your employing organisation or institution.</li>
                <li>We may collect activity data about your use of the Platform. This includes basic records of Studies you have published (including frequency, title(s), time published and completed, and the level of reward set for those Studies), your account balance, payments and top-ups and log files generated through your use of the Platform.</li>
                <li>We may collect transaction data such as rewards you have issued for Studies, payments and top-ups you have made on the Platform, and your account balance.</li>
                <li>We (or our third-party payment provider) may collect billing information relating to you or your organisation/institution. We only collect basic billing details such as billing address and VAT number. Other billing information (such as card details of you or your organisation/institution) is collected directly by our third-party payment provider, Braintree.</li>
                <li>We may collect user-generated content such as the information you choose to post publicly regarding the Platform in a user forum.</li>
                <li>We may collect correspondence with you related to your use of the Platform (such as when you contact us) and information contained in messages you have sent and received through the messaging features of the Platform. This information may include metadata and social media usernames.</li>
                <li>If you decide to take part in user research concerning use of the Platform, then the usability testing or interview will take place via a video call, which we will record (with your permission) or from which we may otherwise take notes. We may ask you to share your screen while you use features of the Platform for usability testing purposes so we can record your interactions with the Platform, or we may track those interactions by cookies or similar technologies. Any video recording will likely feature your face and voice as well as your screen-share. Finally, we may also receive written feedback from you on your use of the features being tested.</li>
                <li>Finally, we may collect general usage data in relation to your use of the Platform, including data which we obtain through our, or our service providers’, analytics tracking systems. This might include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and navigation paths, and other interactions with and uses of the Platform, including information about the timing, frequency and pattern of those interactions and that use.</li>
            </ul>

            <h2>What we do with your personal data</h2>
            <p>We may use your personal data for the following purposes or as otherwise described at the time of collection:</p>

            <h3>Service delivery and operations</h3>
            <p>We may use your personal data to:</p>
            <ul>
                <li>provide, operate and improve the Platform, Studies, and our business;</li>
                <li>personalise the Platform, including remembering the devices from which you have previously logged in and remembering your selections and preferences as you navigate the Platform;</li>
                <li>establish and maintain your account and profile on the Platform;</li>
                <li>communicate with you about the Platform and Studies, including by sending service-related announcements, updates, security alerts, and support and administrative messages;</li>
                <li>understand your needs and interests, and personalise your experience with the Platform and Studies and our communications; and</li>
                <li>provide support for the Platform and Studies, and respond to your requests, questions and feedback.</li>
            </ul>
            <p>We may read messages sent through the Platform. In particular, we may review them if necessary to:</p>
            <ul>
                <li>resolve disputes between our users;</li>
                <li>resolve support requests relating to those messages (e.g. allegations of abuse by another user, or bug reports relating to messaging); or</li>
                <li>investigate suspected misuse of the Platform (such as fraud, money laundering or spam advertising).</li>
            </ul>
            <p>We may also review messages for the purposes of user discovery – i.e. to gain insight into how our users use the Platform. The data derived from these reviews will be anonymised and aggregated and used for the same purposes as user research data (see below).</p>

            <h3>Research and development</h3>
            <p>We may use your personal data for research and development purposes, including to analyse and improve the Platform and Studies and our business and to develop new products and services. As part of these activities, we may create aggregated, de-identified and/or anonymised data from personal data we collect. We make personal data into de-identified or anonymised data by removing information that makes the data personally identifiable to you. We may use this aggregated, de-identified or otherwise anonymised data and share it with third parties for our lawful business purposes, including to analyse and improve the Platform and promote our business and will not attempt to reidentify you from any such data. From time to time, we may also contact you to invite you to take part in user research, and may use the personal data you provide as part of these user research opportunities for the above purposes.</p>

            <h3>Marketing</h3>
            <p>We (and our service providers acting on our behalf) may send you direct marketing communications personalised based on your needs and interests. You may opt-out of our marketing communications at any time as described in the ‘Your rights’ section below.</p>

            <h3>Advertising</h3>
            <p>If you have accepted our use of Marketing/Advertising cookies, we may use your personal data to ensure you only see relevant ads for our products and services on third-party sites. You may opt-out of this use of your personal data at any time by rejecting or opting out of our Marketing/Advertising cookies.</p>

            <h3>Service improvement and analytics</h3>
            <p>We may use your personal data to analyse your usage of the Platform, improve the Platform, MarsData Studies, and the way Researcher Studies are conducted, improve the rest of our business, help us understand user activity on the Platform and in Studies, including which pages are most and least visited and how visitors move around the Platform, as well as user interactions with our emails, and to develop new products and services.</p>

            <h3>Compliance and protection</h3>
            <p>We may use your personal data to:</p>
            <ul>
                <li>verify identity for fraud prevention purposes;</li>
                <li>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas, investigations or requests from government authorities;</li>
                <li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                <li>audit our internal processes for compliance with legal and contractual requirements or our internal policies;</li>
                <li>enforce the terms and conditions that govern the Platform and Studies; and</li>
                <li>prevent, identify, investigate and deter fraudulent, harmful, unauthorised, unethical or illegal activity, including cyberattacks and identity theft.</li>
            </ul>

            <h2>Legal Basis</h2>
            <p>In the below table, we have set out our purposes for using your personal data and the legal basis on which we do it (where such legal basis is required by applicable law, such as the GDPR). Where required, our legal bases for processing your personal data are:</p>
            <ul>
                <li>Where we need to perform a contract, we are about to enter into or have entered into with you (“Contractual Necessity”).</li>
                <li>Where it is necessary for our (or a third party’s) legitimate interests, and your interests and fundamental rights do not override those interests (“Legitimate Interests”). More detail about the specific legitimate interests pursued in respect of each Purpose we use your personal data for is set out in the table below.</li>
                <li>Where we need to comply with a legal or regulatory obligation (“Compliance with Law”).</li>
                <li>Where we have your specific consent to carry out the processing for the Purpose in question (“Consent”).</li>
            </ul>

            <table border="1">
                <tr>
                    <th>Purpose</th>
                    <th>Categories of personal data involved</th>
                    <th>Legal basis</th>
                </tr>
                <tr>
                    <td>Service delivery and operations, including to:
                        <ul>
                            <li>✔️ provide you with the use of the Platform;</li>
                            <li>✔️ communicate with you in relation to your use of the Platform; and</li>
                            <li>✔️ perform our obligations under the Researcher Terms.</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Account information</li>
                            <li>Transaction data</li>
                            <li>Billing information</li>
                            <li>Usage data</li>
                            <li>Correspondence</li>
                            <li>Activity data</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Contractual Necessity</li>
                            <li>Where Contractual Necessity does not apply: Legitimate Interests We have a legitimate interest in conducting our business.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Verification checks to confirm your identity or authenticate your use of the Platform.</td>
                    <td>Verification information</td>
                    <td>Legitimate Interests We have a legitimate interest in verifying the identity of Researchers to ensure the authenticity of users of the Platform, and to prevent fraud.</td>
                </tr>
                <tr>
                    <td>Research and development, including to analyse performance and data in relation to Studies you have published in order to improve the Platform and other content based on analysing user feedback, and for fraud prevention purposes.</td>
                    <td>
                        <ul>
                            <li>Transaction data</li>
                            <li>User research data</li>
                            <li>Usage data</li>
                            <li>Correspondence</li>
                            <li>Activity data</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Legitimate Interests We have a legitimate interest in improving our Platform.</li>
                            <li>Consent, where consent is required under applicable data protection or privacy laws, e.g., where non-essential cookies are used for this purpose.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Marketing, including to send you content related to MarsData.</td>
                    <td>Account information</td>
                    <td>
                        <ul>
                            <li>Legitimate Interests We have a legitimate interest in promoting our operations and goals as an organisation and sending marketing communications for that purpose.</li>
                            <li>Consent, in circumstances or in jurisdictions where consent is required under applicable data protection or privacy laws for the sending of any given marketing communications.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Advertising, including to ensure you only see ads for MarsData which are relevant to you.</td>
                    <td>
                        <ul>
                            <li>Account information</li>
                            <li>Usage data</li>
                        </ul>
                    </td>
                    <td>
                        <ul>
                            <li>Legitimate Interests We have a legitimate interest in promoting our products and services and ensuring our advertising is relevant to you and your requirements.</li>
                            <li>Consent, where consent is required under applicable data protection or privacy laws, e.g., where non-essential cookies are used for this purpose.</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Creation of aggregated, de-identified and/or anonymised data.</td>
                    <td>Any and all data types</td>
                    <td>Legitimate Interests We have a legitimate interest in using privacy-preserving techniques to analyse user behaviour.</td>
                </tr>
                <tr>
                    <td>Compliance and protection, including to comply with applicable laws and legal processes, protect ours and others’ rights, audit our processes for legal and contractual compliance, and identify and prevent harmful, unauthorised or illegal activity.</td>
                    <td>Any and all data types</td>
                    <td>
                        <ul>
                            <li>Compliance with Law</li>
                            <li>Where Compliance with Law is not applicable: Legitimate Interests We have a legitimate interest in participating in, supporting, and following legal process and requests, including through co-operation with authorities. We may also have a legitimate interest in ensuring the protection, maintenance, and enforcement of our rights, property and/or safety.</li>
                        </ul>
                    </td>
                </tr>
            </table>

            <h1>How we share your personal data</h1>

            <h2>Published information</h2>
            <p>Any information which you choose to include in a Study listing will be visible to Participants. Any user-generated content which you choose to include in any forum post will be visible to other users, or browsers, of that forum.</p>

            <h2>Service Providers, Suppliers, and Contractors</h2>
            <p>We may disclose your personal data to other service providers, suppliers or contractors in connection with the uses described above. For example, we may disclose:</p>
            <ul>
                <li>any personal data in our possession to service providers that host the servers on which our data is stored; and</li>
                <li>usage data and account information for the purposes of analysing use of the Platform, account verification and for fraud prevention. Usage data and account information (e.g. hashed email addresses) may also be shared with and processed by third-party providers who deliver ads.</li>
            </ul>
            <p>You can find a complete list of our relevant providers and their privacy policies here.</p>

            <h2>Third-Party Payment Provider</h2>
            <p>Our third-party payment provider, Braintree, may collect certain transaction data and billing information directly, only to the extent necessary for the purposes of processing payments, refunding payments, and dealing with complaints and queries relating to payments and refunds. You can find information about its policies and practices here.</p>

            <h2>Professional advisors</h2>
            <p>We may disclose your personal data to our professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</p>

            <h2>Authorities and others</h2>
            <p>We may disclose your personal data to law enforcement, government authorities, and private parties as we believe in good faith to be necessary or appropriate for the Compliance and protection purposes described above.</p>

            <h2>Business transferees</h2>
            <p>We may disclose personal data in the context of actual or prospective business transactions (e.g., investments in MarsData, financing of MarsData, public stock offerings, or the sale, transfer or merger of all or part of our business, assets or shares), for example, we may need to share certain personal data with prospective counterparties and their advisers. We may also disclose your personal data to an acquirer, successor, or assignee of MarsData as part of any merger, acquisition, sale of assets, or similar transaction, and/or in the event of an insolvency, bankruptcy, or receivership in which personal data is transferred to one or more third parties as one of our business assets.</p>

            <h2>International transfers of your personal data</h2>
            <p>Some of the third parties to whom we may transfer your personal data, discussed above, may be located outside the UK and EEA or may transfer the personal data to their own service providers located outside the UK and EEA. If so, then we will ensure that transfers by our appointed data processors will only be made lawfully (e.g., to countries in respect of which the European Commission or UK has made an "adequacy decision”, or with appropriate safeguards such as the use of standard clauses approved by the European Commission or UK, bolstered by supplementary measures where required, or to signatories of the EU-US Data Privacy Framework, where applicable). You may contact us if you would like further information about these safeguards.</p>

            <h2>Third-parties who are controllers in their own right</h2>
            <p>We have no control over any third-party whose site or services are linked from our Platform (such as third-party survey platforms or social media operators). You should check their privacy notices and relevant terms to understand the uses they might make of your personal data.</p>

            <h2>How long we keep your personal data</h2>
            <p>We only retain personal data for as long as is necessary for the purposes for which we use it. In particular:</p>
            <ul>
                <li>most account information will be kept during the life of the account and then deleted no more than twelve months after account closure. As an exception, we may retain data related to dormant accounts if they have any outstanding balances associated with them, until that balance has been cleared, in order to ensure we can pay out or dispose of that balance lawfully and in accordance with our terms;</li>
                <li>transaction data and billing information that we collect will be retained for up to seven years following the relevant transaction or payment, as we keep records for tax purposes (however, this does not include transaction data and billing information collected directly by our third party payment provider, which is subject to its own retention policy, as described above);</li>
                <li>encrypted phone numbers and email addresses may be stored for up to seven years for the purposes of fraud prevention;</li>
                <li>verification information may be stored for the purposes of fraud prevention for as long as is proportionate and necessary;</li>
                <li>correspondence and documents relating to our contractual relationship with you may be retained for up to seven years from termination (in order to ensure that we have kept records of that relationship for the applicable limitation periods prescribed by law);</li>
                <li>user research data will be kept only as long as it is relevant, which may depend on the context of the specific research. For example, if the research related to a product feature which is entirely obsolete, and in relation to which feedback is no longer relevant, we will delete the relevant user research data. Sometimes it may be useful to keep user research data as a comparator and reference over several product cycles, in which case we may keep it for several years. You can always contact us if you wish us to delete any recordings comprised in user research data. We will also pseudonymise user research data where practicable (e.g., by referring to User A, User B, etc.); and</li>
                <li>usage data will be deleted within twelve months of creation.</li>
            </ul>
            <p>We may retain your personal data longer:</p>
            <ul>
                <li>to comply with law;</li>
                <li>to protect the vital interests of any individual; or</li>
                <li>in connection with any legal claims (to the extent those claims are continuing after the end of the relevant retention period).</li>
            </ul>
            <p>Finally, we may create anonymised or aggregated records relating to demography or the use of the Platform, from which no individual is identifiable, and we may keep those records indefinitely.</p>

            <h2>Your organisation or institution’s role as controller</h2>
            <p>This Privacy Notice provides you, the individual Researcher, with information about how we use your personal data. However, you should bear in mind that as a Researcher using the Platform you may have access to personal data relating to Participants, and when you do you or your organisation/institution will be a data controller in relation to that personal data.</p>

            <h2>Your rights</h2>
            <p>You may have rights under certain data protection laws to the extent they apply – they are complex, and subject to exemptions. If you are in the UK, you can read guidance from the Information Commissioner’s Office at <a href="http://www.ico.gov.uk">www.ico.gov.uk</a> for a fuller explanation of your rights. In summary, these include:</p>
            <ul>
                <li>the right to access: you may have the right to confirmation as to whether or not we process your personal data and, where we do, to access the personal data, together with certain additional information;</li>
                <li>the right to rectification: you may have the right to have any inaccurate or incomplete personal data about you rectified or completed;</li>
                <li>the right to erasure: in some circumstances you may have the right to the erasure of your personal data (for example, if the personal data are no longer needed for the purposes for which they were processed or if the processing is for direct marketing purposes);</li>
                <li>the right to restrict processing: you may have the right to restrict the processing of your personal data to limit its use. Where processing has been restricted, we may continue to store your personal data and will observe the restrictions on processing except to the extent permitted by law;</li>
                <li>the right to object to processing: you may have the right to object to our processing of your personal data on the basis of legitimate interests (discussed above) or for direct marketing purposes and if you do so we will stop processing your personal data except to the extent permitted by law; and</li>
                <li>the right to complain to a supervisory authority: if you consider that our processing of your personal data is unlawful, you may have a legal right to lodge a complaint with the ICO or your local data protection authority.</li>
            </ul>
            <p>You can submit requests to exercise these rights by emailing <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>. We may request specific information from you to help us confirm your identity and process your request. Whether or not we are required to fulfil any request you make will depend on a number of factors (e.g., why and how we are processing your personal data, where you are located, etc.). If we reject any request you may make (whether in whole or in part) we will let you know our grounds for doing so at the time, subject to any legal restrictions.</p>
            <p>You can ask us to stop sending you marketing-related emails at any time by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. You can also amend your marketing preferences at any time in your account. Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.</p>

            <h2>Do Not Track</h2>
            <p>Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.</p>

            <h2>Security</h2>
            <p>We employ a number of technical, organisational and physical safeguards designed to protect the personal data we collect, in accordance with applicable laws. However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal data.</p>

            <h2>Children</h2>
            <p>Our Platform is not intended for use by anyone under 18 years of age. If you are a parent or guardian of a child from whom you believe we have collected personal data in a manner prohibited by law, please contact us. If we learn that we have collected personal data through the Platform from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the personal data.</p>

            <h2>Our details</h2>
            <p>MarsData Academic Ltd is registered in England and Wales under registration number XYZ, and our registered office is at 483 Green Lanes, London, England, N13 4BS. You can contact us by post at our registered office, or by email at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>.</p>
            <p>We are registered as a data controller with the UK Information Commissioner's Office under registration number ZA317731.</p>
            <p>If you are in the EEA, our EU GDPR representative can be contacted here:</p>
            <p>- GDPR-Rep.eu - Maetzler Rechtsanwalts GmbH & Co KG, Attorneys at Law, c/o MarsData Academic Ltd, Schellinggasse 3/10, 1010 Vienna, Austria <a href="https://prighter.com">https://prighter.com</a> Please add the following subject heading to all correspondence: GDPR-REP ID: 18862131</p>

            <h2>Revisions, and last updated</h2>
            <p>We’re constantly monitoring developments in law, introducing new product features and striving to improve our processes, and we may revise this Privacy Notice from time to time. If we make any substantial changes to this privacy notice which affect you, we will notify you via a message or prominent notification on the Platform and/or by email. Where required under applicable law, we will obtain your consent to such changes, otherwise, your continued use of our services after any such updates take effect will constitute acceptance of those changes. If you do not accept any updates to this privacy notice, you should stop using our services.</p>
            <p><strong>Last updated: July 2024</strong></p>

        </div>
    );
};