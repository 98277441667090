import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SubmissionEvent = ({ status, date, title }) => {
    const { t } = useTranslation();

    // Parse the ISO string back to a Date object, then format
    const createDateString = (date) => {
        const dateObj = new Date(date);
        const month = dateObj.toLocaleString('default', { month: 'long' });
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
    
        return `${dateObj.getDate()} ${t(month)}, ${dateObj.getFullYear()} ${hours}:${minutes.toString().padStart(2, '0')}`;
    }

    return (
        <div
            style={{
                maxWidth: '97%',
                padding: '12px',
                margin: '6px',
                position: 'relative',
                borderTop: '1px solid gray',
                borderBottom: '1px solid gray',
            }}
        >
            <Typography variant="body1">
                {['reserved', 'started', 'finished', 'timed_out', 'not_valid', 'reservation_cancelled'].includes(status) ? <b>{t('byWorker')}: </b> : ' '}
                {t('study')} "<b>{title}</b>" {t(status)}</Typography>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '5px' }}>
                <Typography variant="caption">{createDateString(date)}</Typography>
            </div>
        </div>
    );
};

export default SubmissionEvent;