import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Loader from '../../components/common/Loader';
import FormContainer from '../../components/common/FormContainer';
import { requestPasswordReset } from '../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography, InputLabel } from '@mui/material';

function ForgotPasswordScreen() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.user);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(requestPasswordReset({ 'email': email }));
    };

    return (
        <div className='screen-notloggedin'>
            <FormContainer mdval={6}>
                <Typography variant="h4" gutterBottom sx={{ mb: '1px' }}>
                    {t('forgotPassword')}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    {t('itsOkay')}
                </Typography>

                {loading === 'pending' && <Loader />}

                <form onSubmit={submitHandler}>
                    <InputLabel
                        shrink={false}
                        htmlFor="enterEmail"
                        sx={{ mb: '1px', mt: '24px', padding: 0 }}
                    >
                        <Typography variant='body2'>{t('enterEmail')}</Typography>
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        id="enterEmail"
                        type='email'
                        placeholder={t('enterEmail')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                        sx={{ mb: 0, mt: 0 }}
                    />

                    <Grid container justifyContent="flex-end" sx={{ marginTop: '12px' }}>
                        <Grid item>
                            <Button type='submit' variant='contained'>
                                {t('emailMeRecovery')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </FormContainer>
        </div>
    );
}

export default ForgotPasswordScreen;