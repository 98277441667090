import * as React from 'react';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import MessageIcon from '@mui/icons-material/Message';
import { useHistory } from 'react-router-dom';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuizIcon from '@mui/icons-material/Quiz';

function ParticipantDrawer(props) {
    const drawerWidth = '15%';
    const { t } = useTranslation();
    const { window } = props;
    const history = useHistory();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const userInfo = useSelector(state => state.user.userInfo);

    const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); }

    const drawer = (
        <List>
            <ListItem disablePadding>
                <ListItemButton onClick={() => { history.push('/participant') }}>
                    <ListItemIcon>
                        <WorkOutlineIcon fontSize='medium' style={{ color: '#264573', marginRight: '6px' }} />
                    </ListItemIcon>
                    <ListItemText className='hide-under-1000' primary={t("studies")} />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton onClick={() => { history.push('/dashboard') }}>
                    <ListItemIcon>
                        <DashboardIcon fontSize='medium' style={{ color: '#264573' }} />
                    </ListItemIcon>
                    <ListItemText className='hide-under-1000' primary={t("dashboard")} />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton onClick={() => { history.push('/screening-questions') }}>
                    <ListItemIcon>
                        <QuizIcon fontSize='medium' style={{ color: '#264573' }} />
                    </ListItemIcon>
                    <ListItemText className='hide-under-1000' primary={t("screeningQuestions")} />
                </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
                <ListItemButton component={Link} to={`/messages`}>
                    <ListItemIcon>
                        <MessageIcon fontSize='medium' style={{ color: '#264573' }} />
                    </ListItemIcon>
                    <ListItemText className='hide-under-1000' primary={t("messages")} />
                </ListItemButton>
            </ListItem>

        </List>

    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            {!userInfo?.user?.participant_approved ? <></> :
                <>
                    {/* Full screen drawer */}
                    <Drawer
                        variant="permanent"
                        anchor="left"

                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                top: '65px',
                                width: drawerWidth,
                                boxSizing: 'border-box',
                                marginTop: '0px',
                                paddingTop: '0px',
                                backgroundColor: '#f5f5f5',
                                border: 0
                            },
                            display: { xs: 'none', sm: 'block' },
                        }}
                    >

                        {drawer}
                    </Drawer>

                    {/* Mobile drawer */}
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            border: 0,
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                marginTop: '0px',
                                borderTop: '1px',
                            },
                            borderTop: '1px',
                        }}
                    >
                        {drawer}
                    </Drawer>
                </>
            }
        </>
    );
}

export default ParticipantDrawer;