import React from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Message = ({ message, isOwnMessage, date }) => {
    const { t } = useTranslation();

    // Parse the ISO string back to a Date object, then format
    const createDateString = (date) => {
        // Create string where we show the date in format DD Month in words, YYYY
        const dateObj = new Date(date)
        const month = dateObj.toLocaleString('default', { month: 'long' });

        return `${dateObj.getDate()} ${t(month)}, ${dateObj.getFullYear()}`
    }

    return (
        <Paper
            elevation={3}
            sx={{
                maxWidth: '60%',
                margin: isOwnMessage ? '10px 10px 10px auto' : '10px auto 10px 10px',
                padding: '10px',
                backgroundColor: isOwnMessage ? '#afafaf' : '#595959',
                color: isOwnMessage ? '#000' : '#fff',
                overflowWrap: 'break-word' // Break long words
            }}
        >
            <Typography variant="body1">{message}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '5px' }}>
                <Typography variant="caption">{createDateString(date)}</Typography>
            </Box>
        </Paper>
    );
};

export default Message;