import React, { useState, useEffect } from 'react';
import { List, ListItemButton, ListItemText, Box, TextField, Divider, Tab, Tabs, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import InboxIcon from '@mui/icons-material/Inbox';
import { getUsers, getBulkMessages, getUsersSent } from '../../features/message/messageSlice';
import { setMessages } from '../../features/message/messageSlice';
import PeopleIcon from '@mui/icons-material/People';

const UserMenu = ({ onSelectUser, setOpen, selectedTab, setSelectedTab, toggleUserMenu, setBulkReceivers, setOpenReceiversModal }) => {
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const { users, currentReceiver, bulkMessages } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const { userInfo } = useSelector(state => state.user);
    const [filteredUsers, setFilteredUsers] = useState(users);


    // Change filteredUsers when users or search change
    useEffect(() => {
        setFilteredUsers(users?.filter(user =>
            user.id.toLowerCase().includes(search.toLowerCase())
        ));
    }, [users, search]);

    // If users is none, tab is 0, get users
    useEffect(() => {
        if (selectedTab === 0 && !users) {
            dispatch(getUsers({ access: userInfo.user.access }));
        }
    }, [selectedTab, users, userInfo.user.access, dispatch]);


    const handleTabChange = (event, newValue) => {
        event.preventDefault();
        setSelectedTab(newValue);
        dispatch(setMessages([]));

        if (newValue === 0) {
            dispatch(getUsers({ access: userInfo.user.access }));
        } else if (newValue === 1) {
            dispatch(getUsersSent({ access: userInfo.user.access }));
        } else if (newValue === 2 & userInfo.user.user_type === 'researcher') {
            dispatch(getBulkMessages({ access: userInfo.user.access }));
        }
    }

    const createDateString = (date) => {
        // Create string where we show the date in format DD Month in words, YYYY
        const dateObj = new Date(date);
        const month = dateObj.toLocaleString('default', { month: 'long' });

        return `${dateObj.getDate()} ${t(month)}, ${dateObj.getFullYear()}`;
    }

    const onSelectBulkMessage = (bulkMsg) => {
        dispatch(setMessages([bulkMsg]));
        setBulkReceivers(bulkMsg.receivers);
    }

    useEffect(() => { let root = document.getElementById("root"); root?.setAttribute("aria-hidden", "false"); }, []);

    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: '80vh' }}>
            <Button variant='contained' style={{ width: '100%', marginBottom: '10px', marginTop: 0 }} onClick={() => setOpen(true)}>
                <AddIcon sx={{ marginRight: '6px' }} />
                {t('composeMessage')}
            </Button>

            <TextField
                variant="outlined"
                placeholder={t('searchUsers')}
                value={search}
                onChange={e => setSearch(e.target.value)}
                sx={{ width: '100%', marginBottom: 1 }}
            />
            <Divider />
            <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable">
                <Tab className='tab-hover' label={t('inbox')} icon={<InboxIcon />} />
                <Tab className='tab-hover' label={t('sent')} icon={<SendIcon />} />
                {userInfo.user.user_type === 'researcher' && <Tab className='tab-hover' label={t('bulkMessages')} icon={<PresentToAllIcon />} />}
            </Tabs>
            <List>
                {
                    /* Inbox */
                    [0, 1].includes(selectedTab) ?
                        (filteredUsers?.length === 0 ? <ListItemText secondary={t('noUsersFound')} />
                            :
                            filteredUsers?.map((user) => (
                                <ListItemButton
                                    key={user.id}
                                    onClick={() => { onSelectUser(user); toggleUserMenu() }}
                                    sx={{ bgcolor: (currentReceiver != null && user.id === currentReceiver) ? '#B9B9B9' : '#FFFFFF' }}
                                >

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <ListItemText primary={user.id} secondary={`${t(user.first_name).replace('researcher', t('researcher'))} ${t(user.last_name)}`} />
                                        {!user.last_message_read ? <FiberNewIcon style={{ color: '#5f8ced' }} /> : <></>}
                                    </div>

                                </ListItemButton>
                            ))
                        ) :
                        /* Bulk Messages */
                        selectedTab === 2 ? (
                            bulkMessages?.length === 0 ? <ListItemText secondary={t('noBulkMessagesSent')} />
                                :
                                bulkMessages?.map((bulkMsg) => (
                                    <ListItemButton
                                        key={bulkMsg.date}
                                        onClick={() => { onSelectBulkMessage(bulkMsg); toggleUserMenu() }}
                                    >
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography align='center' fontSize={12}
                                                    sx={{
                                                        border: 1,
                                                        backgroundColor: 'gray',
                                                        borderRadius: 1,
                                                        padding: '2px',
                                                        color: 'white',
                                                        '&:hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }}
                                                    onClick={() => setOpenReceiversModal(true)}
                                                >
                                                    <PeopleIcon fontSize="small" sx={{ marginRight: '6px' }} />
                                                    <b>{`${bulkMsg.n_receivers} ${t('sentTo')}`}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} zeroMinWidth>
                                                <ListItemText primary={
                                                    <Typography noWrap>{bulkMsg.content}</Typography>
                                                } secondary={createDateString(bulkMsg.date)} />
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                ))
                        ) : null
                }
            </List>
        </Box>
    );
};

export default UserMenu;