import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import i18n from '../../i18n';
import { baseUrl } from '../../const';
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast, showSuccessToast, getErrorMessage } from '../utils';

const initialState = {
    studyInfo: null,
    loading: 'idle',
    error: null,
    success: null,
    submissions: null,

    loadingNumAvailable: 'idle',
    numAvailableParticipants: 'NA',

    loadingGetCompletedStudies: 'idle',
    completedStudies: null,

    currentStudyId: null,
    activeStudies: null,
    recentlyFinishedStudies: null
}

// ******* Define async thunk actions ******* //
export const createStudy = createAsyncThunk(
    'study/createStudy',
    async (data, { rejectWithValue }) => {
        data.data.status = data.status
        data = data.data

        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/studies/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const editStudy = createAsyncThunk(
    'study/editStudy',
    async (data, { rejectWithValue }) => {
        data.data.status = data.status
        data = data.data

        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.put(
                `${baseUrl}/api/studies/${data.id}/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const deleteStudy = createAsyncThunk(
    'study/deleteStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.delete(
                `${baseUrl}/api/studies/${data.id}/`,
                config
            )

            return { 'id': data.id, 'response': response.data };
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const cancelStudy = createAsyncThunk(
    'study/cancelStudy',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.id}/cancel/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const pauseStudy = createAsyncThunk(
    'study/pauseStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.id}/pause/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const resumeStudy = createAsyncThunk(
    'study/resumeStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.id}/resume/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const archiveStudy = createAsyncThunk(
    'study/archiveStudy',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.id}/archive/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const getStudiesInProject = createAsyncThunk(
    'study/getStudiesInProject',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.projectId}/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get submissions of a study
export const getSubmissions = createAsyncThunk(
    'study/getSubmissions',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.studyId}/submissions/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Approve a submission with a post request
export const approveSubmission = createAsyncThunk(
    'study/approveSubmission',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/researcher/approve/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Reject a submission with a post request
export const rejectSubmission = createAsyncThunk(
    'study/rejectSubmission',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/researcher/reject/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

export const sendBonus = createAsyncThunk(
    'study/sendBonus',
    async (data, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${data.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/researcher/send_bonus/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get the number of available participants for a study, by sending a post request to n_qualified_workers
export const getNumOfAvailableParticipants = createAsyncThunk(
    'study/getNumOfAvailableParticipants',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.post(
                `${baseUrl}/api/researcher/n_qualified_workers/`,
                data,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get completed studies of a researcher
export const getCompletedStudies = createAsyncThunk(
    'study/getCompletedStudies',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/researcher/get_completed_studies/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Duplicate a study
export const duplicateStudy = createAsyncThunk(
    'study/duplicateStudy',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/${data.studyId}/duplicate/?internal_name=${data.internal_name}`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);
            return rejectWithValue(error);
        }
    }
)

// Get active studies
export const getActiveStudiesResearcher = createAsyncThunk(
    'study/getActiveStudiesResearcher',
    async (data, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/get_active_studies/`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);

            return rejectWithValue(error);
        }
    }
)

export const getRecentlyFinishedStudies = createAsyncThunk(
    'study/getRecentlyFinishedStudies',
    async ({ timeRange }, { rejectWithValue, getState }) => {
        try {
            const { user: { userInfo } } = getState();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    // Add the token to the header
                    Authorization: `Bearer ${userInfo.user.access}`,
                }
            }

            const response = await axios.get(
                `${baseUrl}/api/studies/get_recent_studies/?timeRange=${timeRange}`,
                config
            )

            return response.data;
        } catch (err) {
            let error = getErrorMessage(err);

            return rejectWithValue(error);
        }
    }
)


export const studySlice = createSlice({
    name: 'study',
    initialState,
    reducers: {
        // ******* Define synchronous actions ******* //
        clearMessages: (state) => {
            state.error = null;
            state.success = null;
            state.loading = 'idle';
        },
        setCurrentStudyId: (state, action) => {
            state.currentStudyId = action.payload;
        }
    },
    // ******* Define asynchronous actions ******* //
    extraReducers: builder => {
        builder
            .addCase(createStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(createStudy.fulfilled, (state, action) => {
                state.loading = 'idle'

                // Get 'data' we sent in the createStudy action
                let data = action.meta.arg.data

                state.error = null
                if (data.status === 'draft') {
                    state.success = i18n.t('studySavedAsDraft')
                    showSuccessToast(i18n.t('studySavedAsDraft'));
                } else if (data.status === 'active') {
                    state.success = i18n.t('studyCreatedAndPublished')
                    showSuccessToast(i18n.t('studyCreatedAndPublished'));
                }

            })
            .addCase(createStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(editStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(editStudy.fulfilled, (state, action) => {
                state.loading = 'idle';
                state.error = null;


                if (action.meta.arg.status === 'active' && !action.meta.arg.projectId) {
                    state.success = i18n.t('studySavedAndPublished')
                    showSuccessToast(i18n.t('studySavedAndPublished'));
                } else if (action.meta.arg.status === 'draft') {
                    state.success = i18n.t('studyEdited');
                    showSuccessToast(i18n.t('studyEdited'));
                }

                let newStudy = action.payload;

                // Update the study we edited from studyInfo object
                const index = state.studyInfo.findIndex(study => study.id === newStudy.id);
                if (index !== -1) {
                    // Replace the old study with the edited study in the studyInfo array
                    state.studyInfo[index] = newStudy;
                }
            })
            .addCase(editStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getStudiesInProject.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getStudiesInProject.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.studyInfo = action.payload.studies

                // Sort studies by the date they were created (create_date) is the following format: 2023-10-10T08:40:44.159722Z. Newest first.
                state.studyInfo.sort((a, b) => new Date(b.create_date) - new Date(a.create_date))
            })
            .addCase(getStudiesInProject.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(deleteStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(deleteStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('studyDeleted')

                showSuccessToast(i18n.t('studyDeleted'));

                // Set that study's status to 'deleted' in studyInfo
                let newStudy = action.payload.response
                const index = state.studyInfo.findIndex(study => study.id === newStudy.id);
                if (index !== -1) {
                    // Replace the old study with the edited study in the studyInfo array
                    state.studyInfo[index] = newStudy;
                }
            })
            .addCase(deleteStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(cancelStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(cancelStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('studyCancelled')

                showSuccessToast(i18n.t('studyCancelled'));

                // Set that study's status to 'cancelped' in studyInfo
                let newStudy = action.payload
                const index = state.studyInfo.findIndex(study => study.id === newStudy.id);
                if (index !== -1) {
                    // Replace the old study with the edited study in the studyInfo array
                    state.studyInfo[index] = newStudy;
                }
            })
            .addCase(cancelStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(pauseStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(pauseStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('studyPaused')

                showSuccessToast(i18n.t('studyPaused'));

                let newStudy = action.payload
                const index = state.studyInfo.findIndex(study => study.id === newStudy.id);
                if (index !== -1) {
                    // Replace the old study with the edited study in the studyInfo array
                    state.studyInfo[index] = newStudy;
                }
            })
            .addCase(pauseStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(resumeStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(resumeStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('studyResumed')

                showSuccessToast(i18n.t('studyResumed'));

                let newStudy = action.payload
                const index = state.studyInfo.findIndex(study => study.id === newStudy.id);
                if (index !== -1) {
                    // Replace the old study with the edited study in the studyInfo array
                    state.studyInfo[index] = newStudy;
                }
            })
            .addCase(resumeStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(archiveStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(archiveStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('studyArchived')

                showSuccessToast(i18n.t('studyArchived'));

                let newStudy = action.payload
                const index = state.studyInfo.findIndex(study => study.id === newStudy.id);
                if (index !== -1) {
                    // Replace the old study with the edited study in the studyInfo array
                    state.studyInfo[index] = newStudy;
                }
            })
            .addCase(archiveStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getSubmissions.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getSubmissions.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = null
                state.submissions = action.payload
            })
            .addCase(getSubmissions.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                state.submissions = null

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(approveSubmission.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(approveSubmission.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('submissionApproved')

                showSuccessToast(i18n.t('submissionApproved'));

                // Update the submission status as approved in the submissions array
                let newSubmissions = action.payload
                newSubmissions.forEach(newSubmission => {
                    const index = state.submissions.findIndex(submission => submission.id === newSubmission.id);
                    if (index !== -1) {
                        // Replace the old submission with the edited submission in the submissions array
                        state.submissions[index] = newSubmission;
                    }
                });
            })
            .addCase(approveSubmission.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(rejectSubmission.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(rejectSubmission.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('submissionRejected')

                showSuccessToast(i18n.t('submissionRejected'));

                // Update the submission status as rejected in the submissions array
                let newSubmissions = action.payload
                newSubmissions.forEach(newSubmission => {
                    const index = state.submissions.findIndex(submission => submission.id === newSubmission.id);
                    if (index !== -1) {
                        // Replace the old submission with the edited submission in the submissions array
                        state.submissions[index] = newSubmission;
                    }
                });
            })
            .addCase(rejectSubmission.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)

                showErrorToast(i18n.t(action.payload));
            })
            .addCase(sendBonus.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(sendBonus.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('bonusSent')
                showSuccessToast(i18n.t('bonusSent'));

                let newSubmissions = action.payload

                newSubmissions.forEach(newSubmission => {
                    const index = state.submissions.findIndex(submission => submission.id === newSubmission.id);
                    if (index !== -1) {
                        // Replace the old submission with the edited submission in the submissions array
                        state.submissions[index] = newSubmission;
                    }
                });
            })
            .addCase(sendBonus.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getNumOfAvailableParticipants.pending, (state) => {
                state.loadingNumAvailable = 'pending'
            })
            .addCase(getNumOfAvailableParticipants.fulfilled, (state, action) => {
                state.loadingNumAvailable = 'idle'
                state.error = null
                state.success = null
                state.numAvailableParticipants = action.payload.n_qualified
            })
            .addCase(getNumOfAvailableParticipants.rejected, (state, action) => {
                state.loadingNumAvailable = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                state.numAvailableParticipants = null
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getCompletedStudies.pending, (state) => {
                state.loadingGetCompletedStudies = 'pending'
            })
            .addCase(getCompletedStudies.fulfilled, (state, action) => {
                state.loadingGetCompletedStudies = 'idle'
                state.error = null
                state.success = null
                state.completedStudies = action.payload
            })
            .addCase(getCompletedStudies.rejected, (state, action) => {
                state.loadingGetCompletedStudies = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
                state.completedStudies = null
            })
            .addCase(duplicateStudy.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(duplicateStudy.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = i18n.t('studyDuplicated')
                showSuccessToast(i18n.t('studyDuplicated'));
                state.studyInfo.push(action.payload)
            })
            .addCase(duplicateStudy.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
            })
            .addCase(getActiveStudiesResearcher.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getActiveStudiesResearcher.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = null
                state.activeStudies = action.payload
            })
            .addCase(getActiveStudiesResearcher.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
                state.activeStudies = null
            })
            .addCase(getRecentlyFinishedStudies.pending, (state) => {
                state.loading = 'pending'
            })
            .addCase(getRecentlyFinishedStudies.fulfilled, (state, action) => {
                state.loading = 'idle'
                state.error = null
                state.success = null
                state.recentlyFinishedStudies = action.payload
            })
            .addCase(getRecentlyFinishedStudies.rejected, (state, action) => {
                state.loading = 'idle'
                state.success = null
                state.error = i18n.t(action.payload)
                showErrorToast(i18n.t(action.payload));
                state.recentlyFinishedStudies = null
            })
    }
})

export const { clearMessages, setCurrentStudyId } = studySlice.actions
export default studySlice.reducer;