import React from 'react';
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react';

import './styles.css'
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom'
import './i18n';

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <Router>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Router>
  </Provider>
);