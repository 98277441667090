import React, { useEffect, useState } from 'react';
import { Button, Modal, Box, TextField, Typography, Link } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resendActivationEmail } from '../../features/user/userSlice';
import { useParams } from 'react-router-dom';
import Loader from '../../components/common/Loader';
import FormContainer from '../../components/common/FormContainer';
import { activateAccount } from '../../features/user/userSlice';
import { useHistory } from 'react-router-dom';


const EnterEmailModal = ({ open, setOpen }) => {
    const { t } = useTranslation();
    const [email, setEmail] = React.useState('');
    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        setOpen(false);
        dispatch(resendActivationEmail({ email: email }));
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="enter-email-modal-title"
        >
            <Box
                component="form"
                onSubmit={submitHandler}
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="enter-email-modal-title" variant="h6" component="h2">
                    {t('enterEmail')}
                </Typography>
                <TextField
                    fullWidth
                    margin="normal"
                    id="email"
                    label={t('email')}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Box sx={{ textAlign: 'right', mt: 2 }}>
                    <Button type="submit" variant="outlined">{t('submit')}</Button>
                </Box>
            </Box>
        </Modal>
    );
}

const ActivateAccountScreen = () => {
    let { uid, token } = useParams(); // Assuming uid and token are passed as URL params
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [triedActivation, setTriedActivation] = useState(false);
    const { loading } = useSelector(state => state.user);
    const [openModal, setOpenModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!triedActivation) {
            dispatch(activateAccount({ uid: uid, token: token })).then((result) => {
                setTriedActivation(true);
                if (result.type.endsWith('fulfilled')) {
                    history.push('/login');
                }
            });
        }
    }, [dispatch, uid, token, triedActivation, history]);

    const resendEmail = () => {
        setOpenModal(true);
    }

    return (
        <div className='screen-notloggedin'>
            <FormContainer mdval={6}>
                {loading === 'pending' && <Loader />}

                <EnterEmailModal open={openModal} setOpen={setOpenModal} />

                <Typography variant="h4">{t('activateAccount')}</Typography>
                <Typography variant="h6">{t('notReceiveEmail')}</Typography>
                <Box sx={{ mt: 2 }}>
                    <Link onClick={resendEmail} style={{ cursor: 'pointer' }}>
                        {t('resendEmail')}
                    </Link>
                </Box>
            </FormContainer>
        </div>
    );
}

export default ActivateAccountScreen;