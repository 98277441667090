import React from 'react';

export const Terms = () => {
    return (
        <div>

            <p>MarsData Academic Ltd ("MarsData", "we", "us" or "our") provides a platform ("Platform") that helps recruit participants ("Participants") for online research studies ("Studies") conducted by third-party researchers ("Researchers") and by us. This Privacy Notice describes how we process personal data of Participants in relation to their use of the Platform, through which they can access and take part in Studies. For our privacy notice in relation to how we process personal data of Researchers, please see here, and for our privacy notice in relation to how we process personal data of anyone else (e.g., suppliers, journalists, general enquirers or people just browsing the Website), please see here.</p>

            <p>If you are located in the United Kingdom 🇬🇧 ("UK”) or the European Economic Area 🇪🇺 ("EEA") and where the "GDPR" applies (i.e., collectively, the General Data Protection Regulation 2016/679 ("EU GDPR") and the EU GDPR as it forms part of UK law ("UK GDPR")), MarsData is the data controller in respect of the processing of your personal data covered by this Privacy Notice. See the ‘Our details’ section below for our contact details.</p>

            <p>🇨🇳 If you are located in the People’s Republic of China, please see the China Addendum</p>
            <p>🇮🇳 If you are located in India, please see the India Addendum</p>
            <p>🇯🇵 If you are located in Japan, please see the Japan Appendum</p>
            <p>🇮🇩 If you are located in Indonesia, please see the Indonesian language version of this Privacy Notice</p>

            <h2>Personal data we collect</h2>
            <p>Here’s the personal data we (and selected third parties acting on our behalf) may collect in relation to your use of the Platform as a Participant:</p>
            <ul>
                <li>We may collect account information, such as your name, email address, date of birth, mobile number, address, other contact details and login information. We may also assign you a “MarsData ID”, a unique reference number or identifier which you will use on the Platform, including when you take part in Studies.</li>
                <li>We may collect profile information added in the “About You” section of your account on the Platform in response to any profile or screening questions. We use your responses to these questions to determine your eligibility for different Studies (including when a Researcher has asked us to screen for Participants meeting specific requirements from time to time). This profile information could include a range of data, depending on which profile or screening questions you have chosen to answer, but could include (as a non-exhaustive list) gender, relationship status, interests, hobbies, educational details and employment status. It could also include special categories of data, like data about your race, ethnic origin, politics, religion, health, sex life or sexual orientation. You can update or remove your profile information at any time.</li>
                <li>We (or our third-party verification providers) may collect verification information about you:
                    <ul>
                        <li><strong>Identity checks:</strong> As part of your onboarding process on the Platform, we may conduct certain identity checks. For example, we may verify your email address by sending you a verification email. We may also verify your mobile number via our third-party verification provider, Twilio. We also use a third-party verification provider, Onfido, to conduct identity checks. These checks require you to provide Onfido with a copy of your photo ID, like your passport or driving licence, together with a selfie to compare to that document. To authenticate your identity, Onfido may collect, generate, process, and use your biometric data (with your consent). The check process may also require you to provide a title, name, date of birth, email address, mobile number and country of residence. It may also collect, generate, process, or use additional data (like your IP address and associated city/country-level location information, an SMS message to you, and the results of the relevant check). MarsData may have access to the results of the check, and in limited situations, the photo ID and selfie that you provide to Onfido (but not your biometric data).</li>
                        <li><strong>‘Watchlist’ checks:</strong> In certain circumstances we may also use Onfido to conduct additional ‘watchlist’ verification checks (e.g., where this is a requirement for you to take part in particular Studies). We will notify you where this is relevant and give you the opportunity to opt-out. To perform these ‘watchlist’ checks, Onfido will use some of the data it collects about you as part of your identity check (such as your name and date of birth), together with certain limited personal data which we may provide, such as your country of residence and state (if you are based in the US). MarsData may have access to the basic results of the check.</li>
                    </ul>
                </li>
                <li>We may collect activity data about your use of the Platform. This includes basic records of Studies you have participated in (including frequency, title(s), start and end times, and your location) and log files generated through your use of the Platform.</li>
                <li>We may collect transaction data such as rewards you have earned on the Platform and that we have paid you, and your account balance.</li>
                <li>We (or our third-party payment providers) may collect certain limited payment information about you such as your PayPal username/email address. Other payment information is collected directly by our third-party payment providers, PayPal and Hyperwallet. If you use these services, PayPal and Hyperwallet may also a provide us with a small amount of information for payment verification purposes.</li>
                <li>We may collect certain limited tax information about you such as your personal tax identification number (e.g., your national insurance number, unique tax reference number or permanent account number) where we are required to do so to comply with any tax reporting requirements.</li>
                <li>We may collect user-generated content such as the information you choose to post publicly regarding the Platform in a user forum.</li>
                <li>We may collect correspondence with you related to your use of the Platform (such as when you contact us) and information contained in messages you have sent and received through the messaging features of the Platform. This information may include metadata and social media usernames.</li>
                <li>We may collect third-party account data. If you use a third-party application like Facebook to log into your account on the Platform then we may receive and process account data from the relevant third-party used to verify your login.</li>
                <li>If you decide to take part in user research concerning use of the Platform, then the usability testing or interview will take place via a video call, which we will record (with your permission) or from which we may otherwise take notes. We may ask you to share your screen while you use features of the Platform for usability testing purposes so we can record your interactions with the Platform, or we may track those interactions by cookies or similar technologies. Any video recording will likely feature your face and voice as well as your screen-share. Finally, we may also receive written feedback from you on your use of the features being tested.</li>
                <li>We may collect general usage data in relation to your use of the Platform, including data which we obtain through our, or our service providers’, analytics tracking systems. This might include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and navigation paths, and other interactions with and uses of the Platform, including information about the timing, frequency and pattern of those interactions and that use.</li>
            </ul>

            <p>Because most Studies take place outside of MarsData, we do not usually ‘see’ your Study responses, except in limited circumstances such as where we need to resolve a dispute, for example (see ‘Resolving disputes’ in the ‘What we do with your personal data’ section below). Instead, you provide your Study responses direct to the Researcher and they act as the data controller in relation to that data (see the ‘Information available to Researchers through Studies’ section below).</p>

            <p>However, where we conduct a Study on behalf of a third-party researcher, or a Researcher uses a data collection tool on our Platform to run its Study, we will have access to your Study responses. Where this is the case, the relevant third party or Researcher will still act as the data controller in relation to your Study responses, and their privacy notice – and not ours - will apply. But, in addition, for those Studies we act as a data processor of the data comprising your Study responses, because we’re handling it on the third party/Researcher’s behalf. It will be made clear when this is the case.</p>
            <h2>MarsData Studies and Polls</h2>
            <p>We may also conduct our own Studies and research polls on the Platform. This includes the short trial study you may take part in as part of your onboarding on the Platform. We may collect and process information submitted by you in these Studies and polls (“MarsData Study Data”), which could be wide-ranging and consist of the information outlined above.</p>

            <h2>What We Do with Your Personal Data</h2>
            <p>We may use your personal data for the following purposes or as otherwise described at the time of collection:</p>

            <h3>Service Delivery and Operations</h3>
            <p>We may use your personal data to:</p>
            <ul>
                <li>provide, operate and improve the Platform, Studies, and our business;</li>
                <li>personalise the Platform, including remembering the devices from which you have previously logged in and remembering your selections and preferences as you navigate the Platform;</li>
                <li>establish and maintain your account and profile on the Platform;</li>
                <li>communicate with you about the Platform and Studies, including by sending service-related announcements, updates, security alerts, and support and administrative messages;</li>
                <li>understand your needs and interests, and personalise your experience with the Platform and Studies and our communications; and</li>
                <li>provide support for the Platform and Studies, and respond to your requests, questions and feedback.</li>
            </ul>

            <h3>Research and Development</h3>
            <p>We may use your personal data for research and development purposes, including to analyse and improve the Platform and Studies and our business and to develop new products and services. As part of these activities, we may create aggregated, de-identified and/or anonymised data from personal data we collect. We make personal data into de-identified or anonymised data by removing information that makes the data personally identifiable to you. We may use this aggregated, de-identified or otherwise anonymised data and share it with third parties for our lawful business purposes, including to analyse and improve the Platform and promote our business, and will not attempt to re-identify you from any such data. From time to time, we may also contact you to invite you to take part in user research, and may use the personal data you provide as part of these user research opportunities for the above purposes.</p>

            <h3>Service Improvement and Analytics</h3>
            <p>We may use your personal data to analyse your usage of the Platform, improve the Platform, MarsData Studies, and the way Researcher Studies are conducted, improve the rest of our business, help us understand user activity on the Platform and in Studies, including which pages are most and least visited and how visitors move around the Platform, as well as user interactions with our emails, advertisements on the web and to develop new products and services.</p>

            <h3>Marketing</h3>
            <p>We (and our service providers acting on our behalf) may send you direct marketing communications personalised based on your needs and interests. You may opt-out of our marketing communications at any time as described in the ‘Your rights’ section below.</p>

            <h3>Advertising</h3>
            <p>If you have accepted our use of Marketing/Advertising cookies, we may use your personal data to ensure you only see relevant ads for our products and services on third-party sites. You may opt-out of this use of your personal data at any time by rejecting or opting out of our Marketing/Advertising cookies.</p>

            <h3>Compliance and Protection</h3>
            <p>We may use your personal data to:</p>
            <ul>
                <li>verify identity for fraud prevention purposes and to record the checks we’ve done;</li>
                <li>comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas, investigations or requests from government authorities, and for tax reporting purposes;</li>
                <li>protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims);</li>
                <li>audit our internal processes for compliance with legal and contractual requirements or our internal policies;</li>
                <li>enforce the terms and conditions that govern the Platform and Studies; and</li>
                <li>prevent, identify, investigate and deter fraudulent, harmful, unauthorised, unethical or illegal activity, including cyberattacks and identity theft.</li>
            </ul>

            <h3>Resolving Disputes</h3>
            <p>In very limited circumstances, a Researcher might share a limited extract of a Study response with us – e.g., in connection with a dispute between the Researcher and a Participant which we are requested to mediate, or a complaint by one of our users relating to the Study or response, or any investigation of suspected fraud, or if a response raises safeguarding or legal concerns. These circumstances are exceptional, and we encourage that they are resolved without us seeing any part of any Study response as far as possible. If we do see any part of any Study response for this purpose, we will use your personal data only to resolve the relevant issue.</p>

            <h2>Legal Basis for Processing Personal Data</h2>
            <p>In the below table, we have set out our purposes for using your personal data and the legal basis on which we do it (where such legal basis is required by applicable law, such as the GDPR). Where required, our legal bases for processing your personal data are:</p>
            <ul>
                <li>Where we need to perform a contract, we are about to enter into or have entered into with you (“Contractual Necessity”).</li>
                <li>Where it is necessary for our (or a third party’s) legitimate interests, and your interests and fundamental rights do not override those interests (“Legitimate Interests”). More detail about the specific legitimate interests pursued in respect of each Purpose we use your personal data for is set out in the table below.</li>
                <li>Where we need to comply with a legal or regulatory obligation (“Compliance with Law”).</li>
                <li>Where we have your specific consent to carry out the processing for the Purpose in question (“Consent”).</li>
            </ul>
            <p>For any special categories of personal data that we process, we also have to establish a ‘condition’ to process such data where the GDPR and/or other applicable data protection laws apply, because it is considered to be more sensitive in nature – for example, we may look to obtain your explicit consent to the processing of those special categories of personal data for one or more specified purposes (“Explicit Consent”).</p>

            <h2>Purposes for Using Your Personal Data</h2>
            <table border="1">
                <thead>
                    <tr>
                        <th>Purpose</th>
                        <th>Categories of Personal Data Involved</th>
                        <th>Legal Basis</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Service delivery and operations, including to:</td>
                        <td>
                            <ul>
                                <li>Account information</li>
                                <li>Profile information</li>
                                <li>Transaction data</li>
                                <li>Payment information</li>
                                <li>Tax information</li>
                                <li>Usage data</li>
                                <li>Correspondence</li>
                                <li>Activity data</li>
                                <li>Third-party account data</li>
                            </ul>
                        </td>
                        <td>
                            <p>Contractual Necessity</p>
                            <p>Where Contractual Necessity does not apply: Legitimate Interests</p>
                            <p>We have a legitimate interest in conducting our business.</p>
                            <p>For special categories of personal data (where the GDPR applies): Explicit Consent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Demographic screening, including to ensure you are eligible to participate in a given Study. This is a form of automated decision-making as we apply automatic filters to this information to enable us and our Researchers to select the requirements for each Study (e.g., if a study requires Participants located in a particular territory, then your eligibility for that Study will be determined automatically based on the Profile information you have provided).</td>
                        <td>Profile information</td>
                        <td>
                            <p>Contractual Necessity</p>
                            <p>Where Contractual Necessity does not apply: Legitimate Interests</p>
                            <p>We and our Researchers have a legitimate interest in screening Participants to ensure their eligibility for a given Study.</p>
                            <p>For special categories of personal data (where the GDPR applies): Explicit Consent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Verification checks, including to:</td>
                        <td>
                            <ul>
                                <li>conduct identity checks;</li>
                                <li>(where applicable) conduct ‘watchlist’ checks; and</li>
                                <li>keep a record of the checks we’ve done.</li>
                            </ul>
                        </td>
                        <td>
                            <p>Verification information</p>
                            <p>To conduct identity checks: Legitimate Interests</p>
                            <p>We and our Researchers have a legitimate interest in verifying the identity of Participants to ensure data quality and reliability, and to prevent fraud.</p>
                            <p>For biometric data (where the GDPR applies): Explicit Consent</p>
                            <p>To keep a record of identity checks (but not any biometric data): Legitimate Interests</p>
                            <p>We have a legitimate interest in maintaining a record of Participants’ identity checks and associated documentation to prevent fraud on the Platform.</p>
                            <p>To conduct and keep a record of ‘watchlist’ checks: Legitimate Interests</p>
                            <p>We and our Researchers have a legitimate interest in verifying that Participants are not on certain ‘watchlists’ to ensure their eligibility for particular Studies.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Research and development, including to analyse performance and data in relation to Studies you have completed in order to improve the Platform and other content based on analysing user feedback, and for fraud prevention purposes.</td>
                        <td>
                            <ul>
                                <li>Performance data</li>
                                <li>Transaction data</li>
                                <li>User research data</li>
                                <li>Usage data</li>
                                <li>Correspondence</li>
                                <li>Activity data</li>
                                <li>MarsData Study Data</li>
                            </ul>
                        </td>
                        <td>
                            <p>Legitimate Interests</p>
                            <p>We have a legitimate interest in improving our Platform.</p>
                            <p>Consent, where consent is required under applicable data protection or privacy laws, e.g., where non-essential cookies are used for this purpose.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Marketing, including to send you content related to MarsData.</td>
                        <td>Account information</td>
                        <td>
                            <p>Legitimate Interests</p>
                            <p>We have a legitimate interest in promoting our products and services and sending marketing communications for that purpose.</p>
                            <p>Consent, in circumstances or in jurisdictions where consent is required under applicable data protection or privacy laws for the sending of any given marketing communications.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Advertising, including to ensure you only see ads for MarsData which are relevant to you.</td>
                        <td>
                            <ul>
                                <li>Account information</li>
                                <li>Usage data</li>
                            </ul>
                        </td>
                        <td>
                            <p>Legitimate Interests</p>
                            <p>We have a legitimate interest in promoting our products and services and ensuring our advertising is relevant to you and your requirements.</p>
                            <p>Consent, where consent is required under applicable data protection or privacy laws, e.g., where non-essential cookies are used for this purpose.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Conducting our own research Studies and polls.</td>
                        <td>MarsData Study Data</td>
                        <td>
                            <p>Legitimate Interests</p>
                            <p>We have a legitimate interest in conducting our own research Studies and polls via the Platform.</p>
                            <p>For special categories of personal data (where the GDPR applies): Explicit Consent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Creation of aggregated, de-identified and/or anonymised data.</td>
                        <td>Any and all data types</td>
                        <td>Legitimate Interests</td>
                    </tr>
                    <tr>
                        <td>Compliance and protection, including to comply with applicable laws and legal processes, protect ours and others’ rights, audit our processes for legal and contractual compliance, and identify and prevent harmful, unauthorised or illegal activity.</td>
                        <td>
                            <ul>
                                <li>Tax information</li>
                                <li>Any and all other data types</li>
                            </ul>
                        </td>
                        <td>
                            <p>Compliance with Law</p>
                            <p>Where Compliance with Law is not applicable: Legitimate Interests</p>
                            <p>We have a legitimate interest in participating in, supporting, and following legal process and requests, including through co-operation with authorities. We may also have a legitimate interest in ensuring the protection, maintenance, and enforcement of our rights, property and/or safety.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Resolving disputes between Researchers and Participants</td>
                        <td>Any and all other data types</td>
                        <td>Legitimate Interests</td>
                    </tr>
                </tbody>
            </table>

            <h2>What personal data is shared with Researchers?</h2>

            <h3>Information available to Researchers through the Platform</h3>

            <p>We will not disclose any direct identifiers to Researchers (such as your name, contact details, email address or social media accounts), except in the unlikely event of any fraud, misconduct or breach of confidentiality by you which we explain further below.</p>

            <p>There are some messaging functions of the Platform that allow Researchers and Participants to communicate directly (e.g., for longitudinal Studies, or to resolve queries or concerns), but messages are directed to your MarsData ID (a string of numbers and characters) rather than to your name or email address. You may of course choose to share information like this yourself with a Researcher, but this is up to you (and the Researcher).</p>

            <p>Researchers will be able to see profile information (i.e., demographic data) relating to Participants for screening purposes. They will also see performance data connected with your completion of Studies. <b>Neither the profile information, nor the performance data available to Researchers, will contain any direct identifiers such as your name, email address or contact information.</b> In more detail:</p>

            <ul>
                <li>If you elect to participate in a Study, then baseline performance / quality data associated with your account will be available to the Researcher. This will cover subject matter such as the number of Study submissions associated with your account which have been accepted or rejected, and when you have started and finished the Study.</li>
                <li>If you elect to participate in a Study, then basic demographic data associated with your account will be available to the Researcher. This covers age, sex, language, country of residence, nationality, country of birth, student status and employment status. This information is available by default as our experience is that this demographic information is relevant to the majority of Studies. A full list of the demographic data that will be visible to Researchers is available <b>here (TODO)</b>.</li>
                <li>If you elect to participate in a Study, then responses to screener questions associated with that Study will be made available to the Researcher (so if, for example, a Study is aimed at medical workers experiencing poor sleep quality, the screener responses will identify that you are a medical worker who is not sleeping well).</li>
                <li>If you elect to participate in a Study that requires a ‘watchlist’ check, information about you related to that ‘watchlist’ check will not be made available to the Researcher (so they won’t be aware if you have not passed the check for any reason, however, they may be able to infer that you have passed this check if you take part in a Study where this is a requirement).</li>
                <li>No additional information will be made visible to Researchers through the Platform by us – so, for example, if the screener questions for a Study relate to your profession, we will not make available to the Researcher additional information relating to your hobbies, health, etc.</li>
                <li>The data made available to Researchers by us as described above will be exported by reference to your MarsData ID rather than to any direct identifiers like your name or email.</li>
                <li>When you make your Study submission, we will record your IP address and if necessary use it to provide your MarsData ID to the Researcher so they can match your submission with your MarsData ID as described above.</li>
                <li>Some Studies may be designed in such a way that you may make multiple submissions to the same Researcher, either in the course of the same Study (multi-submission Studies) or in the course or sequential or longitudinal Studies. The features of our Platform enable Researchers to make “save” and “allow” lists of MarsData IDs in order for them to re-recruit previous Participants for future Studies.</li>
                <li>In order to further ensure protection of the data visible to Researchers, we require all of our Researcher users to agree to our <a href="https://www.notion.so/7787f102f0c541bdbe2c04b5d3285acb?pvs=21"><b>Researcher Terms</b></a> (which requires them to comply with applicable law and their own ethics policies when receiving and handling that data), and we conduct checks on our Researcher users before allowing them access to the Platform (in particular, making sure that they are indeed employed by or associated with the institution or organisation they have identified themselves as representing).</li>
                <li>Researchers may be located outside the UK and EEA, which means that if they receive or access personal data, a data transfer may happen. Where necessary, for such transfers we require Researchers to agree to the Standard Contractual Clauses (<b>SCCs</b>) approved by the European Commission, and/or the UK’s International Data Transfer Agreement/Addendum, approved by the UK Government and/or other relevant authorities. Alternatively, we may transfer your personal data to signatories of the EU-US Data Privacy Framework, where applicable.</li>
            </ul>

            <p>Please note, in very limited circumstances where there is, for example, fraud, misconduct, or breach of confidentiality by you as part of a Study or otherwise toward a Researcher, we may need to provide the Researcher with information that directly identifies you so that they can take further action.</p>

            <h3>Information available to Researchers through Studies</h3>

            <p>When you participate in Studies set by Researchers, you may provide further information directly to the Researcher. The information you choose to share with a Researcher is entirely up to you.</p>

            <p>As part of some Studies, Researchers may ask if you’re willing to share additional personal data, including direct identifiers (like your name and email address). Where this is the case, we require the Researcher to make this clear in the Study listing and encourage them to give you information about how your data will be used so you can make an informed decision about whether or not you are willing to share it with them.</p>

            <p>Generally, unless <i>you</i> provide any additional personal data in a Study (or otherwise) to a Researcher which is capable of identifying you, your identity will remain unknown to them. <b>Remember:</b> the information we share with Researchers via the platform as described above is by reference to your MarsData ID only.</p>

            <p>Any personal data forming part of your Study responses (or otherwise shared with Researchers) is not being shared by MarsData, but by you. While we require Researchers to comply with applicable data protection laws in conducting their Studies, <b>we are not responsible for their actions or their processing of your personal data for their own purposes, including any use or misuse of that data</b>. <b>We encourage you to consult the privacy information (e.g. in a privacy notice) and/or consent form issued by the Researcher to give you information about how they will use your responses and make sure you are comfortable. This includes any Study which we conduct on behalf of a third party researcher or which is run by a Researcher using a data collection tool on our Platform – that third party’s/Researcher’s privacy notice will apply.</b></p>

            <h3>Why is the information we share with Researchers classed as personal data if it doesn’t directly identify you?</h3>

            <p>Again, MarsData does not disclose to Researchers any data which directly identifies you (such as your name, email address or mobile number), except in the event of any fraud, misconduct or breach of confidentiality by you. Instead, the information we make available to Researchers on the Platform is by reference to your MarsData ID only.</p>

            <p>Different territories in the world will have different data protection laws, but we adopt an approach consistent with the GDPR. Under the GDPR, information we make available about you to Researchers is considered to be ‘<b>pseudonymised</b>’ and therefore must be treated as personal data.</p>

            <p>Of course, if you choose to share any additional information with a Researcher which is capable of directly identifying you, your data would no longer be pseudonymised (as the Researcher would be able to identify you).</p>

            <h1>What third parties (other than Researchers) do we share your personal data with?</h1>

            <h2>Study administrator</h2>
            <p>Any Studies or polling which we run ourselves (whether for research or screening purposes) are conducted using the third-party platform, Typeform. Typeform may collect and process personal data on our behalf as our data processor.</p>

            <h2>Service Providers, Suppliers, and Contractors</h2>
            <p>We may disclose your personal data to other service providers, suppliers, or contractors in connection with the uses described above. For example, we may disclose:</p>
            <ul>
                <li>any personal data in our possession to service providers that host the servers on which our data is stored;</li>
                <li>your mobile number to our third-party verification provider, Twilio, so that it can generate and send an SMS message to you to verify your mobile number; and</li>
                <li>usage data and account information for the purposes of analysing use of the Platform, account verification and for fraud prevention. Usage data and account information (e.g. hashed email addresses) may also be shared with and processed by third-party providers who deliver ads.</li>
            </ul>
            <p>You can find a complete list of our relevant providers and their privacy policies here.</p>

            <h2>Third-Party Payment and Verification Providers</h2>
            <p>Our third-party payment and verification providers may directly collect and process your personal data, or we may disclose it to them. For example:</p>
            <ul>
                <li>Our third-party verification provider, Onfido, may collect verification information directly from you in order to check your identity (and may make this temporarily available to its trusted third-party partners to assist with the review of your ID as part of this identity check). Onfido may also process some of your verification information (together with a small amount of additional information it collects from us) as described above to conduct additional ‘watchlist’ checks where this is part of the eligibility criteria for certain types of Studies, and you have not opted-out of such checks. These ‘watchlist’ checks are used to confirm whether any individuals have been identified as ‘politically exposed persons’, or are on so-called ‘sanctions’ or ‘monitored’ lists. You can find information about Onfido’s privacy policy here;</li>
                <li>Our third-party payment providers (such as PayPal and Hyperwallet) may collect certain transaction data and payment information directly, only to the extent necessary for the purposes of processing payments, refunding payments, and dealing with complaints and queries relating to payments and refunds. You can find information about their privacy policies and practices here (for PayPal) and here (for Hyperwallet).</li>
            </ul>

            <h2>Linked third-party services</h2>
            <p>If you log into the Platform with, or otherwise link your Platform account to, a social media or other third-party service, we may share certain of your personal data with that third-party service, such as Facebook. The third party’s use of the shared information will be governed by its privacy policy and the settings associated with your account with the third-party service.</p>

            <h2>Professional advisors</h2>
            <p>We may disclose your personal data to our professional advisors, such as lawyers, auditors, bankers and insurers, where necessary in the course of the professional services that they render to us.</p>

            <h2>Authorities and others</h2>
            <p>We may disclose your personal data to law enforcement, government authorities, and private parties as we believe in good faith to be necessary or appropriate for the Compliance and protection purposes described above.</p>
            <p>We may be required to share certain transaction data and tax information about you with relevant tax authorities. For example, if you are a resident in the United Kingdom and the total value of the rewards you earn on the Platform exceeds a threshold value within one calendar year, we have an obligation to disclose your name, address, date of birth and personal tax identification number (e.g., national insurance number or unique tax reference number) for reporting purposes. See also the “Tax and reporting” section in our Participant Terms for more information.</p>

            <h2>Business transferees</h2>
            <p>We may disclose personal data in the context of actual or prospective business transactions (e.g., investments in MarsData, financing of MarsData, public stock offerings, or the sale, transfer or merger of all or part of our business, assets or shares); for example, we may need to share certain personal data with prospective counterparties and their advisers. We may also disclose your personal data to an acquirer, successor, or assignee of MarsData as part of any merger, acquisition, sale of assets, or similar transaction, and/or in the event of an insolvency, bankruptcy, or receivership in which personal data is transferred to one or more third parties as one of our business assets.</p>
            <h1>International transfers of your personal data</h1>
            <p>Some of the third parties to whom we may transfer your personal data, discussed above, may be located outside the UK and EEA or may transfer the personal data to their own service providers located outside the UK and EEA. If so, then we will ensure that transfers by our appointed data processors will only be made lawfully (e.g., to countries in respect of which the European Commission or UK has made an "adequacy decision”, or with appropriate safeguards such as the use of standard clauses approved by the European Commission or UK, bolstered by supplementary measures where required, or to signatories of the EU-US Data Privacy Framework, where applicable). You may contact us if you would like further information about these safeguards.</p>

            <h2>Third-parties who are controllers in their own right</h2>
            <p>We have no control over any third-party whose site or services are linked from our Platform (such as third-party survey platforms or social media operators). You should check their privacy notices and relevant terms to understand the uses they might make of your personal data.</p>

            <h2>How long we keep your personal data</h2>
            <p>We only retain personal data for as long as is necessary for the purposes for which we use it. In particular:</p>
            <ul>
                <li>most account information will be kept during the life of the account and then deleted no more than twelve months after account closure. As an exception, we may retain data related to dormant accounts if they have any outstanding balances associated with them, until that balance has been cleared, in order to ensure we can pay out or dispose of that balance lawfully and in accordance with our terms;</li>
                <li>transaction data, payment information and tax information that we collect will be retained for up to seven years following the relevant transaction or payment, as we keep records for tax purposes (however, this does not include transaction data and payment information collected directly by our third-party payment providers, which is subject to their own retention policies, as described above);</li>
                <li>encrypted phone numbers and email addresses may be stored for up to seven years for the purposes of fraud prevention;</li>
                <li>verification information (excluding biometric data collected directly by Onfido) may be stored for the purposes of fraud prevention for as long as is proportionate and necessary;</li>
                <li>correspondence and documents relating to our contractual relationship with you may be retained for up to seven years from termination, and records of complaints, disputes or other issues, including any relevant extract of a Study response, for up to seven years after their resolution (if this is later than seven years from termination of our contractual relationship), in order to ensure that we have kept records for the applicable limitation periods prescribed by law;</li>
                <li>MarsData Study Data will be deleted within twelve months of creation, or in the case of special category data deleted immediately after completion of the relevant Study or poll and compilation of the Study or poll responses;</li>
                <li>user research data will be kept only as long as it is relevant, which may depend on the context of the specific research. For example, if the research related to a product feature which is entirely obsolete, and in relation to which feedback is no longer relevant, we will delete the relevant user research data. Sometimes it may be useful to keep user research data as a comparator and reference over several product cycles, in which case we may keep it for several years. You can always contact us if you wish us to delete any recordings comprised in user research data. We will also pseudonymise user research data where practicable (e.g., by referring to User A, User B, etc.); and</li>
                <li>usage data will be deleted within twelve months of creation.</li>
            </ul>
            <p>We may retain your personal data longer:</p>
            <ul>
                <li>to comply with law;</li>
                <li>to protect the vital interests of any individual; or</li>
                <li>in connection with any legal claims (to the extent those claims are continuing after the end of the relevant retention period).</li>
            </ul>
            <p>Finally, we may create anonymised or aggregated records relating to demography or the use of the Platform, from which no individual is identifiable, and we may keep those records indefinitely.</p>

            <h2>Your rights</h2>
            <p>You may have rights under certain data protection laws to the extent they apply – they are complex, and subject to exemptions. If you are in the UK, you can read guidance from the Information Commissioner’s Office at <a href="http://www.ico.gov.uk">www.ico.gov.uk</a> for a fuller explanation of your rights. In summary, these include:</p>
            <ul>
                <li>the right to access: you may have the right to confirmation as to whether or not we process your personal data and, where we do, to access the personal data, together with certain additional information;</li>
                <li>the right to rectification: you may have the right to have any inaccurate or incomplete personal data about you rectified or completed;</li>
                <li>the right to erasure: in some circumstances you may have the right to the erasure of your personal data (for example, if the personal data are no longer needed for the purposes for which they were processed or if the processing is for direct marketing purposes);</li>
                <li>the right to restrict processing: you may have the right to restrict the processing of your personal data to limit its use. Where processing has been restricted, we may continue to store your personal data and will observe the restrictions on processing except to the extent permitted by law;</li>
                <li>the right to object to processing: you may have the right to object to our processing of your personal data on the basis of legitimate interests (discussed above) or for direct marketing purposes and if you do so we will stop processing your personal data except to the extent permitted by law; and</li>
                <li>the right to complain to a supervisory authority: if you consider that our processing of your personal data is unlawful, you may have a legal right to lodge a complaint with the ICO or your local data protection authority.</li>
            </ul>
            <p>You can submit requests to exercise these rights by emailing <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>. We may request specific information from you to help us confirm your identity and process your request. Whether or not we are required to fulfil any request you make will depend on a number of factors (e.g., why and how we are processing your personal data, where you are located, etc.). If we reject any request you may make (whether in whole or in part) we will let you know our grounds for doing so at the time, subject to any legal restrictions.</p>
            <p>You can ask us to stop sending you marketing-related emails at any time by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. Please note that if you choose to opt-out of marketing-related emails, you may continue to receive service-related and other non-marketing emails.</p>
            <p>If you choose to connect to the Platform through your social media account or other third-party platform (such as Facebook), you may be able to use your settings in your account with that third-party platform to limit the information we receive from it. If you revoke our ability to access information from a third-party platform, that choice will not apply to information that we have already received from that third party.</p>

            <h2>Do Not Track</h2>
            <p>Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com">http://www.allaboutdnt.com</a>.</p>

            <h2>Security</h2>
            <p>We employ a number of technical, organisational and physical safeguards designed to protect the personal data we collect, in accordance with applicable laws. However, security risk is inherent in all internet and information technologies and we cannot guarantee the security of your personal data.</p>

            <h2>Children</h2>
            <p>Our Platform is not intended for use by anyone under 18 years of age. If you are a parent or guardian of a child from whom you believe we have collected personal data in a manner prohibited by law, please contact us. If we learn that we have collected personal data through the Platform from a child without the consent of the child’s parent or guardian as required by law, we will comply with applicable legal requirements to delete the personal data.</p>

            <h2>Our details</h2>
            <p>MarsData Academic Ltd is registered in England and Wales under registration number XYZ, and our registered office is at 483 Green Lanes, London, England, N13 4BS. You can contact us by post at our registered office, or by email at <a href="mailto:marsdataco@gmail.com">marsdataco@gmail.com</a>.</p>
            <p>We are registered as a data controller with the UK Information Commissioner's Office under registration number ZA317731.</p>
            <p>If you are in the EEA, our EU GDPR representative can be contacted here:
                GDPR-Rep.eu
                Maetzler Rechtsanwalts GmbH & Co KG, Attorneys at Law,
                c/o MarsData Academic Ltd, Schellinggasse 3/10, 1010 Vienna, Austria
                <a href="https://prighter.com">https://prighter.com</a>
                Please add the following subject heading to all correspondence: GDPR-REP ID: 18862131
            </p>

            <h2>Revisions, and last updated</h2>
            <p>We’re constantly monitoring developments in law, introducing new product features and striving to improve our processes, and we may revise this Privacy Notice from time to time. If we make any substantial changes to this privacy notice which affect you, we will notify you via a message or prominent notification on the Platform and/or by email. Where required under applicable law, we will obtain your consent to such changes, otherwise, your continued use of our services after any such updates take effect will constitute acceptance of those changes. If you do not accept any updates to this privacy notice, you should stop using our services.</p>
            <p>Last updated: July 2024</p>

            <h2>Language</h2>
            <p>If this Privacy Notice is translated into, or appears in a language other than English (as may be required by applicable laws), the English version shall control.</p>

        </div>
    );
};