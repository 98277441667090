import React from 'react';

export const Terms = () => {
    return (
        <div>

            <p>This is a short summary only, for ease of reading. Our <strong>Full Privacy Notice</strong> <strong>for Researchers</strong> below <strong>has much more detail (and in particular contains all the information which we are legally required to provide).</strong></p>
            <ul>
                <li>We’re a <strong>data controller</strong> in relation to the personal data that we hold about you. That means we determine how we will use and look after it.</li>
                <li>If you’re a Researcher, we will hold a variety of personal data relating to you, but primarily <strong>account information</strong> (like your name and contact details), records relating to your use of MarsData (like <strong>transaction data,</strong> <strong>activity data</strong> and <strong>billing information</strong>), <strong>correspondence</strong> and <strong>usage data</strong>.</li>
                <li>We use your personal data to verify your identity, provide you with the use of MarsData, manage our relationship with you as a MarsData user, contact you, monitor the use of the MarsData platform and meet our legal obligations.</li>
                <li>We only provide your personal data to third parties for our business purposes (like the various suppliers who host data or provide software tools in relation to the operation of MarsData) or as permitted by law. We don’t share your data with third party advertisers.</li>
                <li>Some of our suppliers may be located outside the UK and EEA. We have terms in place with those providers to make sure our transfer of personal data to them is lawful.</li>
                <li>We store personal data for specified periods and delete it when it is no longer required.</li>
                <li>You have legal rights in relation to your personal data which you can exercise on request.</li>
                <li>Any use by you of Participants’ personal data (even if this is pseudonymised by reference to their MarsData ID only) as a Researcher must comply with applicable law. More details of our Researchers’ legal responsibilities, and how we help you comply with them, are set out <strong>further below in the current page</strong>.</li>
                <li>You can contact us to enquire about any of the contents of this Privacy Notice, at <a href="mailto:marsdata@gmail.com"><strong>marsdata@gmail.com</strong></a>.</li>
                <li>As we introduce new product features or new business processes, or as applicable law changes, we may update this Privacy Notice from time to time. We’ll notify you of any material changes (for example, through a notification or message on the MarsData platform, or by email).</li>
            </ul>

        </div>
    );
};