import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { TextField, Typography, List, ListItem, ListItemText, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ReceiversModal = ({ open, setOpen, receivers }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const { t } = useTranslation();
    const textFieldRef = useRef(null); // Create a reference to the TextField

    const onCloseModal = () => {
        setSearchTerm(''); // Reset search field when closing modal
        setOpen(false);
    };

    // Filter receivers based on search term
    const filteredReceivers = receivers.filter(receiver =>
        receiver.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => { 
        let root = document.getElementById("root");

        if (open) { 
            // Manually set focus to avoid automatic focusing on the input
            setTimeout(() => {
                if (textFieldRef.current) {
                    textFieldRef.current.blur(); // Ensure TextField does not gain focus initially
                }
            }, 0);

            // Apply the inert attribute to the root element when the modal is open
            root?.setAttribute("inert", "true");
        } else {
            // Remove the inert attribute when the modal is closed
            root?.removeAttribute("inert");
        }
    }, [open]);

    return (
        <Modal
            open={open}
            onClose={onCloseModal}
            center
            focusTrapped={true} 
            aria-labelledby="receivers-modal-title"
        >
            <Box> {/* Set a fixed width for the modal's content */}
                <Typography id="receivers-modal-title" variant="h5">{t('receivers')}</Typography>
                <TextField
                    inputRef={textFieldRef} // Set the reference to the TextField
                    label={t('searchReceiver')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ mb: '6px', mt: '6px', width: '100%' }}
                    // Ensure autoFocus is not set
                    autoFocus={false}
                />
                <Box sx={{ maxHeight: 400, overflow: 'auto' }}>  {/* Wrapper to make the list scrollable */}
                    <List>
                        {filteredReceivers.map((receiver, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={`${index + 1}. ${receiver}`} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </Modal>
    );
};

export default ReceiversModal;